.btn {
    position: relative;
    display: inline-block;
    padding: 4px 15px 2px 30px;
    color: $color-grey-black;
    font-size: $font-size-18;
    font-weight: 200;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;

    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        font: inherit;
        text-transform: inherit;
        background: $color-grey-black;
        overflow: hidden;
        @include transition(all, 0.2s, $ease-in-out-cubic, 0s);

        &:before {
            background: $color-white;
        }

        &:after {
            content: attr(data-hover);
            display: inline-block;
            padding: 4px 15px 2px 30px;
            color: $color-white;
            font: inherit;
            text-transform: inherit;
            white-space: nowrap;
        }
    }

    &:before,
    :before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 20px;
        height: 1px;
    }

    &:before {
        background: $color-grey-black;
    }

    &:hover {

        span {
            width: 100%;
        }
    }

    &.btn-grey-black {
        color: $color-white;
        background: $color-grey-black;

        span {
            background: $color-white;

            &:before {
                background: $color-grey-black;
            }

            &:after {
                color: $color-grey-black;
            }
        }

        &:before {
            background: $color-white;
        }
    }

    &.btn-white {
        color: $color-grey-black;
        background: $color-white;

        span {
            background: $color-grey-black;

            &:before {
                background: $color-white;
            }

            &:after {
                color: $color-white;
            }
        }

        &:before {
            background: $color-grey-black;
        }
    }

    &.btn-white-hover-orange {
        color: $color-grey-black;
        background: $color-white;

        span {
            background: $color-orange;

            &:before {
                background: $color-white;
            }

            &:after {
                color: $color-white;
            }
        }

        &:before {
            background: $color-grey-black;
        }
    }

    &.btn-orange {
        color: $color-white;
        background: $color-orange;

        span {
            background: $color-white;

            &:before {
                background: $color-grey-black;
            }

            &:after {
                color: $color-grey-black;
            }
        }

        &:before {
            background: $color-white;
        }
    }

    &.btn-orange-hover-black {
        color: $color-white;
        background: $color-orange;

        span {
            background: $color-grey-black;

            &:before {
                background: $color-white;
            }

            &:after {
                color: $color-white;
            }
        }

        &:before {
            background: $color-white;
        }
    }
}
