.mixin-headline {
    text-align: center;

    .clm {
        position: relative;
        padding-top: 40px;
        width: 55%;

        span {
            color: $color-grey-middle;
            font-size: $font-size-20;
            font-weight: 500;
            text-transform: uppercase;
        }

        h2 {
            color: $color-orange;
            font-size: $font-size-50;
            font-weight: 400;
            line-height: 1.3em;
            text-transform: uppercase;
            background-image: url('public/images/assets/layout/global/pattern-orange.png');
            background-position: center;
            @include font-family('Clarendon BT');
            @include hyphens(manual);
            @include background-clip(text);
            @include text-fill-color($transparent);
        }

        p {
            margin-top: 20px;
            color: $color-grey-dark;
            font-size: $font-size-20;
            font-weight: 500;
            line-height: 1.5em;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 0;
            height: 5px;
            background: $color-orange;
            @include opacity(0);
            @include transform(translate(-50%, 0));
            @include transition(all, 0.75s, $ease-in-out-cubic, 0.5s);
        }

        &.is-visible-in-viewport {

            &:after {
                width: 100px;
                @include opacity(1);
            }
        }
    }

    &.left {
        text-align: left;

        .clm {
            padding: 0 0 40px 0;

            &:after {
                top: auto;
                bottom: 0;
                left: 0;
                width: 0;
                @include transform(translate(0, 0));
            }

            &.is-visible-in-viewport {

                &:after {
                    width: 200px;
                    @include opacity(1);
                }
            }
        }
    }

    &.right {
        text-align: right;

        .clm {
            padding: 0 0 40px 0;
            text-align: left;

            &:after {
                top: auto;
                bottom: 0;
                left: 200%;
                width: 1000px;
                @include transform(translate(0, 0));
            }

            &.is-visible-in-viewport {

                &:after {
                    left: 60%;
                    @include opacity(1);
                }
            }
        }
    }

    &.center {

        .clm {
            width: auto;
            max-width: 700px !important;
        }
    }
}

html.isMobile {

    .mixin-headline {

        .clm:after {
            width: 100px;
            @include opacity(1);
        }

        &.left .clm:after {
            width: 200px;
            @include opacity(1);
        }

        &.right .clm:after {
            left: 60%;
            width: 200%;
            @include opacity(1);
        }
    }
}

@media screen and (max-width: 1360px) {

    .mixin-headline .clm h2 {
        font-size: $font-size-46;
    }
}

@media screen and (max-width: 1300px) {

    .mixin-headline {

        .clm {

            h2 {
                font-size: $font-size-46;
            }
        }

        &.left .clm {
            width: 60%;
        }

        &.right .clm {
            width: 65%;
        }
    }
}

@media screen and (max-width: 1150px) {

    .mixin-headline {

        &.right .clm {
            width: 75%;
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-headline .clm {
        width: 100% !important;
    }
}

@media screen and (max-width: 700px) {

    .mixin-headline .clm h2 {
        font-size: $font-size-40;
    }
}

@media screen and (max-width: 500px) {

    .mixin-headline .clm {

        h2 {
            font-size: $font-size-32;
        }

        p {
            font-size: $font-size-18;
        }
    }
}
