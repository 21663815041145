#page-error {
    height: 100%;
    text-align: center;
    background-image: none;
    overflow-y: auto;
    @include opacity(1);

    .icon {
        margin-bottom: 30px;
        color: $color-grey-dark;
        font-size: 100px;
    }

    h1 {
        padding: 0 20px;
        font-size: 70px;
        line-height: 1em;
    }

    p {
        margin-top: 20px;
        padding: 0 20px;
        font-size: $font-size-30;
        font-weight: 100;

        @include opacity(0.5);
    }

    a {
        margin-top: 20px;
        text-decoration: underline;
    }
}

@media screen and (max-width: $screen-767) {

    #page-error {
        .icon {
            font-size: 60px;
        }

        h1 {
            font-size: $font-size-40;
        }

        p {
            font-size: $font-size-20;
        }
    }
}
