.mixin-tiles {
    margin-top: 100px;

    ul {

        li {
            position: relative;

            .clm {
                position: relative;
                overflow: visible;

                &:nth-child(1) {
                    z-index: 2;
                    background: $color-grey-black;

                    h4 {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        padding: 40px 70px;
                        color: $color-grey-white;
                        font-size: $font-size-40;
                        line-height: 1.3em;
                        text-align: center;
                        background-image: url('public/images/assets/layout/global/pattern-white.png');
                        background-position: center;
                        @include font-family('Clarendon BT');
                        @include hyphens(manual);
                        @include background-clip(text);
                        @include text-fill-color($transparent);
                        @include transform(translate(0, -50%));
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 100%;
                        top: 50%;
                        width: 0;
                        height: 0;
                        border-top: 10px solid $transparent;
                        border-bottom: 10px solid $transparent;
                        border-left: 10px solid $color-grey-black;
                        @include transform(translate(0, -50%));
                    }
                }

                &:nth-child(2) {
                    z-index: 1;
                    background: $color-grey-dark;

                    p {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        padding: 30px 70px;
                        color: $color-grey-white;
                        font-weight: 100;
                        text-align: center;
                        @include transform(translate(0, -50%));

                        em {
                            font-weight: 500;
                        }
                    }

                    a {
                        position: absolute;
                        bottom: 40px;
                        left: 50%;
                        @include transform(translate(-50%, 0));
                    }
                }

                &:nth-child(3) {
                    position: absolute;
                    left: 33.33%;
                    @include transition(left, 0.75s, $ease-in-out-cubic, 0s);

                    a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            &:hover,
            &.is-total-in-viewport {

                .clm {

                    &:nth-child(3) {
                        left: 66.66%;
                    }
                }
            }

            &:nth-child(even) {

                .clm {

                    &:nth-child(1) {
                        margin-left: 33.333%;
                    }
                }

                &:hover,
                &.is-total-in-viewport {

                    .clm {

                        &:nth-child(3) {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}

html.isFirefox {

    .mixin-tiles ul li .clm:first-child h4 {
        @include text-fill-color($color-grey-white);
    }
}

@media screen and (max-width: 1300px) {

    .mixin-tiles ul li .clm {

        &:nth-child(1) {

            h4 {
                padding-left: 50px;
                padding-right: 50px;
                font-size: $font-size-36;
            }
        }

        &:nth-child(2) p {
            top: 40%;
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}

@media screen and (max-width: 1100px) {

    .mixin-tiles ul li .clm {

        &:nth-child(1) {

            h4 {
                padding-left: 30px;
                padding-right: 30px;
                font-size: $font-size-30;
            }
        }

        &:nth-child(2) {

            p {
                padding-left: 30px;
                padding-right: 30px;
            }

            a {
                bottom: 30px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-tiles {
        margin-top: 60px;

        ul li {
            margin-bottom: 20px;
            background: $color-grey-black;

            .clm {

                &:nth-child(1),
                &:nth-child(2) {
                    position: relative;
                    left: 0 !important;
                    width: 66.66%;
                    text-align: center;
                    background: $color-grey-black;

                    h4,
                    p,
                    a {
                        position: static;
                        @include transform(translate(0, 0));
                    }

                    h4 {
                        padding-bottom: 0;
                    }

                    a {
                        margin-bottom: 40px;
                    }

                    &:before,
                    &:after {
                        content: none;
                    }
                }

                &:nth-child(3) {
                    top: 0;
                    left: auto;
                    right: 0;
                    width: 33.33%;
                    height: 100%;

                    a img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: auto;
                        height: 100%;
                        @include transform(translate(-50%, -50%));
                    }
                }
            }

            &:nth-child(even) {
                background: $color-grey-dark;

                .clm {

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-left: 33.33%;
                        background: $color-grey-dark;
                    }

                    &:nth-child(3) {
                        left: 0;
                        right: auto;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 600px) {

    .mixin-tiles ul li .clm {

        &:nth-child(1),
        &:nth-child(2) {
            width: 100%;
            margin-left: 0 !important;

            h4,
            p {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        &:nth-child(1) {
            margin-top: 300px;
        }

        &:nth-child(3) {
            left: 0 !important;
            width: 100%;

            a {
                height: 300px;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {

    .mixin-tiles ul li .clm {

        &:nth-child(1) {
            margin-top: 200px;
        }

        &:nth-child(3) {

            a {
                height: 200px;
            }
        }
    }
}
