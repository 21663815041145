#slideup {

    .links {
        font-size: 0;

        .label {
            margin-right: 20px;
            cursor: pointer;

            &:last-child {
                margin-right: 0 !important;
            }
        }
    }

    .contents {
        position: relative;
        height: 0;
        overflow: hidden;
        @include transition(height, 1s, $ease-in-out-cubic, 0s);

        .close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            @include opacity(0);
            @include transition(opacity, 0.3s, ease-out, 0s);
        }

        .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            pointer-events: none;
            @include opacity(0);
            @include transition(opacity, 0.3s, ease-out, 0s);

            &.show {
                pointer-events: initial;
                @include opacity(1);
                @include transition(opacity, 0.3s, ease-out, 0.3s);
            }
        }

        &.show {

            .close {
                @include opacity(1);
                @include transition(opacity, 0.3s, ease-out, 1s);
            }
        }
    }
}
