/* == DIMENSIONS ====================================================== */
$middle-ctn: 1200px;
$small-ctn: 800px;

/* == MEDIA-QUERIES ====================================================== */
// extands and overwites /globals/_vars.scss

/* == VALUES ====================================================== */
$box-rounded-1: 3px;

/* == DURATIONS ====================================================== */
$loader: 1.8s;

/* == FONT-SIZES ====================================================== */
$font-size-8: 8px;

/* == COLORS ====================================================== */
$color-theme: #fa7921;

// white
$color-white-brown: #e6dfd5;

// grey
$color-grey-black: #1e282f;
$color-grey-dark: #6b7074;
$color-grey-middle: #acaaaf;
$color-grey-white: #f4f4f4;

// ornage
$color-orange: #fa7921;

/* == EASING ====================================================== */
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
