.mixin-burger {
    position: absolute;
    top: 19px;
    left: 30px;
    padding: 10px;
    cursor: pointer;

    div {
        position: relative;
        width: 20px;
        height: 1px;
        margin-bottom: 4px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-grey-black;
            @include transition(all, 0.3s, $ease-in-out-cubic, 0s);
        }

        &:nth-child(2) {

            &:after {
                width: 10px;
            }
        }

        &:nth-child(3) {

            &:after {
                width: 15px;
            }
        }

        &:nth-child(4) {

            &:after {
                width: 5px;
            }
        }
    }

    &:hover {

        div {

            &:after {
                width: 100% !important;
            }
        }
    }
}
