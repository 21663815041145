#gallery {
    padding: 100px 0;

    article.clm-wrapper {
        clear: both;
        padding-top: 80px;

        .clm {
            float: left;

            .tile-wrapper {
                position: relative;
                height: 100%;
                background-size: cover;
                background-position: center;
                overflow: hidden;

                &.tile-portrait {
                    padding-top: 120%;
                }

                &.tile-landscape {
                    padding-top: 60%;
                }

                &.tile-panorama {
                    padding-top: 30%;
                }

                &.tile-text {

                    p {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        z-index: 2;
                        width: 100%;
                        padding: 0 40px;
                        color: $color-white;
                        font-size: $font-size-40;
                        font-weight: 100;
                        text-align: center;
                        text-transform: uppercase;
                        @include transform(translate(0, -50%));
                    }

                    &:after {
                        background: $color-grey-black;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border: 5px solid $color-white;
                }
            }

            &.clm-2 {

                .tile-wrapper {

                    &.tile-landscape {
                        padding-top: 59.99%;
                    }
                }
            }
        }
    }
}

html.isSafari {

    #gallery article.clm-wrapper .clm {

        .tile-wrapper.tile-text p {
            font-weight: 300;
        }

        &:nth-child(11) {
            margin-left: -100%;

            .tile-wrapper {
                margin-top: 0;
            }
        }
    }
}

@media screen and (max-width: 1500px) {

    #gallery article.clm-wrapper .clm .tile-wrapper.tile-text p {
        font-size: $font-size-30;
        line-height: 1.3em;
    }
}

@media screen and (max-width: 1200px) {

    #gallery article.clm-wrapper .clm {

        .tile-wrapper {
            padding-top: 60% !important;
        }

        &.clm-2 {
            width: 100%;

            .tile-wrapper {
                padding-top: 30% !important;
            }
        }

        &.clm-4 {
            width: 50%;

            .tile-wrapper {
                padding-top: 60% !important;
            }
        }
    }
}

@media screen and (max-width: 800px) {

    #gallery article.clm-wrapper .clm .tile-wrapper.tile-text p {
        font-size: $font-size-30;
    }
}

@media screen and (max-width: 600px) {

    #gallery article.clm-wrapper {
        padding-top: 50px;

        .clm {
            margin: 0 !important;

            &.clm-2,
            &.clm-4 {
                width: 100% !important;

                .tile-wrapper {
                    margin: 0 !important;
                    padding-top: 60% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {

    #gallery {
        padding: 50px 0;
    }
}
