#footer {
    position: relative;
    z-index: 10;
    width: 100%;
    overflow-x: hidden;
    background: $color-white;

    .external-links {
        font-size: 0;
        padding: 20px 0;
        text-align: right;
        background: $color-white;
        border-top: 5px solid $color-orange;

        .social-media {
            float: left;
            position: relative;
            top: 1px;
            margin-right: 6px;
            overflow: hidden;

            .icon {
                display: inline-block;
                position: relative;
                width: 31px;
                height: 31px;
                color: $color-orange;
                border: 1px solid $color-orange;

                &.icon-facebook {
                    font-size: 23px;
                    color: $color-white;
                    @include text-stroke(1px, $color-orange);

                    &:before {
                        position: absolute;
                        bottom: -1px;
                        right: 0;
                    }
                }

                &.icon-instagram {
                    position: relative;

                    .flash {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 2px;
                            right: 2px;
                            width: 4px;
                            height: 4px;
                            border: 1px solid $color-orange;
                            @include border-radius(50%, 50%, 50%, 50%);
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 12px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background: $color-orange;
                        }
                    }

                    .lens {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 16px;
                        height: 16px;
                        border: 1px solid $color-orange;
                        background: $color-white;
                        @include border-radius(50%, 50%, 50%, 50%);
                        @include transform(translate(-50%, -50%));

                        &:before {
                            content: '';
                            position: inherit;
                            top: inherit;
                            left: inherit;
                            width: 8px;
                            height: 8px;
                            border: inherit;
                            @include border-radius(50%, 50%, 50%, 50%);
                            @include transform(translate(-50%, -50%));
                        }
                    }
                }

                &.icon-tripadvisor {

                    span {
                        position: absolute;
                    }

                    .nose {
                        top: 9px;
                        left: 50%;
                        width: 10px;
                        height: 10px;
                        overflow: hidden;
                        border: 1px solid $color-orange;
                        @include transform(translate(-50%, 0));
                        @include rotate-z(0, 45deg, 0, 0);
                    }

                    .ear {
                        top: 10px;
                        width: 10px;
                        height: 10px;
                        overflow: hidden;
                        border-top: 1px solid $color-orange;

                        &:before {
                            content: '';
                            position: absolute;
                            top: -20%;
                            left: -20%;
                            width: 100%;
                            height: 100%;
                        }

                        &.left {
                            left: 2px;

                            &:before {
                                border-left: 1px solid $color-orange;
                                @include rotate-z(0, -45deg, 0, 0);
                            }
                        }

                        &.right {
                            right: 2px;

                            &:before {
                                border-bottom: 1px solid $color-orange;
                                @include rotate-z(0, 315deg, 0, 0);
                            }
                        }
                    }

                    .head {
                        top: 7px;
                        left: 50%;
                        width: 15px;
                        height: 6px;
                        background: $color-white;
                        border: 1px solid $color-orange;
                        @include border-radius(50%, 50%, 50%, 50%);
                        @include transform(translate(-50%, 0));
                    }

                    .eye {
                        top: 9px;
                        width: 12px;
                        height: 12px;
                        border: 1px solid $color-orange;
                        background: $color-white;
                        @include border-radius(50%, 50%, 50%, 50%);

                        &:after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 4px;
                            height: 4px;
                            border: 1px solid $color-orange;
                            @include border-radius(50%, 50%, 50%, 50%);
                            @include transform(translate(-50%, -50%));
                        }

                        &.left {
                            left: 3px;
                        }

                        &.right {
                            right: 3px;
                        }
                    }
                }
            }
        }

        .mixin-newsletter {
            display: inline-block;
        }
    }

    .internal-links {
        position: relative;
        padding-top: 20px;
        font-size: 0;
        text-align: center;

        .clm-wrapper {
            position: relative;

            .clm {
                padding: 0 20px;

                .h5-style,
                ul,
                nav,
                address {
                    max-width: 500px;
                }

                .h5-style {
                    display: block;
                    padding-bottom: 5px;
                    margin-bottom: 10px;
                    color: $color-grey-dark;
                    font-size: $font-size-20;
                    line-height: 1.3em;
                    text-transform: uppercase;
                    border-bottom: 1px solid $color-grey-middle;

                    em {
                        font-weight: 500;
                    }
                }

                &:nth-child(1) {
                    text-align: center;

                    .h5-style,
                    nav {
                        max-width: 400px;
                        margin-left: auto;
                        margin-right: auto;

                        ul {
                            font-size: 0;

                            li {
                                display: inline-block;
                                margin: 4px;

                                a {
                                    padding: 2px 8px 0 8px;
                                    color: $color-grey-middle;
                                    font-size: $font-size-14;
                                    text-transform: uppercase;
                                    border: 1px solid $color-grey-middle;
                                    @include transition(all, 0.2s, $ease-in-out-cubic, 0s);

                                    &:hover {
                                        color: $color-grey-dark;
                                        border-color: $color-grey-dark;
                                    }
                                }
                            }
                        }
                    }
                }

                &:nth-child(2),
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                }

                &:nth-child(2) {
                    left: 0;
                    padding-left: 0;

                    .h5-style {
                        text-align: left;
                    }

                    ul {

                        li {
                            margin-bottom: 5px;

                            a {
                                display: block;
                                position: relative;
                                text-align: left;

                                time {
                                    position: absolute;
                                    top: 2px;
                                    left: 20px;
                                    font-family: sans-serif;
                                    font-size: $font-size-12;
                                    @include opacity(0.5);
                                    @include transition(all, 0.2s, $ease-in-out-cubic, 0s);
                                }

                                span {
                                    display: block;
                                    margin-left: 90px;
                                    padding-right: 20px;
                                    font-weight: 200;
                                }

                                &:hover {

                                    time {
                                        left: 0;
                                        color: $color-orange;
                                        @include opacity(1);
                                    }
                                }
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    right: 0;
                    padding-right: 0;
                    text-align: right;

                    h5,
                    address {
                        margin-left: auto;

                        span {
                            display: block;
                            margin: 0 20px 5px 0;
                            color: $color-grey-dark;

                            a {
                                color: inherit;
                                font-weight: 200;
                            }
                        }
                    }
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -50%;
            width: 200%;
            height: 1px;
            background: $color-grey-white;
        }
    }

    // .open-table {
    //     margin: 40px 0 20px 0;
    //     text-align: center;

    //     a {
    //         display: inline-block;

    //         img {
    //             height: 50px;
    //         }
    //     }

    //     span {
    //         display: block;
    //         margin-top: 5px;
    //         color: $color-grey-dark;
    //         font-size: $font-size-18;
    //         font-weight: 500;
    //     }
    // }

    .legal-details {
        position: relative;
        margin-top: 20px;
        padding: 20px 0;

        .center-wide-ctn {
            position: relative;

            .webdesign {
                position: absolute;
                top: 0;
                left: 50%;
                color: $color-grey-middle;
                font-weight: 200;
                @include transform(translate(-50%, 0));

                a {
                    color: inherit;
                    font-weight: 300;
                    text-decoration: none;
                    @include font-family('Roboto, Galano Grotesque');

                    span {
                        color: inherit;
                        font: inherit;
                        font-weight: 400;
                    }
                }
            }

            #slideup {

                .links .label {
                    color: $color-grey-middle;
                    font-weight: 200;

                    &.active {
                        text-decoration: underline;
                    }
                }

                .contents {

                    .content {
                        padding: 80px 0 20px 0;
                        text-align: center;

                        h4 {
                            max-width: $middle-ctn;
                            margin: 0 auto;
                            padding: 20px 0 10px 0;
                            font-size: $font-size-20;
                            text-transform: uppercase;
                        }

                        p {
                            max-width: $middle-ctn;
                            margin: 0 auto;
                            padding-bottom: 10px;
                            color: $color-grey-middle;
                            font-weight: 200;
                        }

                        a {
                            color: inherit;
                            font: inherit;
                            text-decoration: underline;
                        }
                    }
                }

                .close {
                    top: 20px;
                }
            }

            .copyright {
                position: absolute;
                top: 0;
                right: 0;
                color: $color-grey-middle;
                font-weight: 200;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -50%;
            width: 200%;
            height: 1px;
            background: $color-grey-white;
        }
    }
}

@media screen and (max-width: 1550px) {

    #footer .internal-links .clm-wrapper .clm {

        &:nth-child(1) {
            display: block;
            margin-bottom: 40px;
            width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3) {
            position: relative;
            width: 50%;
        }
    }
}

@media screen and (max-width: 1200px) {

    #footer .legal-details .center-wide-ctn {
        padding-top: 40px;

        .copyright {
            top: 40px;
        }
    }
}

@media screen and (max-width: 1000px) {

    #footer {

        .internal-links .clm-wrapper .clm {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                display: block;
                margin-bottom: 60px;
                padding: 0;
                width: 100%;

                .h5-style,
                ul {
                    margin: 0 auto;
                    text-align: center;
                }
            }

            &:nth-child(1) {

                ul {
                    margin-top: 10px;
                }
            }

            &:nth-child(2) {

                ul li a {
                    display: inline-block;
                    text-align: center;

                    time {
                        display: block;
                        position: static;
                        padding-top: 10px;
                    }

                    span {
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            &:nth-child(3) {

                address {
                    margin: 10px auto 0 auto;
                    text-align: center;

                    span {
                        margin-right: 0;
                    }
                }
            }
        }

        .legal-details .center-wide-ctn {
            padding-bottom: 40px;

            .webdesign {
                left: 0;
                width: 100%;
                text-align: center;
                @include transform(translate(0, 0));
            }

            #slideup .links {
                text-align: center;

                .label {
                    margin: 0 10px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .copyright {
                top: auto;
                bottom: 0;
                left: 0;
                right: auto;
                width: 100%;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 500px) {

    #footer .legal-details .center-wide-ctn #slideup .links .label {
        margin: 0 5px;
    }
}

@media screen and (max-width: 400px) {

    #footer {

        .external-links .mixin-newsletter {
            position: relative;
            left: 20px;
        }

        .legal-details .center-wide-ctn {

            .webdesign {
                font-size: $font-size-14;

                * {
                    font-size: inherit;
                }
            }

            .copyright,
            #slideup .links .label {
                font-size: $font-size-14;
            }
        }
    }
}

@media screen and (max-width: 350px) {

    #footer .legal-details .center-wide-ctn #slideup .links .label {
        margin: 0 3px;
    }
}
