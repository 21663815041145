@charset "UTF-8";
/* == CORE / GLOBALS / BASICS ====================================================== */ /* == RESET ====================================================== */
* {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  color: #000;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.6em;
  vertical-align: top;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  height: 100%;
}

body {
  font-family: "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-height: 100%;
  position: relative;
}

address,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

picture {
  display: inline-block;
}
picture img {
  width: 100%;
  height: 100%;
}

img {
  display: inline-block;
  font-size: 0;
}

ul {
  font-size: 0;
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

a:active,
a:hover,
a:focus,
img:focus {
  border: none;
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  background: #000;
}

em {
  font: inherit;
  color: inherit;
}

form {
  display: block;
}

input,
select {
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  resize: none;
  overflow: auto;
  vertical-align: top;
  border: 1px solid #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=radio] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

input[type=text],
input[type=number],
input[type=password] {
  border: 1px solid #000;
}

audio,
canvas,
video {
  display: block;
}

video {
  object-fit: cover;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

b,
strong {
  font-weight: bold;
}

svg:not(:root) {
  overflow: hidden;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
}

input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* == COMMON ====================================================== */
/* == FLEX ====================================================== */
/* == TEXT & FONTS ====================================================== */
/* == BOX-SHADOW ====================================================== */
/* == GRADIENT ====================================================== */
/* == ROTATE ====================================================== */
/* == SCALE ====================================================== */
/* == CALC ====================================================== */
/* == CLIP-PATH ====================================================== */
/* == TRANSFORM ====================================================== */
/* == TRANSITION ====================================================== */
/* @include transition(opacity, 0.5s, ease-out, 0s); */
/* == ANIMATION ====================================================== */
/* == CURSOR ====================================================== */
/* == IMG ====================================================== */
/* == BG-IMG ====================================================== */
/* == COLUMN ====================================================== */
[class^=icon-],
[class*=" icon-"] {
  display: inline-block;
  position: relative;
  font-family: "icomoon" !important;
  speak: none !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  text-align: center !important;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^=icon-] span,
[class*=" icon-"] span {
  font-family: "icomoon" !important;
  speak: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-variant: inherit !important;
  text-transform: inherit !important;
  line-height: inherit;
}
[class^=icon-] .visually-hidden,
[class*=" icon-"] .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  border: none;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  position: relative;
  z-index: 1;
}

/* == NATIVE ====================================================== */
.clear-both {
  clear: both;
}

.clear-none-i {
  clear: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.white-space-nowrap {
  white-space: nowrap;
}

.visibility-hidden {
  visibility: hidden;
}

.word-break-break-all {
  word-break: break-all;
}

.list-style-position-inside {
  list-style-position: inside;
}

.list-style-type-decimal-leading-zero {
  list-style-type: decimal-leading-zero;
}

/* == NATIVE - DISPLAY ====================================================== */
.display-none {
  display: none;
}

.display-none-i {
  display: none !important;
}

.display-block {
  display: block;
}

.display-block-i {
  display: block !important;
}

.display-inline {
  display: inline;
}

.display-inline-i {
  display: inline !important;
}

.display-inline-block {
  display: inline-block;
}

.display-inline-block-i {
  display: inline-block !important;
}

/* == NATIVE - FLEX ====================================================== */
.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

/* == NATIVE - POSITION ====================================================== */
.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

/* == NATIVE - Z-INDEX ====================================================== */
.z-index--1 {
  z-index: -1;
}

.z-index--1-i {
  z-index: -1 !important;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-2-i {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3;
}

.z-index-10-i {
  z-index: 10 !important;
}

/* == NATIVE - FLOAT ====================================================== */
.float-left {
  float: left;
}

.float-left-i {
  float: left !important;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.float-none-i {
  float: none !important;
}

/* == NATIVE - TEXT ====================================================== */
.text-align-left {
  text-align: left;
}

.text-align-left-i {
  text-align: left !important;
}

.text-align-right {
  text-align: right;
}

.text-align-right-i {
  text-align: right !important;
}

.text-align-center {
  text-align: center;
}

.text-align-center-i {
  text-align: center !important;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-transform-lowercase-i {
  text-transform: lowercase !important;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-uppercase-i {
  text-transform: uppercase !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-decoration-none-i {
  text-decoration: none !important;
}

/* == NATIVE - VERTICAL-ALIGN ====================================================== */
.vertical-align-top {
  vertical-align: top;
}

.vertical-align-top-i {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-baseline {
  vertical-align: baseline;
}

.vertical-align-inherit {
  vertical-align: inherit;
}

/* == NATIVE - OVERFLOW ====================================================== */
.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/* == NATIVE - BORDER ====================================================== */
.border-top-none {
  border-top: none;
}

.border-bottom-none {
  border-bottom: none;
}

.border-bottom-none-i {
  border-bottom: none !important;
}

/* == NATIVE VALUES - TOP ====================================================== */
.top-0 {
  top: 0;
}

.top-2px {
  top: 2px;
}

.top--2px {
  top: -2px;
}

.top--2px-i {
  top: -2px !important;
}

.top-3px {
  top: 3px;
}

.top-4px {
  top: 4px;
}

.top-5px {
  top: 5px;
}

.top-8px {
  top: 8px;
}

.top-100 {
  top: 100%;
}

/* == NATIVE VALUES - BOTTOM ====================================================== */
.bottom-0 {
  bottom: 0;
}

.bottom-10px {
  bottom: 10px;
}

/* == NATIVE VALUES - RIGHT ====================================================== */
.left-auto-i {
  left: auto !important;
}

.left-0 {
  left: 0;
}

.left-10 {
  left: 10px;
}

/* == NATIVE VALUES - RIGHT ====================================================== */
.right-0 {
  right: 0;
}

.right-10px {
  right: 10px;
}

/* == NATIVE VALUES - WIDTH ====================================================== */
.width-20px {
  width: 20px;
}

.width-20px-i {
  width: 20px !important;
}

.width-33px {
  width: 33px;
}

.width-34px {
  width: 34px;
}

.width-50px {
  width: 50px;
}

.width-80px {
  width: 80px;
}

.width-100px {
  width: 100px;
}

.width-110px {
  width: 110px;
}

.width-120px {
  width: 120px;
}

.width-120px-i {
  width: 120px !important;
}

.width-140px {
  width: 140px;
}

.width-150px {
  width: 150px;
}

.width-160px {
  width: 160px;
}

.width-170px {
  width: 170px;
}

.width-200px {
  width: 200px;
}

.width-250px {
  width: 250px;
}

.width-280px {
  width: 280px;
}

.width-300px {
  width: 300px;
}

.width-330px {
  width: 330px;
}

.width-350px {
  width: 350px;
}

.width-100 {
  width: 100%;
}

/* == NATIVE VALUES - MIN WIDTH ====================================================== */
.min-width-300px {
  min-width: 300px;
}

.min-width-640px {
  min-width: 640px;
}

/* == NATIVE VALUES - MAX-WIDTH ====================================================== */
.max-width-110px {
  max-width: 110px;
}

.max-width-600px {
  max-width: 600px;
}

.max-width-200px {
  max-width: 200px;
}

/* == NATIVE VALUES - HEIGHT ====================================================== */
.height-auto-i {
  height: auto !important;
}

.height-1px {
  height: 1px;
}

.height-20px {
  height: 20px;
}

.height-26px {
  height: 26px;
}

.height-34px {
  height: 34px;
}

.height-100px {
  height: 100px;
}

.height-100px-i {
  height: 100px !important;
}

.height-195px {
  height: 195px;
}

.height-350px {
  height: 350px;
}

.height-100 {
  height: 100%;
}

/* == NATIVE VALUES - MIN HEIGHT ====================================================== */
.min-height-40px {
  min-height: 40px;
}

.min-height-40px-i {
  min-height: 40px !important;
}

/* == NATIVE VALUES - MAX HEIGHT ====================================================== */
.max-height-100px {
  max-height: 100px;
}

/* == NATIVE VALUES - MARGIN ====================================================== */
.margin-3px {
  margin: 3px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-i {
  margin-top: 0 !important;
}

.margin-top-2px {
  margin-top: 2px;
}

.margin-top-4px {
  margin-top: 4px;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-top-10px-i {
  margin-top: 10px !important;
}

.margin-top-20px {
  margin-top: 20px;
}

.margin-top-20px-i {
  margin-top: 20px !important;
}

.margin-top-25px {
  margin-top: 25px;
}

.margin-top-25px-i {
  margin-top: 25px !important;
}

.margin-top-30px {
  margin-top: 30px;
}

.margin-top-30px-i {
  margin-top: 30px !important;
}

.margin-top-40px {
  margin-top: 40px;
}

.margin-top-40px-i {
  margin-top: 40px !important;
}

.margin-top-50px {
  margin-top: 50px;
}

.margin-top-50px-i {
  margin-top: 50px !important;
}

.margin-top-60px {
  margin-top: 60px;
}

.margin-top-80px {
  margin-top: 80px;
}

.margin-top--1px {
  margin-top: -1px;
}

.margin-top--20px {
  margin-top: -20px;
}

.margin-top--34px {
  margin-top: -34px;
}

.margin-top--60p {
  margin-top: -60%;
}

.margin-top--100p {
  margin-top: -100%;
}

.margin-left-5px {
  margin-left: 5px;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-left-20px {
  margin-left: 20px;
}

.margin-left-25px {
  margin-left: 25px;
}

.margin-left-30px {
  margin-left: 30px;
}

.margin-left-310px {
  margin-left: 310px;
}

.margin-right-10px {
  margin-right: 10px;
}

.margin-right-20px {
  margin-right: 20px;
}

.margin-right-30px {
  margin-right: 30px;
}

.margin-right-40px {
  margin-right: 40px;
}

.margin-right-48px {
  margin-right: 48px;
}

.margin-right-60px {
  margin-right: 60px;
}

.margin-right--10px {
  margin-right: -10px;
}

.margin-right--20px {
  margin-right: -20px;
}

.margin-right--40px {
  margin-right: -40px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-i {
  margin-bottom: 0 !important;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-bottom-10px-i {
  margin-bottom: 10px !important;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}

.margin-bottom-40px-i {
  margin-bottom: 40px !important;
}

.margin-bottom-80px {
  margin-bottom: 80px;
}

.margin-bottom--10px {
  margin-bottom: -10px;
}

.margin-bottom--50px {
  margin-bottom: -50px;
}

.margin-bottom--240px {
  margin-bottom: -240px;
}

.margin-vertical--20px {
  margin-left: -20px;
  margin-right: -20px;
}

/* == NATIVE VALUES - PADDING ====================================================== */
.adding-0 {
  padding: 0;
}

.padding-0-i {
  padding: 0 !important;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-i {
  padding-top: 0 !important;
}

.padding-top-10px {
  padding-top: 10px;
}

.padding-top-20px {
  padding-top: 20px;
}

.padding-top-20px-i {
  padding-top: 20px !important;
}

.padding-top-25px {
  padding-top: 25px;
}

.padding-top-30px {
  padding-top: 30px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-i {
  padding-bottom: 0 !important;
}

.padding-bottom-5px {
  padding-bottom: 5px;
}

.padding-bottom-10px {
  padding-bottom: 10px;
}

.padding-bottom-20px {
  padding-bottom: 20px;
}

.padding-bottom-20px-i {
  padding-bottom: 20px !important;
}

.padding-bottom-25px {
  padding-bottom: 25px;
}

.padding-bottom-30px {
  padding-bottom: 30px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-i {
  padding-left: 0 !important;
}

.padding-left-3px-i {
  padding-left: 3px !important;
}

.padding-left-5px {
  padding-left: 5px;
}

.padding-left-10px {
  padding-left: 10px;
}

.padding-left-20px {
  padding-left: 20px;
}

.padding-left-25px-i {
  padding-left: 25px !important;
}

.padding-left-150px {
  padding-left: 150px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-i {
  padding-right: 0 !important;
}

.padding-right-3px {
  padding-right: 3px;
}

.padding-right-3px-i {
  padding-right: 3px !important;
}

.padding-right-5px {
  padding-right: 5px;
}

.padding-right-10px {
  padding-right: 10px;
}

.padding-right-20px {
  padding-right: 20px;
}

.padding-right-43px-i {
  padding-right: 43px !important;
}

.padding-horizontal-3px {
  padding-left: 3px;
  padding-right: 3px;
}

.padding-horizontal-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.padding-horizontal-5px {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-horizontal-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-vertical-3px {
  padding-top: 3px;
  padding-bottom: 3px;
}

.padding-vertical-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-vertical-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-3px {
  padding: 3px;
}

.padding-10px {
  padding: 10px;
}

/* == NATIVE VALUES - FONT SIZE ====================================================== */
.font-size-0 {
  font-size: 0;
}

.font-size-1em {
  font-size: 1em;
}

.font-size-1c6em {
  font-size: 1.6em;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-20px {
  font-size: 20px;
}

.font-size-22px {
  font-size: 22px;
}

.font-size-24px {
  font-size: 24px;
}

.font-size-26px {
  font-size: 26px;
}

.font-size-28px {
  font-size: 28px;
}

.font-size-30px {
  font-size: 30px;
}

.font-size-32px {
  font-size: 32px;
}

.font-size-34px {
  font-size: 34px;
}

.font-size-38px {
  font-size: 38px;
}

.font-size-40px {
  font-size: 40px;
}

.font-size-80px {
  font-size: 80px;
}

/* == NATIVE VALUES - FONT WEIGHT ====================================================== */
.font-weight-200 {
  font-weight: 200;
}

.font-weight-200-i {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-300-i {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-400-i {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-500-i {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-600-i {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-700-i {
  font-weight: 700 !important;
}

/* == NATIVE VALUES - LINE HEIGHT ====================================================== */
.line-height-1em {
  line-height: 1em;
}

.line-height-26px {
  line-height: 26px;
}

.line-height-34px {
  line-height: 34px;
}

.line-height-37px {
  line-height: 37px;
}

/* == OPACITY ====================================================== */
.opacity-0 {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}

.opacity-0-i {
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
  -ms-filter: alpha(opacity=0) !important;
}

.opacity-1-i {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  -ms-filter: alpha(opacity=100) !important;
}

/* == HOVER ====================================================== */
.hover-text-decoration-underline:hover {
  text-decoration: underline;
}

/* == TAGS ====================================================== */
body {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  background: #fff;
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
body.scrolling iframe {
  pointer-events: none;
}
body.resizing * {
  -webkit-transition: all 0s linear 0s !important;
  -moz-transition: all 0s linear 0s !important;
  -ms-transition: all 0s linear 0s !important;
  -o-transition: all 0s linear 0s !important;
  transition: all 0s linear 0s !important;
}

img,
[data-lazy] {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 1s ease-out 0s;
  -moz-transition: opacity 1s ease-out 0s;
  -ms-transition: opacity 1s ease-out 0s;
  -o-transition: opacity 1s ease-out 0s;
  transition: opacity 1s ease-out 0s;
}
img.img-loaded,
[data-lazy].img-loaded {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
img.img-lazy-waiting,
[data-lazy].img-lazy-waiting {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
img.img-lazy-loaded,
[data-lazy].img-lazy-loaded {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

/* == COMMON ====================================================== */
.box-square {
  position: relative;
  overflow: hidden;
}
.box-square:before {
  content: "";
  display: block;
  aspect-ratio: 1;
  height: 100%;
}
.box-square .box-square-ctn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box-shadow {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.box-shadow.box-shadow-huge {
  -webkit-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -ms-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -o-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  box-shadow: 0 0 10px 0 rgb(0, 0, 0);
}
.box-shadow.box-shadow-small {
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.css-table {
  display: table;
  width: 100%;
  height: 100%;
}
.css-table .css-cell {
  display: table-cell;
  vertical-align: middle;
}

.border-radius {
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}

.border-radius-2 {
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

.text-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}

.line-break:after {
  content: "\a";
  white-space: pre;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  border: none;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.crop-at-show-more,
.crop-at-show-less {
  display: inline-block;
  padding-left: 5px;
  cursor: pointer;
}

.ctn-capitalize {
  position: relative;
}
.ctn-capitalize .ctn-cap {
  position: relative;
  color: inherit;
  font: inherit;
}

.clm-wrapper {
  font-size: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.clm-wrapper > .clm {
  display: inline-block;
}
.clm-wrapper > .clm.clm-1 {
  width: 100%;
}
.clm-wrapper > .clm.clm-2 {
  width: 50%;
}
.clm-wrapper > .clm.clm-3 {
  width: 33.333333%;
}
.clm-wrapper > .clm.clm-4 {
  width: 25%;
}
.clm-wrapper > .clm.clm-5 {
  width: 20%;
}
.clm-wrapper > .clm.clm-6 {
  width: 16.666666%;
}
.clm-wrapper > .clm.clm-7 {
  width: 14.285%;
}
.clm-wrapper > .clm.clm-8 {
  width: 12.5%;
}
.clm-wrapper > .clm.clm-9 {
  width: 11.111111%;
}
.clm-wrapper > .clm.clm-20p {
  width: 20%;
}
.clm-wrapper > .clm.clm-25p {
  width: 25%;
}
.clm-wrapper > .clm.clm-30p {
  width: 30%;
}
.clm-wrapper > .clm.clm-33p {
  width: 33.333333%;
}
.clm-wrapper > .clm.clm-35p {
  width: 35%;
}
.clm-wrapper > .clm.clm-40p {
  width: 40%;
}
.clm-wrapper > .clm.clm-45p {
  width: 45%;
}
.clm-wrapper > .clm.clm-55p {
  width: 55%;
}
.clm-wrapper > .clm.clm-50p {
  width: 50%;
}
.clm-wrapper > .clm.clm-60p {
  width: 60%;
}
.clm-wrapper > .clm.clm-65p {
  width: 65%;
}
.clm-wrapper > .clm.clm-66p {
  width: 66.666666%;
}
.clm-wrapper > .clm.clm-70p {
  width: 70%;
}
.clm-wrapper > .clm.clm-75p {
  width: 75%;
}
.clm-wrapper > .clm.clm-80p {
  width: 80%;
}
.clm-wrapper > .clm.clm-83p {
  width: 83.334%;
}
.clm-wrapper.nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.uniform {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  width: 25px;
  height: 25px;
}
.uniform input {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
.uniform.uniform-radio:before {
  bottom: 3px;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.uniform.uniform-radio:after {
  bottom: 8px;
  width: 8px;
  height: 8px;
  background: #181818;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.uniform.uniform-checkbox:before {
  bottom: 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.uniform.uniform-checkbox:after {
  content: "✓";
  height: 1em;
  bottom: 8px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: alpha(opacity=80);
}
.uniform.uniform-checked:after {
  display: block;
}
.uniform:before, .uniform:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.uniform:before {
  width: 16px;
  height: 16px;
  border: 1px solid #181818;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -ms-filter: alpha(opacity=30);
}
.uniform:after {
  display: none;
}

html.isFirefox .uniform.uniform-checkbox:before {
  bottom: 3px;
}

[data-set-parent-height] {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
[data-set-parent-height][style] {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

[data-trigger-href] {
  cursor: pointer;
}

[data-clone] {
  cursor: pointer;
}

/* == OLD BROWSER ====================================================== */
#old-browser {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #181818;
}
#old-browser h1 {
  padding: 0 20px;
  color: #fff;
  font-size: 30px;
  line-height: 1.3em;
  background: none;
}
#old-browser p {
  margin-top: 20px;
  padding: 0 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.6em;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#old-browser p span,
#old-browser p a {
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
}
#old-browser p a {
  text-decoration: underline;
  cursor: pointer;
}

/* == CORE / GLOBALS / PLUGINS ====================================================== */
.grecaptcha-badge {
  display: none !important;
}

/* == CORE / GLOBALS / MODULES ====================================================== */
#main {
  position: relative;
}

/* == CORE / APP / BASICS ====================================================== */
/* == CORE / APP / PAGES ====================================================== */
#page-error {
  height: 100%;
  text-align: center;
  background-image: none;
  overflow-y: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#page-error .icon {
  margin-bottom: 30px;
  color: #333;
  font-size: 100px;
}
#page-error h1 {
  padding: 0 20px;
  font-size: 70px;
  line-height: 1em;
}
#page-error p {
  margin-top: 20px;
  padding: 0 20px;
  font-size: 30px;
  font-weight: 100;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#page-error a {
  margin-top: 20px;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #page-error .icon {
    font-size: 60px;
  }
  #page-error h1 {
    font-size: 40px;
  }
  #page-error p {
    font-size: 20px;
  }
}
#page-maintenance {
  height: 100%;
  text-align: center;
}
#page-maintenance h1 {
  font-size: 30px;
  line-height: 1.3em;
}
#page-maintenance p {
  margin-top: 10px;
  font-size: 20px;
}
#page-maintenance form {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 400px;
  text-align: left;
}
#page-maintenance form label.mandatory:after {
  content: "*";
  margin-left: 5px;
}
#page-maintenance form input {
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#page-maintenance form input[type=submit] {
  padding: 4px 5px;
  color: #fff;
  background: #333;
  text-transform: uppercase;
}
#page-maintenance form p {
  font-size: 16px;
}

/* == PROJECT / APP / INDEX ====================================================== */
/* == CORE / APP / PLUGINS ====================================================== */
.addthis-smartlayers .at4-share .at-share-btn .at-icon-wrapper {
  background-color: #333 !important;
  -webkit-transition: background-color 0.3s ease-out 0s;
  -moz-transition: background-color 0.3s ease-out 0s;
  -ms-transition: background-color 0.3s ease-out 0s;
  -o-transition: background-color 0.3s ease-out 0s;
  transition: background-color 0.3s ease-out 0s;
}
.addthis-smartlayers .at4-share .at-share-btn:hover:nth-child(1) .at-icon-wrapper {
  background-color: #3b5998 !important;
}
.addthis-smartlayers .at4-share .at-share-btn:hover:nth-child(2) .at-icon-wrapper {
  background-color: #1da1f2 !important;
}
.addthis-smartlayers .at4-share .at-share-btn:hover:nth-child(3) .at-icon-wrapper {
  background-color: #dc4e41 !important;
}
.addthis-smartlayers .at4-share .at-share-btn:hover:nth-child(4) .at-icon-wrapper {
  background-color: #848484 !important;
}
.addthis-smartlayers .at4-share .at-share-btn:hover:nth-child(5) .at-icon-wrapper {
  background-color: #ff6550 !important;
}

/* == CORE / APP / MODULES ====================================================== */
#overlay {
  position: fixed !important;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  cursor: initial !important;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#overlay > .outer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#overlay > .outer > .wrapper {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  padding: 20px;
  text-align: left;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-overflow-scrolling: touch;
}
#overlay > .outer > .wrapper > .close {
  float: right;
  position: relative;
  z-index: 3;
  width: 18px;
  height: 18px;
  border: 1px solid #676a6c;
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#overlay > .outer > .wrapper > .close .trigger {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
#overlay > .outer > .wrapper > .close:before, #overlay > .outer > .wrapper > .close:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 11px;
  background: #676a6c;
}
#overlay > .outer > .wrapper > .close:before {
  top: 4px;
  right: 3px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#overlay > .outer > .wrapper > .close:after {
  top: 5px;
  left: 4px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
#overlay > .outer > .wrapper > .content {
  clear: right;
  position: relative;
  z-index: 1;
  padding-top: 10px;
  overflow-x: hidden;
}
#overlay > .outer > .wrapper > .content .chunk-overlay {
  max-width: 100%;
}
#overlay > .outer > .wrapper > .loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#overlay > .outer > .wrapper > .loading.show {
  pointer-events: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#overlay > .outer > .wrapper > .loading:after {
  content: "Loading...";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#overlay.loading > .outer > .wrapper {
  overflow-y: hidden;
}
#overlay.locked > .outer > .wrapper > .close {
  display: none;
}

#overlay_wrapper {
  overflow: hidden !important;
}

@media screen and (max-width: 400px) {
  #overlay {
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}
#slideup .links {
  font-size: 0;
}
#slideup .links .label {
  margin-right: 20px;
  cursor: pointer;
}
#slideup .links .label:last-child {
  margin-right: 0 !important;
}
#slideup .contents {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#slideup .contents .close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#slideup .contents .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#slideup .contents .content.show {
  pointer-events: initial;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-out 0.3s;
  -moz-transition: opacity 0.3s ease-out 0.3s;
  -ms-transition: opacity 0.3s ease-out 0.3s;
  -o-transition: opacity 0.3s ease-out 0.3s;
  transition: opacity 0.3s ease-out 0.3s;
}
#slideup .contents.show .close {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-out 1s;
  -moz-transition: opacity 0.3s ease-out 1s;
  -ms-transition: opacity 0.3s ease-out 1s;
  -o-transition: opacity 0.3s ease-out 1s;
  transition: opacity 0.3s ease-out 1s;
}

/* == PROJECT / BASICS ====================================================== */
/* == DIMENSIONS ====================================================== */
/* == MEDIA-QUERIES ====================================================== */
/* == VALUES ====================================================== */
/* == DURATIONS ====================================================== */
/* == FONT-SIZES ====================================================== */
/* == COLORS ====================================================== */
/* == EASING ====================================================== */
/* == Galano Grotesque ====================================================== */
@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("public/fonts/Galano-Grotesque/GalanoGrotesque-Thin.woff2") format("woff2");
}
@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("public/fonts/Galano-Grotesque/GalanoGrotesque-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("public/fonts/Galano-Grotesque/GalanoGrotesque-Light.woff2") format("woff2");
}
@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("public/fonts/Galano-Grotesque/GalanoGrotesque-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("public/fonts/Galano-Grotesque/GalanoGrotesque-Medium.woff2") format("woff2");
}
/* == Clarendon BT ====================================================== */
@font-face {
  font-family: "Clarendon BT";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("public/fonts/Clarendon-BT/Clarendon-BT-Roman.woff2") format("woff2");
}
@font-face {
  font-family: "Clarendon BT";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("public/fonts/Clarendon-BT/Clarendon-BT-Bold.woff2") format("woff2");
}
/* == Google Fonts - Roboto ====================================================== */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "icomoon";
  src: url("public/icons/icomoon.eot");
  src: url("public/icons/icomoon.eot?#iefix") format("embedded-opentype"), url("public/icons/icomoon.ttf") format("truetype"), url("public/icons/icomoon.woff") format("woff"), url("public/icons/icomoon.svg?#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.icon-spike:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e902";
}

.icon-location:before {
  content: "\e900";
}

.icon-bug:before {
  content: "\e901";
}

/* == TAGS ====================================================== */
* {
  font-family: "Galano Grotesque", sans-serif;
  color: #1e282f;
}

html {
  width: 100%;
}
html.isTouch .h1-style span {
  background: none;
  -webkit-background-clip: unset;
  -moz-background-clip: unset;
  -ms-background-clip: unset;
  -o-background-clip: unset;
  background-clip: unset;
  -webkit-text-fill-color: unset;
  -moz-text-fill-color: unset;
  -ms-text-fill-color: unset;
  -o-text-fill-color: unset;
  text-fill-color: unset;
}
html.isTouch .h1-style span:after {
  content: none;
}
html.isTouch .h1-style:after {
  content: none;
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fff;
}
body#page-6, body#page-3057 {
  width: 100%;
  height: 100%;
  padding-top: 0;
  overflow: hidden;
}
body#page-6 #main, body#page-3057 #main {
  height: 100%;
}

p {
  color: #acaaaf;
}
p a {
  color: inherit;
  font-weight: 500;
}

/* == PSEUDO CLASS ====================================================== */
::selection {
  color: #fff;
  background: #fa7921;
}

::-moz-selection {
  color: #fff;
  background: #fa7921;
}

/* == COMMON ====================================================== */
.h1-style {
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  color: #fff;
  font-size: 70px;
  line-height: 1.25em;
  text-align: center;
  font-family: "Clarendon BT", sans-serif;
}
.h1-style span {
  position: relative;
  z-index: 1;
  color: inherit;
  font: inherit;
  line-height: inherit;
  background-image: url("public/images/assets/layout/global/pattern-white.png");
  background-position: center;
  -webkit-hyphens: manual;
  -moz-hyphens: manual;
  -ms-hyphens: manual;
  -o-hyphens: manual;
  hyphens: manual;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  -ms-text-fill-color: rgba(0, 0, 0, 0);
  -o-text-fill-color: rgba(0, 0, 0, 0);
  text-fill-color: rgba(0, 0, 0, 0);
}
.h1-style:after {
  content: attr(data-content);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0);
  -webkit-text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
  -moz-text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
  -ms-text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
  -o-text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
  text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
}
.h1-style.shadow {
  -webkit-text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
  -moz-text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
  -ms-text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
  -o-text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
  text-shadow: -1px -1px 0 #000, 0 0 1px #000, 0 0 50px rgb(0, 0, 0);
}

.text-hover {
  position: relative;
  z-index: 0;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.text-hover:after {
  content: "";
  position: absolute;
  height: 3px;
  bottom: 0;
  left: 50%;
  right: 50%;
  z-index: -1;
  background: #fa7921;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.text-hover:hover:after {
  left: 0;
  right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

.clm-wrapper {
  display: block;
  flex-wrap: unset;
}

@media screen and (max-width: 1200px) {
  .h1-style {
    font-size: 50px;
  }
}
@media screen and (max-width: 1180px) {
  .h1-style {
    margin: 0 90px;
  }
}
@media screen and (max-width: 600px) {
  .h1-style {
    margin: 0 30px;
    font-size: 40px;
  }
}
@media screen and (max-width: 400px) {
  .h1-style {
    margin: 0 10px;
    font-size: 36px;
  }
}
@media screen and (max-width: 350px) {
  .h1-style {
    font-size: 32px;
  }
}
/* == CONTENT-WRAPPER ====================================================== */
.center-header-ctn {
  margin: 0 40px;
}

.center-huge-ctn {
  margin: 0 90px;
}

.center-wide-ctn {
  margin: 0 60px;
}

.center-middle-ctn {
  margin: 0 auto;
  max-width: 1200px;
}

.center-small-ctn {
  margin: 0 auto;
  max-width: 800px;
}

.center-menu-ctn {
  margin: 0 auto;
  max-width: 1400px;
}

@media screen and (max-width: 1550px) {
  .center-menu-ctn {
    margin-left: 60px;
    margin-right: 60px;
  }
}
@media screen and (max-width: 1360px) {
  .center-middle-ctn {
    margin-left: 90px;
    margin-right: 90px;
  }
}
@media screen and (max-width: 1300px) {
  .center-huge-ctn {
    margin-left: 60px;
    margin-right: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .center-huge-ctn,
  .center-wide-ctn {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .center-menu-ctn {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 800px) {
  .center-middle-ctn {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media screen and (max-width: 765px) {
  .center-menu-ctn {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media screen and (max-width: 600px) {
  .center-header-ctn,
  .center-huge-ctn,
  .center-wide-ctn,
  .center-middle-ctn,
  .center-small-ctn,
  .center-menu-ctn {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .center-header-ctn,
  .center-huge-ctn,
  .center-wide-ctn,
  .center-menu-ctn,
  .center-middle-ctn,
  .center-small-ctn {
    margin-left: 20px;
    margin-right: 20px;
  }
}
/* == BACKGROUND ====================================================== */
.bg-color-white {
  background: #fff;
}

.bg-color-grey-black {
  background: #1e282f;
}

.bg-color-grey-white {
  background: #f4f4f4;
}

/* == PROJECT / PLUGINS ====================================================== */
.addthis-smartlayers {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
.addthis-smartlayers .at4-share {
  left: -48px;
  animation-name: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;
}
.addthis-smartlayers .at4-share .at-share-btn .at-icon-wrapper {
  background-color: #1e282f !important;
}

body.body-images-loaded .addthis-smartlayers {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

body.window-scroll-top-gt-0 .addthis-smartlayers .at4-share {
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
body.window-scroll-top-gt-0#page-1342 .addthis-smartlayers .at4-share, body.window-scroll-top-gt-0#page-1346 .addthis-smartlayers .at4-share, body.window-scroll-top-gt-0#page-42 .addthis-smartlayers .at4-share, body.window-scroll-top-gt-0#page-47 .addthis-smartlayers .at4-share {
  left: -48px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}

body.window-scroll-top-gt-viewport .addthis-smartlayers .at4-share {
  left: 0 !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  -ms-filter: alpha(opacity=100) !important;
}

@media screen and (max-width: 1000px) {
  .addthis-smartlayers {
    display: none;
  }
}
.notifyjs-corner {
  left: 50% !important;
  right: auto !important;
  z-index: 999999 !important;
  margin: 0 !important;
  padding: 10px;
  width: 100%;
  max-width: 960px;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.notifyjs-corner .notifyjs-wrapper {
  margin: 0 0 10px 0 !important;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container {
  margin: 0 !important;
  text-align: center;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container > div {
  display: inline-block;
  padding: 10px 20px;
  text-align: left;
  background: #fff;
  -webkit-box-shadow: 0 0 10px 0 #1e282f;
  -moz-box-shadow: 0 0 10px 0 #1e282f;
  -ms-box-shadow: 0 0 10px 0 #1e282f;
  -o-box-shadow: 0 0 10px 0 #1e282f;
  box-shadow: 0 0 10px 0 #1e282f;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container > div .icon {
  display: inline-block;
  width: 34px;
  margin-right: 15px;
  color: #6b7074;
  font-size: 26px;
  line-height: 32px;
  font-weight: 100;
  text-align: center;
  border: 1px solid #6b7074;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container > div .text {
  color: #6b7074;
  line-height: 34px;
}
.notifyjs-corner .notifyjs-wrapper .notifyjs-container > div.notifyjs-error-base {
  background: #fff;
}

body > div[class^=privacypolicies-com]:first-child {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out 1s;
  -moz-transition: opacity 0.5s ease-out 1s;
  -ms-transition: opacity 0.5s ease-out 1s;
  -o-transition: opacity 0.5s ease-out 1s;
  transition: opacity 0.5s ease-out 1s;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb {
  top: auto;
  bottom: 20px;
  left: 20px;
  right: 20px;
  height: auto;
  max-width: 1200px;
  max-height: calc(100vh - 40px);
  margin: 0 auto;
  background: #fff;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-okagree, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-reject, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-changep,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-okagree,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-reject,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-changep {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px !important;
  padding: 7px 15px;
  font-size: 16px;
  font-weight: 200;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  box-shadow: none !important;
  cursor: pointer;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  -ms-border-radius: 0 0 0 0;
  -o-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-okagree,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-okagree {
  color: #fff;
  background: #fa7921;
  border: 1px solid #fa7921;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-reject, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-changep,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-reject,
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-changep {
  color: #6b7074;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb .cc-nb-title {
  margin-bottom: 10px;
  color: #6b7074;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3em;
  font-family: "Clarendon BT", sans-serif;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb .cc-nb-text {
  font-family: "Galano Grotesque", sans-serif;
  color: #6b7074;
  font-size: 16px;
  line-height: 1.5em;
}
body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb .cc-nb-main-container {
  padding: 20px;
}

body.body-loaded > div[class^=privacypolicies-com]:first-child {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

#privacypolicies-com---preferences-center {
  overflow: hidden;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] {
  top: 20px;
  left: 20px;
  right: 20px;
  width: auto;
  max-width: 750px;
  margin: 0 auto;
  overflow: hidden;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container {
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head {
  display: block;
  position: relative;
  background: #fff;
  border-color: #e0e0e0;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-title {
  display: block;
  padding: 0;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-title .cc-pc-head-title-headline {
  padding: 52px 0 20px 20px;
  color: #6b7074;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.3em;
  font-family: "Clarendon BT", sans-serif;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  min-height: unset;
  padding: 0;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-lang-select {
  display: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close {
  position: relative;
  width: 22px;
  height: 22px;
  margin: 0;
  padding: 0;
  font-size: 0;
  border: 1px solid #6b7074;
  cursor: pointer;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close .trigger {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close:before, #privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 17px;
  background: #6b7074;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close:before {
  top: 4px;
  right: 3px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-pc-head .cc-pc-head-lang .cc-pc-head-close:after {
  top: 5px;
  left: 4px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs {
  width: 200px;
  min-width: 200px;
  background: #fff;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs .cc-cp-body-tabs-item {
  position: relative;
  border-color: #f3f3f4;
  background: #fff;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs .cc-cp-body-tabs-item .cc-cp-body-tabs-item-link {
  padding: 7px 15px 3px 20px;
  color: #6b7074;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs .cc-cp-body-tabs-item[active=true] {
  background: #f3f3f4 !important;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs .cc-cp-body-tabs-item[active=true]:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  z-index: 1;
  width: 5px;
  background: #fa7921;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content {
  background: #f3f3f4 !important;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry {
  padding: 20px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-title {
  color: #acaaaf;
  font-weight: 600;
  line-height: 1.3em;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-text {
  color: #acaaaf;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6em;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-text a {
  display: inline;
  color: #fa7921;
  font: inherit;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-text a:hover {
  text-decoration: underline;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-cp-body-content-entry-text a:focus {
  box-shadow: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox {
  position: relative;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox input[type=checkbox] {
  display: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox input[type=checkbox] + label:before {
  border-color: #e0e0e0;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox input[type=checkbox]:checked + label:before {
  background: #fa7921;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-content .cc-cp-body-content-entry .cc-custom-checkbox input[type=checkbox] + label:after {
  border-color: #e0e0e0;
  background: #fff;
  box-shadow: none;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot {
  display: block;
  text-align: right;
  background: #fff;
  border-color: #e0e0e0;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot .cc-cp-foot-byline {
  display: none !important;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot .cc-cp-foot-button {
  padding: 20px;
}
#privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot .cc-cp-foot-button .cc-cp-foot-save {
  display: inline-block;
  margin: 0 !important;
  padding: 7px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  background: #fa7921;
  border: 1px solid #fa7921;
  box-shadow: none !important;
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  font-family: "Galano Grotesque", sans-serif;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  -ms-border-radius: 0 0 0 0;
  -o-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}

@media screen and (max-width: 700px) {
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-okagree, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-reject, body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-changep,
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-okagree,
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-reject,
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-changep {
    float: none;
    display: block;
    width: 100%;
    margin: 0 0 20px 0 !important;
  }
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button.cc-nb-changep,
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb button:focus.cc-nb-changep {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 600px) {
  #privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  #privacypolicies-com---preferences-center > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-foot .cc-cp-foot-button .cc-cp-foot-save {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  body > div[class^=privacypolicies-com]:first-child #privacypolicies-com---nb {
    bottom: 10px;
    left: 10px;
    right: 10px;
    max-height: calc(100vh - 20px);
  }
  #privacypolicies-com---preferences-center > div[class^=privacypolicies-com] {
    top: 10px;
    left: 10px;
    right: 10px;
  }
}
/* v1.0.6 */
/* Core RS CSS file. 95% of time you shouldn't change anything here. */
.royalSlider {
  width: 600px;
  height: 400px;
  position: relative;
  direction: ltr;
}

.royalSlider > * {
  float: left;
}

.rsFade.rsWebkit3d .rsSlide,
.rsFade.rsWebkit3d img,
.rsFade.rsWebkit3d .rsContainer {
  -webkit-transform: none;
}

.rsOverflow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  float: left;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVisibleNearbyWrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVisibleNearbyWrap .rsOverflow {
  position: absolute;
  left: 0;
  top: 0;
}

.rsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsArrow,
.rsThumbsArrow {
  cursor: pointer;
}

.rsThumb {
  float: left;
  position: relative;
}

.rsArrow,
.rsNav,
.rsThumbsArrow {
  opacity: 1;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.rsHidden {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  -moz-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  -o-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
}

.rsGCaption {
  width: 100%;
  float: left;
  text-align: center;
}

/* Fullscreen options, very important ^^ */
.royalSlider.rsFullscreen {
  position: fixed !important;
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 2147483647 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.royalSlider .rsSlide.rsFakePreloader {
  opacity: 1 !important;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  display: none;
}

.rsSlide {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.royalSlider.rsAutoHeight,
.rsAutoHeight .rsSlide {
  height: auto;
}

.rsContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.rsPreloader {
  position: absolute;
  z-index: 0;
}

.rsNav {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
}

.rsNavItem {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.25);
}

.rsThumbs {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  float: left;
  z-index: 22;
}

.rsTabs {
  float: left;
  background: none !important;
}

.rsTabs,
.rsThumbs {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVideoContainer {
  /*left: 0;
  top: 0;
  position: absolute;*/
  /*width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  float: left;*/
  width: auto;
  height: auto;
  line-height: 0;
  position: relative;
}

.rsVideoFrameHolder {
  position: absolute;
  left: 0;
  top: 0;
  background: #141414;
  opacity: 0;
  -webkit-transition: 0.3s;
}

.rsVideoFrameHolder.rsVideoActive {
  opacity: 1;
}

.rsVideoContainer iframe,
.rsVideoContainer video,
.rsVideoContainer embed,
.rsVideoContainer .rsVideoObj {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* ios controls over video bug, shifting video */
.rsVideoContainer.rsIOSVideo iframe,
.rsVideoContainer.rsIOSVideo video,
.rsVideoContainer.rsIOSVideo embed {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 44px;
}

.rsABlock {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 15;
}

img.rsImg {
  max-width: none;
}

.rsNoDrag {
  cursor: auto;
}

.rsLink {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 20;
}

/* == PROJECT / MIXINS ====================================================== */
.mixin-article {
  position: relative;
  margin-top: 40px;
}
.mixin-article .clm-wrapper .clm {
  position: relative;
}
.mixin-article .clm-wrapper .clm h3 {
  margin-bottom: 10px;
  color: #6b7074;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3em;
}
.mixin-article .clm-wrapper .clm p {
  margin-bottom: 10px;
}
.mixin-article .clm-wrapper .clm p:last-child {
  margin-bottom: 0;
}
.mixin-article .clm-wrapper .clm > ul > li {
  margin-bottom: 40px;
}
.mixin-article .clm-wrapper .clm > ul > li p {
  padding-left: 40px;
}
.mixin-article .clm-wrapper .clm > ul > li ul {
  text-align: left;
}
.mixin-article .clm-wrapper .clm > ul > li ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 60px;
  color: #acaaaf;
}
.mixin-article .clm-wrapper .clm > ul > li ul li:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 40px;
  width: 8px;
  height: 8px;
  background: #fa7921;
}
.mixin-article .clm-wrapper .clm > ul > li ul li:last-child {
  margin-bottom: 0;
}
.mixin-article .clm-wrapper .clm > ul > li:last-child {
  margin-bottom: 0;
}
.mixin-article .clm-wrapper .clm:nth-child(1) {
  padding-right: 60px;
}
.mixin-article .clm-wrapper .clm:nth-child(2) {
  padding-left: 60px;
}
.mixin-article .img-wrapper,
.mixin-article .mixin-gmaps {
  position: relative;
  margin-top: 100px;
  height: 500px;
}
.mixin-article .img-wrapper .img-mask {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200%;
  height: 100%;
  overflow: hidden;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.mixin-article .img-wrapper .img-mask img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.mixin-article .mixin-gmaps {
  overflow: visible;
}
.mixin-article .mixin-gmaps .wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200%;
  height: 100% !important;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.mixin-article .mixin-gmaps > a {
  left: auto;
  right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-article.left .clm-wrapper .clm img {
  position: absolute;
  bottom: -200px;
  left: 200px;
  width: 900px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-article.left .clm-wrapper.is-visible-in-viewport .clm img {
  left: 150px;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-article.left.parallax .clm-wrapper .clm img {
  left: 25%;
}
.mixin-article.right .clm-wrapper .clm h3 {
  max-width: 500px;
}
.mixin-article.right .clm-wrapper .clm p {
  max-width: 500px;
}
.mixin-article.right .clm-wrapper .clm img {
  position: absolute;
  bottom: -200px;
  right: 110px;
  width: 1000px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-article.right .clm-wrapper.is-visible-in-viewport .clm img {
  right: 60px;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-article.right.parallax .clm-wrapper .clm img {
  right: 25%;
}
.mixin-article.parallax .clm-wrapper .clm img {
  top: -500px;
  bottom: auto;
  width: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 1s ease-out 0s;
  -moz-transition: opacity 1s ease-out 0s;
  -ms-transition: opacity 1s ease-out 0s;
  -o-transition: opacity 1s ease-out 0s;
  transition: opacity 1s ease-out 0s;
}
.mixin-article.parallax .clm-wrapper .clm img.img-lazy-waiting {
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
.mixin-article.parallax .clm-wrapper .clm img.img-lazy-loaded {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media screen and (max-width: 1500px) {
  .mixin-article .clm-wrapper .clm.includes-image-landscape img {
    width: auto !important;
    height: 200% !important;
  }
  .mixin-article .img-wrapper,
  .mixin-article .mixin-gmaps {
    height: 400px;
  }
  .mixin-article .img-wrapper .img-mask,
  .mixin-article .mixin-gmaps .img-mask {
    width: 150%;
  }
  .mixin-article .img-wrapper .img-mask img,
  .mixin-article .mixin-gmaps .img-mask img {
    width: auto;
    max-height: 400px;
  }
}
@media screen and (max-width: 1350px) {
  .mixin-article.left .clm-wrapper.is-visible-in-viewport .clm.includes-image-landscape img {
    left: 50px;
  }
}
@media screen and (max-width: 1300px) {
  .mixin-article .clm-wrapper .clm.clm-45p {
    width: 35%;
  }
  .mixin-article .clm-wrapper .clm.includes-image-landscape {
    width: 40%;
  }
  .mixin-article .clm-wrapper .clm.includes-image-landscape img {
    right: 0;
    width: auto !important;
    height: 160% !important;
    max-height: 400px !important;
  }
  .mixin-article .clm-wrapper .clm.clm-55p {
    width: 65%;
  }
  .mixin-article .clm-wrapper .clm.clm-55p.includes-sibling-with-image-landscape {
    width: 60%;
  }
  .mixin-article .clm-wrapper .clm.clm-2.includes-sibling-with-image-parallax {
    width: 60%;
  }
  .mixin-article .clm-wrapper .clm.clm-2.includes-image-parallax {
    width: 40%;
  }
}
@media screen and (max-width: 1200px) {
  .mixin-article .img-wrapper {
    margin-top: 50px;
    height: auto;
  }
  .mixin-article .img-wrapper .img-mask {
    position: relative;
    left: -100px;
    right: -100px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mixin-article .img-wrapper .img-mask img {
    position: relative;
    top: 0;
    left: 50%;
    width: 100%;
    min-width: 1000px;
    min-height: 250px;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
@media screen and (max-width: 1150px) {
  .mixin-article .clm-wrapper .clm.clm-55p {
    width: 75%;
  }
  .mixin-article .clm-wrapper .clm.clm-2.includes-sibling-with-image {
    width: 60%;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-article .clm-wrapper .clm {
    content: "ignore-parent-min-height";
    padding: 0 !important;
  }
  .mixin-article .clm-wrapper .clm.includes-image-parallax {
    display: none !important;
  }
  .mixin-article .clm-wrapper .clm.clm-45p.includes-image-landscape {
    padding-right: 0;
    width: 100%;
  }
  .mixin-article .clm-wrapper .clm.clm-55p {
    width: 100% !important;
  }
  .mixin-article .clm-wrapper .clm.clm-55p h3,
  .mixin-article .clm-wrapper .clm.clm-55p p {
    max-width: none;
  }
  .mixin-article .clm-wrapper .clm.clm-2 {
    width: 100% !important;
  }
  .mixin-article .clm-wrapper .clm.clm-2 > ul {
    margin: 0 auto 40px auto;
    max-width: 500px;
    text-align: center;
  }
  .mixin-article .clm-wrapper .clm.clm-2 > ul > li p {
    padding: 0;
  }
  .mixin-article .clm-wrapper .clm.clm-2 > ul > li ul li {
    padding-left: 40px;
  }
  .mixin-article .clm-wrapper .clm.clm-2 > ul > li ul li:before {
    left: 0;
  }
  .mixin-article .clm-wrapper .clm.includes-image {
    height: 300px;
    overflow: hidden;
  }
  .mixin-article .clm-wrapper .clm.includes-image img {
    position: relative;
    top: 50% !important;
    left: 0 !important;
    bottom: auto !important;
    width: 100% !important;
    height: auto !important;
    max-height: none !important;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  .mixin-article .clm-wrapper .clm:last-child {
    margin-top: 20px;
  }
}
@media screen and (max-width: 850px) {
  .mixin-article .clm-wrapper .clm {
    padding: 0 !important;
  }
}
@media screen and (max-width: 500px) {
  .mixin-article .img-wrapper,
  .mixin-article .mixin-gmaps {
    margin-top: 30px;
  }
  .mixin-article.right .clm-wrapper .clm h3 {
    font-size: 26px;
  }
}
@media screen and (max-width: 400px) {
  .mixin-article .clm-wrapper .clm h3 {
    font-size: 22px;
  }
  .mixin-article .clm-wrapper .clm.includes-image {
    height: 200px;
  }
  .mixin-article .clm-wrapper .clm.clm-2 > ul > li ul li {
    padding-left: 25px;
  }
  .mixin-article .mixin-gmaps > a {
    right: -20px;
  }
}
.btn {
  position: relative;
  display: inline-block;
  padding: 4px 15px 2px 30px;
  color: #1e282f;
  font-size: 18px;
  font-weight: 200;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}
.btn span {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  font: inherit;
  text-transform: inherit;
  background: #1e282f;
  overflow: hidden;
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.btn span:before {
  background: #fff;
}
.btn span:after {
  content: attr(data-hover);
  display: inline-block;
  padding: 4px 15px 2px 30px;
  color: #fff;
  font: inherit;
  text-transform: inherit;
  white-space: nowrap;
}
.btn:before,
.btn :before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 1px;
}
.btn:before {
  background: #1e282f;
}
.btn:hover span {
  width: 100%;
}
.btn.btn-grey-black {
  color: #fff;
  background: #1e282f;
}
.btn.btn-grey-black span {
  background: #fff;
}
.btn.btn-grey-black span:before {
  background: #1e282f;
}
.btn.btn-grey-black span:after {
  color: #1e282f;
}
.btn.btn-grey-black:before {
  background: #fff;
}
.btn.btn-white {
  color: #1e282f;
  background: #fff;
}
.btn.btn-white span {
  background: #1e282f;
}
.btn.btn-white span:before {
  background: #fff;
}
.btn.btn-white span:after {
  color: #fff;
}
.btn.btn-white:before {
  background: #1e282f;
}
.btn.btn-white-hover-orange {
  color: #1e282f;
  background: #fff;
}
.btn.btn-white-hover-orange span {
  background: #fa7921;
}
.btn.btn-white-hover-orange span:before {
  background: #fff;
}
.btn.btn-white-hover-orange span:after {
  color: #fff;
}
.btn.btn-white-hover-orange:before {
  background: #1e282f;
}
.btn.btn-orange {
  color: #fff;
  background: #fa7921;
}
.btn.btn-orange span {
  background: #fff;
}
.btn.btn-orange span:before {
  background: #1e282f;
}
.btn.btn-orange span:after {
  color: #1e282f;
}
.btn.btn-orange:before {
  background: #fff;
}
.btn.btn-orange-hover-black {
  color: #fff;
  background: #fa7921;
}
.btn.btn-orange-hover-black span {
  background: #1e282f;
}
.btn.btn-orange-hover-black span:before {
  background: #fff;
}
.btn.btn-orange-hover-black span:after {
  color: #fff;
}
.btn.btn-orange-hover-black:before {
  background: #fff;
}

.mixin-burger {
  position: absolute;
  top: 19px;
  left: 30px;
  padding: 10px;
  cursor: pointer;
}
.mixin-burger div {
  position: relative;
  width: 20px;
  height: 1px;
  margin-bottom: 4px;
}
.mixin-burger div:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1e282f;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-burger div:nth-child(2):after {
  width: 10px;
}
.mixin-burger div:nth-child(3):after {
  width: 15px;
}
.mixin-burger div:nth-child(4):after {
  width: 5px;
}
.mixin-burger:hover div:after {
  width: 100% !important;
}

.mixin-gmaps {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-gmaps .wrapper {
  height: 100%;
  background: #f4f4f4;
}
.mixin-gmaps .wrapper .gmnoprint,
.mixin-gmaps .wrapper .gm-style-cc,
.mixin-gmaps .wrapper .gm-style > div > a {
  display: none !important;
}
.mixin-gmaps .close {
  position: absolute;
  top: 0;
  right: -200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
}
.mixin-gmaps > a {
  position: absolute;
  top: 0;
  left: -200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
}

#header.show-gmaps .mixin-gmaps .wrapper {
  height: 100% !important;
}
#header.show-gmaps .mixin-gmaps .close {
  right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#header.show-gmaps .mixin-gmaps > a {
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
}

@media screen and (max-width: 700px) {
  .mixin-gmaps > a {
    top: auto;
    bottom: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
}
.mixin-headline {
  text-align: center;
}
.mixin-headline .clm {
  position: relative;
  padding-top: 40px;
  width: 55%;
}
.mixin-headline .clm span {
  color: #acaaaf;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}
.mixin-headline .clm h2 {
  color: #fa7921;
  font-size: 50px;
  font-weight: 400;
  line-height: 1.3em;
  text-transform: uppercase;
  background-image: url("public/images/assets/layout/global/pattern-orange.png");
  background-position: center;
  font-family: "Clarendon BT", sans-serif;
  -webkit-hyphens: manual;
  -moz-hyphens: manual;
  -ms-hyphens: manual;
  -o-hyphens: manual;
  hyphens: manual;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  -ms-text-fill-color: rgba(0, 0, 0, 0);
  -o-text-fill-color: rgba(0, 0, 0, 0);
  text-fill-color: rgba(0, 0, 0, 0);
}
.mixin-headline .clm p {
  margin-top: 20px;
  color: #6b7074;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5em;
}
.mixin-headline .clm:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 5px;
  background: #fa7921;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-transition: all 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  -moz-transition: all 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  -ms-transition: all 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  -o-transition: all 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  transition: all 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}
.mixin-headline .clm.is-visible-in-viewport:after {
  width: 100px;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-headline.left {
  text-align: left;
}
.mixin-headline.left .clm {
  padding: 0 0 40px 0;
}
.mixin-headline.left .clm:after {
  top: auto;
  bottom: 0;
  left: 0;
  width: 0;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.mixin-headline.left .clm.is-visible-in-viewport:after {
  width: 200px;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-headline.right {
  text-align: right;
}
.mixin-headline.right .clm {
  padding: 0 0 40px 0;
  text-align: left;
}
.mixin-headline.right .clm:after {
  top: auto;
  bottom: 0;
  left: 200%;
  width: 1000px;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.mixin-headline.right .clm.is-visible-in-viewport:after {
  left: 60%;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-headline.center .clm {
  width: auto;
  max-width: 700px !important;
}

html.isMobile .mixin-headline .clm:after {
  width: 100px;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
html.isMobile .mixin-headline.left .clm:after {
  width: 200px;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
html.isMobile .mixin-headline.right .clm:after {
  left: 60%;
  width: 200%;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

@media screen and (max-width: 1360px) {
  .mixin-headline .clm h2 {
    font-size: 46px;
  }
}
@media screen and (max-width: 1300px) {
  .mixin-headline .clm h2 {
    font-size: 46px;
  }
  .mixin-headline.left .clm {
    width: 60%;
  }
  .mixin-headline.right .clm {
    width: 65%;
  }
}
@media screen and (max-width: 1150px) {
  .mixin-headline.right .clm {
    width: 75%;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-headline .clm {
    width: 100% !important;
  }
}
@media screen and (max-width: 700px) {
  .mixin-headline .clm h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 500px) {
  .mixin-headline .clm h2 {
    font-size: 32px;
  }
  .mixin-headline .clm p {
    font-size: 18px;
  }
}
.mixin-pagination {
  margin-top: 100px;
  font-size: 0;
  text-align: center;
}
.mixin-pagination .controls {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: 5px;
}
.mixin-pagination .controls span {
  display: none;
}
.mixin-pagination .controls:before, .mixin-pagination .controls:after {
  content: "";
  position: absolute;
  background: #acaaaf;
  -webkit-transition: background 0.3s ease-out 0s;
  -moz-transition: background 0.3s ease-out 0s;
  -ms-transition: background 0.3s ease-out 0s;
  -o-transition: background 0.3s ease-out 0s;
  transition: background 0.3s ease-out 0s;
}
.mixin-pagination .controls:before {
  top: 50%;
  left: 5px;
  right: 4px;
  height: 1px;
}
.mixin-pagination .controls.active {
  cursor: pointer;
}
.mixin-pagination .controls.active:hover:before, .mixin-pagination .controls.active:hover:after {
  background: #6b7074;
}
.mixin-pagination .controls.next:after {
  top: 5px;
  bottom: 4px;
  left: 50%;
  width: 1px;
}
.mixin-pagination ul {
  display: inline-block;
  font-size: 0;
}
.mixin-pagination ul li {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  padding-top: 5px;
  font-size: 0;
  text-align: center;
}
.mixin-pagination ul li a {
  position: absolute;
  top: 1px;
  left: 0;
  right: -2px;
  z-index: 1;
  height: 100%;
  font-size: 0;
}
.mixin-pagination ul li a span {
  display: none;
}
.mixin-pagination ul li a:before {
  content: attr(data-idx);
  color: #acaaaf;
  font-size: 20px;
  line-height: 30px;
  -webkit-transition: color 0.3s ease-out 0s;
  -moz-transition: color 0.3s ease-out 0s;
  -ms-transition: color 0.3s ease-out 0s;
  -o-transition: color 0.3s ease-out 0s;
  transition: color 0.3s ease-out 0s;
}
.mixin-pagination ul li a:hover:before {
  color: #6b7074;
}
.mixin-pagination ul li.active a:before {
  color: #6b7074;
}
.mixin-pagination ul li.active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #acaaaf;
}
.mixin-pagination ul li.dots {
  width: auto;
}
.mixin-pagination ul li.dots span {
  position: relative;
  top: -10px;
  color: #acaaaf;
  font-size: 20px;
}

@media screen and (max-width: 800px) {
  .mixin-pagination {
    margin-top: 60px;
  }
}
@media screen and (max-width: 600px) {
  .mixin-pagination {
    margin-top: 40px;
  }
  .mixin-pagination ul li {
    width: 20px;
    margin: 0 3px;
  }
  .mixin-pagination ul li a:before {
    font-size: 14px;
  }
  .mixin-pagination ul li.dots span {
    top: -4px;
    font-size: 14px;
  }
}
.mixin-section {
  padding: 100px 0;
}
.mixin-section.image-before {
  padding-top: 200px;
}
.mixin-section.image-bottom, .mixin-section.gmaps-bottom {
  padding-bottom: 0;
}
.mixin-section:last-child {
  overflow: hidden;
}
.mixin-section:last-child.image-parallax {
  overflow: visible;
}

@media screen and (max-width: 1000px) {
  .mixin-section.image-before {
    padding-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .mixin-section {
    padding: 50px 0;
  }
}
.mixin-tiles {
  margin-top: 100px;
}
.mixin-tiles ul li {
  position: relative;
}
.mixin-tiles ul li .clm {
  position: relative;
  overflow: visible;
}
.mixin-tiles ul li .clm:nth-child(1) {
  z-index: 2;
  background: #1e282f;
}
.mixin-tiles ul li .clm:nth-child(1) h4 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 40px 70px;
  color: #f4f4f4;
  font-size: 40px;
  line-height: 1.3em;
  text-align: center;
  background-image: url("public/images/assets/layout/global/pattern-white.png");
  background-position: center;
  font-family: "Clarendon BT", sans-serif;
  -webkit-hyphens: manual;
  -moz-hyphens: manual;
  -ms-hyphens: manual;
  -o-hyphens: manual;
  hyphens: manual;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  -ms-text-fill-color: rgba(0, 0, 0, 0);
  -o-text-fill-color: rgba(0, 0, 0, 0);
  text-fill-color: rgba(0, 0, 0, 0);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.mixin-tiles ul li .clm:nth-child(1):after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  border-left: 10px solid #1e282f;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.mixin-tiles ul li .clm:nth-child(2) {
  z-index: 1;
  background: #6b7074;
}
.mixin-tiles ul li .clm:nth-child(2) p {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 30px 70px;
  color: #f4f4f4;
  font-weight: 100;
  text-align: center;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.mixin-tiles ul li .clm:nth-child(2) p em {
  font-weight: 500;
}
.mixin-tiles ul li .clm:nth-child(2) a {
  position: absolute;
  bottom: 40px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.mixin-tiles ul li .clm:nth-child(3) {
  position: absolute;
  left: 33.33%;
  -webkit-transition: left 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: left 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: left 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: left 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: left 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-tiles ul li .clm:nth-child(3) a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mixin-tiles ul li .clm:nth-child(3) a img {
  width: 100%;
}
.mixin-tiles ul li:hover .clm:nth-child(3), .mixin-tiles ul li.is-total-in-viewport .clm:nth-child(3) {
  left: 66.66%;
}
.mixin-tiles ul li:nth-child(even) .clm:nth-child(1) {
  margin-left: 33.333%;
}
.mixin-tiles ul li:nth-child(even):hover .clm:nth-child(3), .mixin-tiles ul li:nth-child(even).is-total-in-viewport .clm:nth-child(3) {
  left: 0;
}

html.isFirefox .mixin-tiles ul li .clm:first-child h4 {
  -webkit-text-fill-color: #f4f4f4;
  -moz-text-fill-color: #f4f4f4;
  -ms-text-fill-color: #f4f4f4;
  -o-text-fill-color: #f4f4f4;
  text-fill-color: #f4f4f4;
}

@media screen and (max-width: 1300px) {
  .mixin-tiles ul li .clm:nth-child(1) h4 {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 36px;
  }
  .mixin-tiles ul li .clm:nth-child(2) p {
    top: 40%;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 1100px) {
  .mixin-tiles ul li .clm:nth-child(1) h4 {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 30px;
  }
  .mixin-tiles ul li .clm:nth-child(2) p {
    padding-left: 30px;
    padding-right: 30px;
  }
  .mixin-tiles ul li .clm:nth-child(2) a {
    bottom: 30px;
  }
}
@media screen and (max-width: 1000px) {
  .mixin-tiles {
    margin-top: 60px;
  }
  .mixin-tiles ul li {
    margin-bottom: 20px;
    background: #1e282f;
  }
  .mixin-tiles ul li .clm:nth-child(1), .mixin-tiles ul li .clm:nth-child(2) {
    position: relative;
    left: 0 !important;
    width: 66.66%;
    text-align: center;
    background: #1e282f;
  }
  .mixin-tiles ul li .clm:nth-child(1) h4,
  .mixin-tiles ul li .clm:nth-child(1) p,
  .mixin-tiles ul li .clm:nth-child(1) a, .mixin-tiles ul li .clm:nth-child(2) h4,
  .mixin-tiles ul li .clm:nth-child(2) p,
  .mixin-tiles ul li .clm:nth-child(2) a {
    position: static;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mixin-tiles ul li .clm:nth-child(1) h4, .mixin-tiles ul li .clm:nth-child(2) h4 {
    padding-bottom: 0;
  }
  .mixin-tiles ul li .clm:nth-child(1) a, .mixin-tiles ul li .clm:nth-child(2) a {
    margin-bottom: 40px;
  }
  .mixin-tiles ul li .clm:nth-child(1):before, .mixin-tiles ul li .clm:nth-child(1):after, .mixin-tiles ul li .clm:nth-child(2):before, .mixin-tiles ul li .clm:nth-child(2):after {
    content: none;
  }
  .mixin-tiles ul li .clm:nth-child(3) {
    top: 0;
    left: auto;
    right: 0;
    width: 33.33%;
    height: 100%;
  }
  .mixin-tiles ul li .clm:nth-child(3) a img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .mixin-tiles ul li:nth-child(even) {
    background: #6b7074;
  }
  .mixin-tiles ul li:nth-child(even) .clm:nth-child(1), .mixin-tiles ul li:nth-child(even) .clm:nth-child(2) {
    margin-left: 33.33%;
    background: #6b7074;
  }
  .mixin-tiles ul li:nth-child(even) .clm:nth-child(3) {
    left: 0;
    right: auto;
  }
  .mixin-tiles ul li:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .mixin-tiles ul li .clm:nth-child(1), .mixin-tiles ul li .clm:nth-child(2) {
    width: 100%;
    margin-left: 0 !important;
  }
  .mixin-tiles ul li .clm:nth-child(1) h4,
  .mixin-tiles ul li .clm:nth-child(1) p, .mixin-tiles ul li .clm:nth-child(2) h4,
  .mixin-tiles ul li .clm:nth-child(2) p {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mixin-tiles ul li .clm:nth-child(1) {
    margin-top: 300px;
  }
  .mixin-tiles ul li .clm:nth-child(3) {
    left: 0 !important;
    width: 100%;
  }
  .mixin-tiles ul li .clm:nth-child(3) a {
    height: 300px;
  }
  .mixin-tiles ul li .clm:nth-child(3) a img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 450px) {
  .mixin-tiles ul li .clm:nth-child(1) {
    margin-top: 200px;
  }
  .mixin-tiles ul li .clm:nth-child(3) a {
    height: 200px;
  }
}
.mixin-timeline {
  margin-top: 100px;
}
.mixin-timeline ul {
  position: relative;
  font-size: 0;
}
.mixin-timeline ul li {
  position: relative;
  left: -50px;
  margin: 70px 0;
  padding-right: 60px;
  width: 50%;
  text-align: right;
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-timeline ul li > div {
  position: relative;
  display: inline-block;
  max-width: 300px;
}
.mixin-timeline ul li > div h3 {
  color: #6b7074;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-timeline ul li > div p {
  color: #acaaaf;
  font-size: 14px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-timeline ul li > div p.link-wrapper {
  margin-top: 20px;
}
.mixin-timeline ul li > div .link-wrapper {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-timeline ul li > div .link-wrapper a,
.mixin-timeline ul li > div .link-wrapper span {
  color: #acaaaf;
  font-size: 14px;
}
.mixin-timeline ul li > div .date {
  position: absolute;
  top: -11px;
  right: -150px;
  width: 80px;
  text-align: center;
  background: #f4f4f4;
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-timeline ul li > div .date span {
  display: block;
  position: relative;
  z-index: 1;
  color: #acaaaf;
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
}
.mixin-timeline ul li:nth-child(even) {
  left: 50px;
  margin-left: 50%;
  padding-left: 60px;
  padding-right: 0;
  text-align: left;
}
.mixin-timeline ul li:nth-child(even) > div .date {
  left: -150px;
  right: auto;
}
.mixin-timeline ul li.is-visible-in-viewport {
  left: 0;
}
.mixin-timeline ul li.is-visible-in-viewport > div h3,
.mixin-timeline ul li.is-visible-in-viewport > div p,
.mixin-timeline ul li.is-visible-in-viewport > div .link-wrapper {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
.mixin-timeline ul li.is-visible-in-viewport > div .date {
  right: -100px;
}
.mixin-timeline ul li.is-visible-in-viewport:nth-child(even) > div .date {
  left: -100px;
  right: auto;
}
.mixin-timeline ul:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: #acaaaf;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -ms-filter: alpha(opacity=30);
}

html.isMobile .mixin-timeline ul li {
  left: 0;
}
html.isMobile .mixin-timeline ul li > div h3,
html.isMobile .mixin-timeline ul li > div p {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
html.isMobile .mixin-timeline ul li > div .date {
  right: -100px;
}
html.isMobile .mixin-timeline ul li:nth-child(even) > div .date {
  left: -100px;
  right: auto;
}

@media screen and (max-width: 600px) {
  .mixin-timeline ul li {
    position: static;
    width: auto;
    padding: 0 !important;
    margin: 0 0 80px 0 !important;
    text-align: center !important;
  }
  .mixin-timeline ul li > div {
    padding-bottom: 20px;
    text-align: center;
    background: #f4f4f4;
  }
  .mixin-timeline ul li > div h3 {
    position: relative;
    z-index: 1;
    padding: 40px 0 10px 0;
  }
  .mixin-timeline ul li > div .date {
    top: 0;
    left: 50% !important;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .mixin-timeline ul li:last-child {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 500px) {
  .mixin-timeline {
    margin-top: 50px;
  }
}
/* == PROJECT / MODULES ====================================================== */
#address {
  position: relative;
  z-index: 30;
  padding: 16px 0 14px 0;
  text-align: center;
  background: #fff;
}
#address .center-header-ctn {
  position: relative;
}
#address .center-header-ctn nav {
  position: absolute;
  top: 3px;
  left: 0;
}
#address .center-header-ctn nav ul li {
  display: inline-block;
  margin-right: 7px;
}
#address .center-header-ctn nav ul li a {
  color: #acaaaf;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.4;
  filter: alpha(opacity=40);
  -ms-filter: alpha(opacity=40);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#address .center-header-ctn nav ul li a:hover, #address .center-header-ctn nav ul li a.active {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#address .center-header-ctn address {
  display: inline-block;
  font-size: 14px;
  color: #6b7074;
  font-family: "Clarendon BT", sans-serif;
}
#address .center-header-ctn address a {
  color: inherit;
  font: inherit;
}
#address .center-header-ctn .wrapper-phone-icon {
  display: inline-block;
}
#address .center-header-ctn .wrapper-phone-icon .phone {
  display: inline-block;
  margin: 0 10px;
  color: #acaaaf;
  font-size: 14px;
}
#address .center-header-ctn .wrapper-phone-icon .icon {
  top: 2px;
  padding: 10px;
  margin: -10px;
  color: #6b7074;
  font-size: 16px;
  cursor: pointer;
}
#address .center-header-ctn .opening-hours {
  position: absolute;
  bottom: 4px;
  right: 0;
  color: #acaaaf;
  font-size: 12px;
}
#address .center-header-ctn .opening-hours sup {
  color: inherit;
  font-family: inherit;
  font-variant: unset;
  font-size: 8px;
}

@media screen and (max-width: 950px) {
  #address .center-header-ctn {
    text-align: right;
  }
  #address .center-header-ctn .opening-hours {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  #address {
    padding: 14px 0 0;
  }
  #address .center-header-ctn {
    text-align: right;
  }
  #address .center-header-ctn nav {
    top: -2px;
  }
  #address .center-header-ctn address {
    display: none;
  }
  #address .center-header-ctn .wrapper-phone-icon {
    display: block;
    text-align: center;
  }
  #address .center-header-ctn .wrapper-phone-icon .phone {
    font-size: 12px;
    line-height: 1em;
  }
  #address .center-header-ctn .wrapper-phone-icon .icon {
    position: absolute;
    top: 0;
    right: 3px;
    font-size: 14px;
  }
}
#footer {
  position: relative;
  z-index: 10;
  width: 100%;
  overflow-x: hidden;
  background: #fff;
}
#footer .external-links {
  font-size: 0;
  padding: 20px 0;
  text-align: right;
  background: #fff;
  border-top: 5px solid #fa7921;
}
#footer .external-links .social-media {
  float: left;
  position: relative;
  top: 1px;
  margin-right: 6px;
  overflow: hidden;
}
#footer .external-links .social-media .icon {
  display: inline-block;
  position: relative;
  width: 31px;
  height: 31px;
  color: #fa7921;
  border: 1px solid #fa7921;
}
#footer .external-links .social-media .icon.icon-facebook {
  font-size: 23px;
  color: #fff;
  -webkit-text-stroke: 1px #fa7921;
  -moz-text-stroke: 1px #fa7921;
  -ms-text-stroke: 1px #fa7921;
  -o-text-stroke: 1px #fa7921;
  text-stroke: 1px #fa7921;
}
#footer .external-links .social-media .icon.icon-facebook:before {
  position: absolute;
  bottom: -1px;
  right: 0;
}
#footer .external-links .social-media .icon.icon-instagram {
  position: relative;
}
#footer .external-links .social-media .icon.icon-instagram .flash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#footer .external-links .social-media .icon.icon-instagram .flash:before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 4px;
  height: 4px;
  border: 1px solid #fa7921;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
#footer .external-links .social-media .icon.icon-instagram .flash:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fa7921;
}
#footer .external-links .social-media .icon.icon-instagram .lens {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid #fa7921;
  background: #fff;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#footer .external-links .social-media .icon.icon-instagram .lens:before {
  content: "";
  position: inherit;
  top: inherit;
  left: inherit;
  width: 8px;
  height: 8px;
  border: inherit;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#footer .external-links .social-media .icon.icon-tripadvisor span {
  position: absolute;
}
#footer .external-links .social-media .icon.icon-tripadvisor .nose {
  top: 9px;
  left: 50%;
  width: 10px;
  height: 10px;
  overflow: hidden;
  border: 1px solid #fa7921;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#footer .external-links .social-media .icon.icon-tripadvisor .ear {
  top: 10px;
  width: 10px;
  height: 10px;
  overflow: hidden;
  border-top: 1px solid #fa7921;
}
#footer .external-links .social-media .icon.icon-tripadvisor .ear:before {
  content: "";
  position: absolute;
  top: -20%;
  left: -20%;
  width: 100%;
  height: 100%;
}
#footer .external-links .social-media .icon.icon-tripadvisor .ear.left {
  left: 2px;
}
#footer .external-links .social-media .icon.icon-tripadvisor .ear.left:before {
  border-left: 1px solid #fa7921;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
#footer .external-links .social-media .icon.icon-tripadvisor .ear.right {
  right: 2px;
}
#footer .external-links .social-media .icon.icon-tripadvisor .ear.right:before {
  border-bottom: 1px solid #fa7921;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(315deg);
  -moz-transform: rotateZ(315deg);
  -ms-transform: perspective(0) rotateZ(315deg);
  -o-transform: perspective(0) rotateZ(315deg);
  transform: perspective(0) rotateZ(315deg);
}
#footer .external-links .social-media .icon.icon-tripadvisor .head {
  top: 7px;
  left: 50%;
  width: 15px;
  height: 6px;
  background: #fff;
  border: 1px solid #fa7921;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
#footer .external-links .social-media .icon.icon-tripadvisor .eye {
  top: 9px;
  width: 12px;
  height: 12px;
  border: 1px solid #fa7921;
  background: #fff;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
#footer .external-links .social-media .icon.icon-tripadvisor .eye:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  border: 1px solid #fa7921;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#footer .external-links .social-media .icon.icon-tripadvisor .eye.left {
  left: 3px;
}
#footer .external-links .social-media .icon.icon-tripadvisor .eye.right {
  right: 3px;
}
#footer .external-links .mixin-newsletter {
  display: inline-block;
}
#footer .internal-links {
  position: relative;
  padding-top: 20px;
  font-size: 0;
  text-align: center;
}
#footer .internal-links .clm-wrapper {
  position: relative;
}
#footer .internal-links .clm-wrapper .clm {
  padding: 0 20px;
}
#footer .internal-links .clm-wrapper .clm .h5-style,
#footer .internal-links .clm-wrapper .clm ul,
#footer .internal-links .clm-wrapper .clm nav,
#footer .internal-links .clm-wrapper .clm address {
  max-width: 500px;
}
#footer .internal-links .clm-wrapper .clm .h5-style {
  display: block;
  padding-bottom: 5px;
  margin-bottom: 10px;
  color: #6b7074;
  font-size: 20px;
  line-height: 1.3em;
  text-transform: uppercase;
  border-bottom: 1px solid #acaaaf;
}
#footer .internal-links .clm-wrapper .clm .h5-style em {
  font-weight: 500;
}
#footer .internal-links .clm-wrapper .clm:nth-child(1) {
  text-align: center;
}
#footer .internal-links .clm-wrapper .clm:nth-child(1) .h5-style,
#footer .internal-links .clm-wrapper .clm:nth-child(1) nav {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
#footer .internal-links .clm-wrapper .clm:nth-child(1) .h5-style ul,
#footer .internal-links .clm-wrapper .clm:nth-child(1) nav ul {
  font-size: 0;
}
#footer .internal-links .clm-wrapper .clm:nth-child(1) .h5-style ul li,
#footer .internal-links .clm-wrapper .clm:nth-child(1) nav ul li {
  display: inline-block;
  margin: 4px;
}
#footer .internal-links .clm-wrapper .clm:nth-child(1) .h5-style ul li a,
#footer .internal-links .clm-wrapper .clm:nth-child(1) nav ul li a {
  padding: 2px 8px 0 8px;
  color: #acaaaf;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #acaaaf;
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#footer .internal-links .clm-wrapper .clm:nth-child(1) .h5-style ul li a:hover,
#footer .internal-links .clm-wrapper .clm:nth-child(1) nav ul li a:hover {
  color: #6b7074;
  border-color: #6b7074;
}
#footer .internal-links .clm-wrapper .clm:nth-child(2), #footer .internal-links .clm-wrapper .clm:nth-child(3) {
  position: absolute;
  top: 0;
}
#footer .internal-links .clm-wrapper .clm:nth-child(2) {
  left: 0;
  padding-left: 0;
}
#footer .internal-links .clm-wrapper .clm:nth-child(2) .h5-style {
  text-align: left;
}
#footer .internal-links .clm-wrapper .clm:nth-child(2) ul li {
  margin-bottom: 5px;
}
#footer .internal-links .clm-wrapper .clm:nth-child(2) ul li a {
  display: block;
  position: relative;
  text-align: left;
}
#footer .internal-links .clm-wrapper .clm:nth-child(2) ul li a time {
  position: absolute;
  top: 2px;
  left: 20px;
  font-family: sans-serif;
  font-size: 12px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#footer .internal-links .clm-wrapper .clm:nth-child(2) ul li a span {
  display: block;
  margin-left: 90px;
  padding-right: 20px;
  font-weight: 200;
}
#footer .internal-links .clm-wrapper .clm:nth-child(2) ul li a:hover time {
  left: 0;
  color: #fa7921;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#footer .internal-links .clm-wrapper .clm:nth-child(3) {
  right: 0;
  padding-right: 0;
  text-align: right;
}
#footer .internal-links .clm-wrapper .clm:nth-child(3) h5,
#footer .internal-links .clm-wrapper .clm:nth-child(3) address {
  margin-left: auto;
}
#footer .internal-links .clm-wrapper .clm:nth-child(3) h5 span,
#footer .internal-links .clm-wrapper .clm:nth-child(3) address span {
  display: block;
  margin: 0 20px 5px 0;
  color: #6b7074;
}
#footer .internal-links .clm-wrapper .clm:nth-child(3) h5 span a,
#footer .internal-links .clm-wrapper .clm:nth-child(3) address span a {
  color: inherit;
  font-weight: 200;
}
#footer .internal-links:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 1px;
  background: #f4f4f4;
}
#footer .legal-details {
  position: relative;
  margin-top: 20px;
  padding: 20px 0;
}
#footer .legal-details .center-wide-ctn {
  position: relative;
}
#footer .legal-details .center-wide-ctn .webdesign {
  position: absolute;
  top: 0;
  left: 50%;
  color: #acaaaf;
  font-weight: 200;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
#footer .legal-details .center-wide-ctn .webdesign a {
  color: inherit;
  font-weight: 300;
  text-decoration: none;
  font-family: "Roboto, Galano Grotesque", sans-serif;
}
#footer .legal-details .center-wide-ctn .webdesign a span {
  color: inherit;
  font: inherit;
  font-weight: 400;
}
#footer .legal-details .center-wide-ctn #slideup .links .label {
  color: #acaaaf;
  font-weight: 200;
}
#footer .legal-details .center-wide-ctn #slideup .links .label.active {
  text-decoration: underline;
}
#footer .legal-details .center-wide-ctn #slideup .contents .content {
  padding: 80px 0 20px 0;
  text-align: center;
}
#footer .legal-details .center-wide-ctn #slideup .contents .content h4 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0 10px 0;
  font-size: 20px;
  text-transform: uppercase;
}
#footer .legal-details .center-wide-ctn #slideup .contents .content p {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 10px;
  color: #acaaaf;
  font-weight: 200;
}
#footer .legal-details .center-wide-ctn #slideup .contents .content a {
  color: inherit;
  font: inherit;
  text-decoration: underline;
}
#footer .legal-details .center-wide-ctn #slideup .close {
  top: 20px;
}
#footer .legal-details .center-wide-ctn .copyright {
  position: absolute;
  top: 0;
  right: 0;
  color: #acaaaf;
  font-weight: 200;
}
#footer .legal-details:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 1px;
  background: #f4f4f4;
}

@media screen and (max-width: 1550px) {
  #footer .internal-links .clm-wrapper .clm:nth-child(1) {
    display: block;
    margin-bottom: 40px;
    width: 100%;
  }
  #footer .internal-links .clm-wrapper .clm:nth-child(2), #footer .internal-links .clm-wrapper .clm:nth-child(3) {
    position: relative;
    width: 50%;
  }
}
@media screen and (max-width: 1200px) {
  #footer .legal-details .center-wide-ctn {
    padding-top: 40px;
  }
  #footer .legal-details .center-wide-ctn .copyright {
    top: 40px;
  }
}
@media screen and (max-width: 1000px) {
  #footer .internal-links .clm-wrapper .clm:nth-child(1), #footer .internal-links .clm-wrapper .clm:nth-child(2), #footer .internal-links .clm-wrapper .clm:nth-child(3) {
    display: block;
    margin-bottom: 60px;
    padding: 0;
    width: 100%;
  }
  #footer .internal-links .clm-wrapper .clm:nth-child(1) .h5-style,
  #footer .internal-links .clm-wrapper .clm:nth-child(1) ul, #footer .internal-links .clm-wrapper .clm:nth-child(2) .h5-style,
  #footer .internal-links .clm-wrapper .clm:nth-child(2) ul, #footer .internal-links .clm-wrapper .clm:nth-child(3) .h5-style,
  #footer .internal-links .clm-wrapper .clm:nth-child(3) ul {
    margin: 0 auto;
    text-align: center;
  }
  #footer .internal-links .clm-wrapper .clm:nth-child(1) ul {
    margin-top: 10px;
  }
  #footer .internal-links .clm-wrapper .clm:nth-child(2) ul li a {
    display: inline-block;
    text-align: center;
  }
  #footer .internal-links .clm-wrapper .clm:nth-child(2) ul li a time {
    display: block;
    position: static;
    padding-top: 10px;
  }
  #footer .internal-links .clm-wrapper .clm:nth-child(2) ul li a span {
    margin: 0;
    padding: 0;
  }
  #footer .internal-links .clm-wrapper .clm:nth-child(3) address {
    margin: 10px auto 0 auto;
    text-align: center;
  }
  #footer .internal-links .clm-wrapper .clm:nth-child(3) address span {
    margin-right: 0;
  }
  #footer .legal-details .center-wide-ctn {
    padding-bottom: 40px;
  }
  #footer .legal-details .center-wide-ctn .webdesign {
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  #footer .legal-details .center-wide-ctn #slideup .links {
    text-align: center;
  }
  #footer .legal-details .center-wide-ctn #slideup .links .label {
    margin: 0 10px;
  }
  #footer .legal-details .center-wide-ctn #slideup .links .label:first-child {
    margin-left: 0;
  }
  #footer .legal-details .center-wide-ctn #slideup .links .label:last-child {
    margin-right: 0;
  }
  #footer .legal-details .center-wide-ctn .copyright {
    top: auto;
    bottom: 0;
    left: 0;
    right: auto;
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  #footer .legal-details .center-wide-ctn #slideup .links .label {
    margin: 0 5px;
  }
}
@media screen and (max-width: 400px) {
  #footer .external-links .mixin-newsletter {
    position: relative;
    left: 20px;
  }
  #footer .legal-details .center-wide-ctn .webdesign {
    font-size: 14px;
  }
  #footer .legal-details .center-wide-ctn .webdesign * {
    font-size: inherit;
  }
  #footer .legal-details .center-wide-ctn .copyright,
  #footer .legal-details .center-wide-ctn #slideup .links .label {
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  #footer .legal-details .center-wide-ctn #slideup .links .label {
    margin: 0 3px;
  }
}
#gallery {
  padding: 100px 0;
}
#gallery article.clm-wrapper {
  clear: both;
  padding-top: 80px;
}
#gallery article.clm-wrapper .clm {
  float: left;
}
#gallery article.clm-wrapper .clm .tile-wrapper {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
#gallery article.clm-wrapper .clm .tile-wrapper.tile-portrait {
  padding-top: 120%;
}
#gallery article.clm-wrapper .clm .tile-wrapper.tile-landscape {
  padding-top: 60%;
}
#gallery article.clm-wrapper .clm .tile-wrapper.tile-panorama {
  padding-top: 30%;
}
#gallery article.clm-wrapper .clm .tile-wrapper.tile-text p {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 0 40px;
  color: #fff;
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
#gallery article.clm-wrapper .clm .tile-wrapper.tile-text:after {
  background: #1e282f;
}
#gallery article.clm-wrapper .clm .tile-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 5px solid #fff;
}
#gallery article.clm-wrapper .clm.clm-2 .tile-wrapper.tile-landscape {
  padding-top: 59.99%;
}

html.isSafari #gallery article.clm-wrapper .clm .tile-wrapper.tile-text p {
  font-weight: 300;
}
html.isSafari #gallery article.clm-wrapper .clm:nth-child(11) {
  margin-left: -100%;
}
html.isSafari #gallery article.clm-wrapper .clm:nth-child(11) .tile-wrapper {
  margin-top: 0;
}

@media screen and (max-width: 1500px) {
  #gallery article.clm-wrapper .clm .tile-wrapper.tile-text p {
    font-size: 30px;
    line-height: 1.3em;
  }
}
@media screen and (max-width: 1200px) {
  #gallery article.clm-wrapper .clm .tile-wrapper {
    padding-top: 60% !important;
  }
  #gallery article.clm-wrapper .clm.clm-2 {
    width: 100%;
  }
  #gallery article.clm-wrapper .clm.clm-2 .tile-wrapper {
    padding-top: 30% !important;
  }
  #gallery article.clm-wrapper .clm.clm-4 {
    width: 50%;
  }
  #gallery article.clm-wrapper .clm.clm-4 .tile-wrapper {
    padding-top: 60% !important;
  }
}
@media screen and (max-width: 800px) {
  #gallery article.clm-wrapper .clm .tile-wrapper.tile-text p {
    font-size: 30px;
  }
}
@media screen and (max-width: 600px) {
  #gallery article.clm-wrapper {
    padding-top: 50px;
  }
  #gallery article.clm-wrapper .clm {
    margin: 0 !important;
  }
  #gallery article.clm-wrapper .clm.clm-2, #gallery article.clm-wrapper .clm.clm-4 {
    width: 100% !important;
  }
  #gallery article.clm-wrapper .clm.clm-2 .tile-wrapper, #gallery article.clm-wrapper .clm.clm-4 .tile-wrapper {
    margin: 0 !important;
    padding-top: 60% !important;
  }
}
@media screen and (max-width: 500px) {
  #gallery {
    padding: 50px 0;
  }
}
#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  -webkit-box-shadow: 0 -1px 8px 1px #1e282f;
  -moz-box-shadow: 0 -1px 8px 1px #1e282f;
  -ms-box-shadow: 0 -1px 8px 1px #1e282f;
  -o-box-shadow: 0 -1px 8px 1px #1e282f;
  box-shadow: 0 -1px 8px 1px #1e282f;
}

#landing {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#landing img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
}
#landing .badge {
  position: fixed;
  top: 50px;
  left: 50px;
  width: 250px;
  height: 250px;
  text-align: center;
  background-image: url("public/images/assets/layout/global/crown-cap.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: -3px 3px;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: perspective(0) rotateZ(-15deg);
  -moz-transform: rotateZ(-15deg);
  -ms-transform: perspective(0) rotateZ(-15deg);
  -o-transform: perspective(0) rotateZ(-15deg);
  transform: perspective(0) rotateZ(-15deg);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#landing .badge h1 {
  margin-top: 10px;
  color: #f4f4f4;
  font-size: 24px;
  line-height: 1.3em;
  background-image: url("public/images/assets/layout/global/pattern-grey-white.png");
  background-position: center;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -o-text-fill-color: transparent;
  text-fill-color: transparent;
  font-family: "Clarendon BT", sans-serif;
}
#landing .badge p,
#landing .badge p span {
  color: #f4f4f4;
  font-size: 14px;
  font-weight: 500;
}
#landing .badge p.circle-type,
#landing .badge p span.circle-type {
  position: absolute;
  letter-spacing: -5px;
  text-transform: uppercase;
}
#landing .badge p.circle-type.top,
#landing .badge p span.circle-type.top {
  top: 48px;
  left: 128px;
}
#landing .badge p.circle-type.bottom,
#landing .badge p span.circle-type.bottom {
  bottom: 45px;
  left: 126px;
}
#landing .badge a {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
#landing .bar {
  position: fixed;
  bottom: 100px;
  left: 0;
  width: 100%;
  text-align: center;
  background: #1e282f;
  border-top: 2px solid #e6dfd5;
  border-bottom: 2px solid #e6dfd5;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
}
#landing .bar .clm-wrapper {
  margin: 0 100px;
}
#landing .bar .clm-wrapper .clm {
  position: relative;
  padding: 40px 0;
}
#landing .bar .clm-wrapper .clm .restaurant {
  position: relative;
  display: inline-block;
}
#landing .bar .clm-wrapper .clm .restaurant .text {
  display: block;
  color: #e6dfd5;
  background-image: url("public/images/assets/layout/global/pattern-white-brown.png");
  background-position: center;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -o-text-fill-color: transparent;
  text-fill-color: transparent;
  font-family: "Clarendon BT", sans-serif;
}
#landing .bar .clm-wrapper .clm .restaurant .text.large {
  color: #e6dfd5;
  font-size: 50px;
}
#landing .bar .clm-wrapper .clm .restaurant .icon.left {
  left: -140px;
}
#landing .bar .clm-wrapper .clm .restaurant .icon.right {
  right: -140px;
}
#landing .bar .clm-wrapper .clm .restaurant .icon:before {
  background-image: url("public/images/assets/layout/global/pattern-white.png");
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -o-text-fill-color: transparent;
  text-fill-color: transparent;
}
#landing .bar .icon {
  position: absolute;
  top: 50%;
  color: #e6dfd5;
  font-size: 140px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0.2;
  filter: alpha(opacity=20);
  -ms-filter: alpha(opacity=20);
}
#landing .bar .icon.outer {
  display: none;
}
#landing .bar .icon.outer.left {
  left: 0;
}
#landing .bar .icon.outer.left:before {
  background-position: 20px 0;
}
#landing .bar .icon.outer.right {
  right: 0;
}
#landing .bar .icon.outer.right:before {
  background-position: 30px 0;
}
#landing .bar .icon:before {
  background-image: url("public/images/assets/layout/global/pattern-white.png");
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -o-text-fill-color: transparent;
  text-fill-color: transparent;
}
#landing .bar:before, #landing .bar:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e6dfd5;
}
#landing .bar:before {
  top: 3px;
}
#landing .bar:after {
  bottom: 3px;
}

@media screen and (max-width: 1600px) {
  #landing .bar .clm-wrapper {
    margin: 0;
  }
}
@media screen and (max-width: 1300px) {
  #landing .bar .clm-wrapper {
    margin: 0 100px;
  }
  #landing .bar .clm-wrapper .clm .restaurant .text {
    font-size: 14px;
  }
  #landing .bar .clm-wrapper .clm .restaurant .text.large {
    font-size: 40px;
  }
  #landing .bar .clm-wrapper .clm .icon {
    display: none;
  }
  #landing .bar .icon.outer {
    display: block;
    font-size: 120px;
  }
  #landing .bar .icon.outer.left {
    left: -15px;
  }
  #landing .bar .icon.outer.right {
    right: -15px;
  }
}
@media screen and (max-width: 1000px) {
  #landing img {
    left: -1000px;
  }
  #landing .bar {
    height: auto;
  }
  #landing .bar .icon.outer.left {
    left: -15px;
  }
  #landing .bar .icon.outer.right {
    right: -15px;
  }
}
@media screen and (max-width: 900px) {
  #landing .bar .clm-wrapper .clm {
    display: block;
    width: 100%;
  }
  #landing .bar .clm-wrapper .clm:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50px;
    width: 200px;
    height: 1px;
    background: #fff;
    opacity: 0.2;
    filter: alpha(opacity=20);
    -ms-filter: alpha(opacity=20);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #landing .bar .icon.outer {
    font-size: 200px;
  }
  #landing .bar .icon.outer.left {
    left: -50px;
  }
  #landing .bar .icon.outer.right {
    right: -50px;
  }
}
@media screen and (max-width: 800px) {
  #landing img {
    left: -1100px;
  }
  #landing .badge {
    top: 20px;
    left: 20px;
  }
}
@media screen and (max-width: 550px) {
  #landing .bar .clm-wrapper .clm .restaurant .text {
    font-size: 12px;
  }
  #landing .bar .clm-wrapper .clm .restaurant .text.large {
    font-size: 30px;
  }
  #landing .bar .icon.outer {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -ms-filter: alpha(opacity=20);
  }
  #landing .bar .icon.outer.left {
    left: -60px;
  }
  #landing .bar .icon.outer.right {
    right: -60px;
  }
}
@media screen and (max-width: 450px) {
  #landing img {
    top: -100px;
  }
  #landing .badge {
    left: -10px;
  }
  #landing .bar {
    bottom: 40px;
  }
  #landing .bar .clm-wrapper {
    margin: 0 20px;
  }
  #landing .bar .clm-wrapper .clm {
    padding: 30px 0;
  }
  #landing .bar .icon.outer {
    margin-top: -100px;
    -webkit-transform: scaleX(0.7);
    -moz-transform: scaleX(0.7);
    -ms-transform: scaleX(0.7);
    -o-transform: scaleX(0.7);
    transform: scaleX(0.7);
  }
  #landing .bar .icon.outer.left {
    left: -70px;
  }
  #landing .bar .icon.outer.right {
    right: -70px;
  }
}
@media screen and (max-width: 400px) {
  #landing img {
    top: -120px;
    left: -1140px;
  }
  #landing .badge {
    top: 0;
    left: -50px;
  }
  #landing .bar .clm-wrapper .clm {
    padding: 20px 0;
  }
}
@media screen and (max-width: 350px) {
  #landing img {
    top: -150px;
  }
  #landing .badge {
    top: -20px;
  }
  #landing .bar {
    bottom: 0;
  }
  #landing .bar .icon.outer {
    display: none;
  }
}
#main {
  z-index: 20;
  width: 100%;
  height: auto;
  overflow-y: visible;
}
#main > section {
  position: relative;
}
#main > section > img[data-parallax] {
  position: absolute;
  top: 0;
  left: 0;
}
#main > section > img[data-parallax].right {
  left: auto;
  right: 0;
}
#main > section.bg-color-grey-white {
  background: rgba(0, 0, 0, 0);
}
#main > section.bg-color-grey-white:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
}

@media screen and (max-width: 1000px) {
  #main {
    overflow: hidden;
  }
}
#menu {
  padding: 100px 0;
}
#menu iframe {
  margin-top: 80px;
}

@media screen and (max-width: 1000px) {
  #menu {
    padding: 100px 0 50px 0;
  }
}
@media screen and (max-width: 750px) {
  #menu {
    padding: 100px 0 20px 0;
  }
  #menu iframe {
    margin-top: 40px;
  }
}
@media screen and (max-width: 700px) {
  #menu {
    padding: 100px 0 20px 0;
  }
}
@media screen and (max-width: 500px) {
  #menu {
    padding: 50px 0 20px 0;
  }
}
#navigation {
  position: relative;
  z-index: 20;
  height: 74px;
  background: #fff;
}
#navigation nav ul li a {
  display: block;
  position: relative;
  padding: 3px 10px;
}
#navigation nav ul li a:hover {
  color: #fff !important;
  background: #1e282f;
}
#navigation nav ul li.current-menu-item > a {
  color: #fff !important;
  background: #1e282f;
}
#navigation nav.navigation-main-aside {
  position: relative;
  height: 0;
  background: #1e282f;
  overflow: hidden;
  -webkit-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#navigation nav.navigation-main-aside > div > div > div {
  font-size: 0;
  padding: 60px 0 40px 0;
  overflow: hidden;
}
#navigation nav.navigation-main-aside > div > div > div > div {
  display: inline-block;
}
#navigation nav.navigation-main-aside > div > div > div > div ul {
  display: block;
}
#navigation nav.navigation-main-aside > div > div > div > div ul li {
  display: block;
  text-align: center;
}
#navigation nav.navigation-main-aside > div > div > div > div ul li a {
  display: inline-block;
  margin: 0 10px;
  color: #acaaaf;
  text-transform: uppercase;
}
#navigation nav.navigation-main-aside > div > div > div > div ul li a:hover {
  background: #fa7921;
}
#navigation nav.navigation-main-aside > div > div > div > div ul li.current-menu-item > a {
  background: #fa7921;
}
#navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) {
  width: 80%;
  height: 100%;
}
#navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) > div {
  display: table;
  width: 100%;
  height: 100%;
}
#navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) > div > ul {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
#navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) > div > ul > li {
  display: inline-block;
  position: relative;
  width: 25%;
}
#navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) > div > ul > li > a {
  border-bottom: 1px solid #6b7074;
}
#navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) > div > ul > li ul li a {
  color: #6b7074;
}
#navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) > div > ul > li:after {
  content: "";
  position: absolute;
  top: -1000px;
  right: 0;
  width: 1px;
  height: 2000px;
  background: #6b7074;
}
#navigation nav.navigation-main-aside > div > div > div > div:nth-child(2) {
  width: 20%;
}
#navigation nav.navigation-main-aside .close {
  position: absolute;
  top: 0;
  right: -200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
}
#navigation nav.navigation-quick {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 22px 0 0 30px;
}
#navigation nav.navigation-quick ul li {
  display: inline-block;
}
#navigation nav.navigation-quick ul li a {
  margin-right: 1px;
  font-size: 18px;
  font-weight: 200;
}
#navigation .center-header-ctn {
  position: relative;
  height: 100%;
  font-size: 0;
  -webkit-transition: margin-top 0.5s ease-out 0s;
  -moz-transition: margin-top 0.5s ease-out 0s;
  -ms-transition: margin-top 0.5s ease-out 0s;
  -o-transition: margin-top 0.5s ease-out 0s;
  transition: margin-top 0.5s ease-out 0s;
}
#navigation .center-header-ctn .logo {
  position: relative;
  z-index: 1;
  height: 74px;
  margin-left: 50px;
  padding: 0 30px;
  text-align: center;
  overflow: hidden;
  background: #1e282f;
}
#navigation .center-header-ctn .logo .text {
  color: #f4f4f4;
  font-family: "Clarendon BT", sans-serif;
}
#navigation .center-header-ctn .logo .text.large {
  position: relative;
  top: 14px;
  font-size: 28px;
  line-height: 42px;
}
#navigation .center-header-ctn .logo .text.small {
  position: absolute;
  left: 0;
  width: 100%;
  font-size: 10px;
}
#navigation .center-header-ctn .logo .text.small-top {
  top: 5px;
}
#navigation .center-header-ctn .logo .text.small-bottom {
  bottom: 6px;
}
#navigation .center-header-ctn .logo .icon {
  position: absolute;
  top: 9px;
  font-size: 56px;
  color: #f4f4f4;
}
#navigation .center-header-ctn .logo .icon.left {
  left: -15px;
}
#navigation .center-header-ctn .logo .icon.right {
  right: -15px;
}
#navigation .center-header-ctn .logo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0s linear 0s !important;
  -moz-transition: opacity 0s linear 0s !important;
  -ms-transition: opacity 0s linear 0s !important;
  -o-transition: opacity 0s linear 0s !important;
  transition: opacity 0s linear 0s !important;
}
#navigation .center-header-ctn .mixin-reservation {
  float: right;
  position: relative;
  z-index: 1;
  right: 0;
  margin: 20px 0 0 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
}
#navigation .center-header-ctn .mixin-reservation .reservation-icon {
  display: none;
  position: relative;
  top: -1px;
  left: 10px;
  margin: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid #1e282f;
  cursor: pointer;
}
#navigation .center-header-ctn .mixin-reservation .reservation-icon .checkmark {
  position: absolute;
  top: 4px;
  left: 8px;
  width: 4px;
  height: 10px;
  border-bottom: 1px solid #1e282f;
  border-right: 1px solid #1e282f;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#navigation .center-header-ctn .mixin-reservation .reservation-icon .trigger {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 40px;
  height: 40px;
}
#navigation .center-header-ctn .mixin-reservation .reservation-icon:before, #navigation .center-header-ctn .mixin-reservation .reservation-icon:after {
  content: "";
  position: absolute;
  top: -4px;
  width: 2px;
  height: 5px;
  border: 1px solid #fff;
  background: #1e282f;
}
#navigation .center-header-ctn .mixin-reservation .reservation-icon:before {
  left: 3px;
}
#navigation .center-header-ctn .mixin-reservation .reservation-icon:after {
  right: 3px;
}
#navigation .center-header-ctn .mixin-reservation a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
#navigation .center-header-ctn:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100px;
  right: -100px;
  height: 100%;
  background: #fff;
}
#navigation .mixin-burger {
  -webkit-transition: opacity 0.5s ease-out 0.75s;
  -moz-transition: opacity 0.5s ease-out 0.75s;
  -ms-transition: opacity 0.5s ease-out 0.75s;
  -o-transition: opacity 0.5s ease-out 0.75s;
  transition: opacity 0.5s ease-out 0.75s;
}
#navigation:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #f4f4f4;
}

#header.show-navigation-main-aside #navigation nav.navigation-main-aside .close {
  right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#header.show-navigation-main-aside #navigation .mixin-burger {
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
#header.show-navigation-main-aside #navigation .center-header-ctn {
  margin-top: 43px;
}
#header.show-navigation-main-aside #navigation .center-header-ctn .mixin-reservation {
  right: -200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}

#header.show-gmaps #navigation .center-header-ctn {
  margin-top: 43px;
}
#header.show-gmaps #navigation .center-header-ctn .mixin-reservation {
  right: -200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}

html.isMobile #navigation .center-header-ctn .mixin-reservation a {
  display: block;
}

body.hb #navigation .center-header-ctn .logo .text.large {
  font-size: 24px;
}

@media screen and (max-width: 1250px) {
  #navigation nav.navigation-quick {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  #navigation nav.navigation-main-aside {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  #navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) {
    content: "ignore-sibling-height";
    width: 100%;
    height: auto !important;
  }
  #navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) > div > ul > li {
    margin-bottom: 20px;
    width: 100%;
  }
  #navigation nav.navigation-main-aside > div > div > div > div:nth-child(1) > div > ul > li:after {
    content: none;
  }
  #navigation nav.navigation-main-aside > div > div > div > div:nth-child(2) {
    content: "ignore-sibling-height";
    width: 100%;
    height: auto !important;
  }
}
@media screen and (max-width: 600px) {
  #navigation {
    height: 70px;
  }
  #navigation .center-header-ctn {
    text-align: center;
  }
  #navigation .center-header-ctn .logo {
    margin: 0;
    height: 70px;
  }
  #navigation .center-header-ctn .logo .text.small.top {
    top: 4px;
  }
  #navigation .center-header-ctn .logo .text.small.bottom {
    bottom: 5px;
  }
  #navigation .center-header-ctn .logo .text.large {
    top: 13px;
    height: 70px;
    font-size: 22px;
  }
  #navigation .center-header-ctn .logo .icon {
    font-size: 50px;
  }
  #navigation .center-header-ctn .mixin-reservation {
    position: absolute;
    top: -2px;
    right: 0 !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
    -ms-filter: alpha(opacity=100) !important;
  }
  #navigation .center-header-ctn .mixin-reservation .btn {
    display: none;
  }
  #navigation .center-header-ctn .mixin-reservation .reservation-icon {
    display: inline-block;
  }
  #navigation .mixin-burger {
    top: 18px;
    left: 20px;
  }
}
@media screen and (max-width: 500px) {
  #navigation .mixin-burger {
    left: 10px;
  }
}
@media screen and (max-width: 350px) {
  body.hb #navigation .center-header-ctn .logo .text.large {
    font-size: 18px;
  }
}
#overlay .wrapper .close {
  border-color: #6b7074;
}
#overlay .wrapper .close:before, #overlay .wrapper .close:after {
  background: #6b7074;
}
#overlay .wrapper .content .chunk-overlay h5 {
  margin-bottom: 20px;
  padding-bottom: 10px;
  color: #6b7074;
  font-size: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid #acaaaf;
}
#overlay .wrapper .content .chunk-overlay h5 em {
  margin-bottom: 10px;
  font-weight: 500;
}
#overlay .wrapper .content .chunk-overlay p {
  margin-bottom: 10px;
}
#overlay .wrapper .content .chunk-overlay p strong {
  margin-bottom: 10px;
  color: #6b7074;
  font-weight: 400;
}
#overlay .wrapper .content .chunk-overlay p:last-of-type {
  margin-bottom: 20px;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper {
  margin: 10px 0;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper .clm {
  padding: 0 5px;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper .clm label {
  color: #acaaaf;
  font-size: 14px;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper .clm input {
  width: 100%;
  padding: 5px 10px;
  border-color: #acaaaf;
  -webkit-transition: border-color 0.5s ease-out 0s;
  -moz-transition: border-color 0.5s ease-out 0s;
  -ms-transition: border-color 0.5s ease-out 0s;
  -o-transition: border-color 0.5s ease-out 0s;
  transition: border-color 0.5s ease-out 0s;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper .clm input.error-flag {
  border-color: #fa7921;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper .clm .error-message {
  color: #fa7921;
  font-size: 14px;
  font-weight: 400;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper .clm:first-child {
  padding-left: 0;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper .clm:last-child {
  padding-right: 0;
}
#overlay .wrapper .content .chunk-overlay form .clm-wrapper:last-child {
  margin-bottom: 0;
}
#overlay .wrapper .content .chunk-overlay form.error-flag .clm-wrapper .clm .error-message {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#overlay .wrapper .content .chunk-overlay .btn {
  float: right;
  color: #6b7074;
}
#overlay .wrapper .content .chunk-overlay.reservation iframe {
  width: 380px;
  height: 640px;
}
#overlay .wrapper .content .chunk-overlay.newsletter {
  max-width: 400px;
}

#posts {
  padding: 100px 0 30px 0;
}
#posts.image-before {
  padding-top: 200px;
}

@media screen and (max-width: 1000px) {
  #posts.image-before {
    padding-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  #posts {
    padding: 50px 0;
  }
}
#slider {
  overflow: hidden;
  background: #1e282f;
}
#slider.royalSlider {
  width: 100%;
}
#slider.royalSlider .rsOverflow {
  height: 100% !important;
}
#slider.royalSlider .rsOverflow .rsContent .ctn-wrapper {
  position: absolute;
  bottom: 15%;
  left: 0;
  z-index: 1;
  width: 100%;
}
#slider.royalSlider .rsOverflow .rsContent .ctn-wrapper .h1-style {
  padding: 0 40px;
}
#slider.royalSlider .rsOverflow .rsContent .ctn-wrapper .btn-wrapper {
  margin-top: 40px;
  text-align: center;
}
#slider.royalSlider .rsOverflow .rsContent .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
#slider.royalSlider .rsOverflow .rsContent .image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 50%);
  background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 50%);
  background: -ms-linear-gradient(bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 50%);
  background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 50%);
  background: linear-gradient(bottom, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 50%);
}
#slider.royalSlider .rsOverflow .rsContent video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#slider.royalSlider .rsOverflow .rsArrow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100px;
}
#slider.royalSlider .rsOverflow .rsArrow .rsArrowIcn {
  position: absolute;
  top: 50%;
  width: 100px;
  height: 74px;
  background: #1e282f;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#slider.royalSlider .rsOverflow .rsArrow .rsArrowIcn:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
}
#slider.royalSlider .rsOverflow .rsArrow.rsArrowLeft {
  left: 0;
}
#slider.royalSlider .rsOverflow .rsArrow.rsArrowLeft .rsArrowIcn {
  left: -100px;
}
#slider.royalSlider .rsOverflow .rsArrow.rsArrowLeft .rsArrowIcn:after {
  left: 50%;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 20px 0;
  -moz-transform-origin: 20px 0;
  -ms-transform-origin: 20px 0;
  -o-transform-origin: 20px 0;
  transform-origin: 20px 0;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
#slider.royalSlider .rsOverflow .rsArrow.rsArrowRight {
  right: 0;
}
#slider.royalSlider .rsOverflow .rsArrow.rsArrowRight .rsArrowIcn {
  right: -100px;
}
#slider.royalSlider .rsOverflow .rsArrow.rsArrowRight .rsArrowIcn:after {
  right: 50%;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
#slider.royalSlider .rsOverflow .rsArrow:hover.rsArrowLeft .rsArrowIcn {
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#slider.royalSlider .rsOverflow .rsArrow:hover.rsArrowRight .rsArrowIcn {
  right: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#slider.royalSlider .rsNav {
  position: absolute;
  bottom: 30px;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
#slider.royalSlider .rsNav .rsNavItem {
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 0 10px;
  background: #fff;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#slider.royalSlider .rsNav .rsNavItem.rsNavSelected {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

#main.show-gmaps #slider,
#main.show-navigation-main-aside #slider {
  content: "ignore-viewport-height";
}

html.isTouch #slider.royalSlider .rsOverflow .rsArrow {
  display: none !important;
}

@media screen and (max-width: 1000px) {
  #slider.royalSlider .rsOverflow .rsArrow {
    display: none !important;
  }
}
@media screen and (max-width: 500px) {
  #slider.royalSlider .rsOverflow .rsContent .ctn-wrapper .h1-style {
    padding: 0 20px;
  }
}
@media screen and (max-width: 400px) {
  #slider.royalSlider .rsNav {
    bottom: 15px;
  }
}
#splasher {
  height: 500px;
  background: #1e282f;
}
#splasher .css-table {
  position: relative;
  z-index: 1;
}
#splasher .css-table h1 {
  text-align: center;
}
#splasher .background {
  position: absolute;
  top: 0;
  left: 0;
  right: -80px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

@media screen and (max-width: 1500px) {
  #splasher {
    height: 400px;
  }
}
@media screen and (max-width: 1200px) {
  #splasher {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  #splasher .background {
    right: -100px;
  }
}
@media screen and (max-width: 400px) {
  #splasher {
    height: 250px;
  }
  #splasher .background {
    right: -120px;
  }
}
@media screen and (max-width: 350px) {
  #splasher {
    height: 200px;
  }
}