/* == COMMON ====================================================== */

@mixin border-radius($top-left, $top-right, $buttom-left, $buttom-right) {
    -webkit-border-radius: $top-left $top-right $buttom-left $buttom-right;
    -moz-border-radius: $top-left $top-right $buttom-left $buttom-right;
    -ms-border-radius: $top-left $top-right $buttom-left $buttom-right;
    -o-border-radius: $top-left $top-right $buttom-left $buttom-right;
    border-radius: $top-left $top-right $buttom-left $buttom-right;
}

@mixin opacity($val) {
    opacity: $val;
    filter: alpha(opacity = $val * 100);
    -ms-filter: alpha(opacity = $val * 100);
}

@mixin opacity-i($val) {
    opacity: $val !important;
    filter: alpha(opacity = $val * 100) !important;
    -ms-filter: alpha(opacity = $val * 100) !important;
}

@mixin blur($val) {
    -webkit-filter: blur($val);
    -moz-filter: blur($val);
    -ms-filter: blur($val);
    -o-filter: blur($val);
    filter: blur($val);
}

@mixin background-clip($val) {
    -webkit-background-clip: $val;
    -moz-background-clip: $val;
    -ms-background-clip: $val;
    -o-background-clip: $val;
    background-clip: $val;
}

@mixin smooth-scroll() {
    -webkit-overflow-scrolling: touch;
}

/* == FLEX ====================================================== */

@mixin flex ($val) {
    -webkit-flex: $val;
    -moz-flex: $val;
    -ms-flex: $val;
    -o-flex: $val;
    flex: $val;
}

@mixin flex-wrap($val) {
    -webkit-flex-wrap: $val;
    -moz-flex-wrap: $val;
    -ms-flex-wrap: $val;
    -o-flex-wrap: $val;
    flex-wrap: $val;
}

@mixin flex-box() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

/* == TEXT & FONTS ====================================================== */

@mixin text-shadow-1($h1, $v1, $b1, $c1) {
    -webkit-text-shadow: $h1 $v1 $b1 $c1;
    -moz-text-shadow: $h1 $v1 $b1 $c1;
    -ms-text-shadow: $h1 $v1 $b1 $c1;
    -o-text-shadow: $h1 $v1 $b1 $c1;
    text-shadow: $h1 $v1 $b1 $c1;
}

@mixin text-shadow-2($h1, $v1, $b1, $c1, $h2, $v2, $b2, $c2) {
    -webkit-text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2;
    -moz-text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2;
    -ms-text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2;
    -o-text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2;
    text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2;
}

@mixin text-shadow-3($h1, $v1, $b1, $c1, $h2, $v2, $b2, $c2, $h3, $v3, $b3, $c3) {
    -webkit-text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2, $h3 $v3 $b3 $c3;
    -moz-text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2, $h3 $v3 $b3 $c3;
    -ms-text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2, $h3 $v3 $b3 $c3;
    -o-text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2, $h3 $v3 $b3 $c3;
    text-shadow: $h1 $v1 $b1 $c1, $h2 $v2 $b2 $c2, $h3 $v3 $b3 $c3;
}

@mixin text-rotate($deg) {
    -webkit-transform: rotate($deg + deg);
    -moz-transform: rotate($deg + deg);
    -ms-transform: rotate($deg + deg);
    -o-transform: rotate($deg + deg);
    transform: rotate($deg + deg);

    -ms-filter: progid:DXImageTransform.Microsoft.BasicImage(rotation = $deg / 3);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation = $deg / 3);
}

@mixin text-stroke($width, $color) {
    -webkit-text-stroke: $width $color;
    -moz-text-stroke: $width $color;
    -ms-text-stroke: $width $color;
    -o-text-stroke: $width $color;
    text-stroke: $width $color;
}

@mixin text-fill-color($color) {
    -webkit-text-fill-color: $color;
    -moz-text-fill-color: $color;
    -ms-text-fill-color: $color;
    -o-text-fill-color: $color;
    text-fill-color: $color;
}

@mixin font-family($name) {
    font-family: $name, sans-serif;
}

@mixin hyphens($val) {
    -webkit-hyphens: $val;
    -moz-hyphens: $val;
    -ms-hyphens: $val;
    -o-hyphens: $val;
    hyphens: $val;
}

@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }

    &::-moz-placeholder {
        color: $color;
    }

    &:-ms-input-placeholder {
        color: $color;
    }

    &:-moz-placeholder {
        color: $color;
    }
}

@mixin user-select($val) {
    -webkit-user-select: $val;
    -moz-user-select: $val;
    -ms-user-select: $val;
    -o-user-select: $val;
    user-select: $val;
}

/* == BOX-SHADOW ====================================================== */

@mixin box-shadow($horizontal, $vertical, $blur, $spread, $color) {
    -webkit-box-shadow: $horizontal $vertical $blur $spread $color;
    -moz-box-shadow: $horizontal $vertical $blur $spread $color;
    -ms-box-shadow: $horizontal $vertical $blur $spread $color;
    -o-box-shadow: $horizontal $vertical $blur $spread $color;
    box-shadow: $horizontal $vertical $blur $spread $color;
}

@mixin box-shadow-inset($horizontal, $vertical, $blur, $spread, $color) {
    -webkit-box-shadow: inset $horizontal $vertical $blur $spread $color;
    -moz-box-shadow: inset $horizontal $vertical $blur $spread $color;
    -ms-box-shadow: inset $horizontal $vertical $blur $spread $color;
    -o-box-shadow: inset $horizontal $vertical $blur $spread $color;
    box-shadow: inset $horizontal $vertical $blur $spread $color;
}

@mixin box-shadow-none() {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}

@mixin box-shadow-none-i() {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
}

/* == GRADIENT ====================================================== */

@mixin gradient-streight($start, $c1, $p1, $c2, $p2) {
    background: -webkit-linear-gradient($start, $c1 $p1, $c2 $p2);
    background: -moz-linear-gradient($start, $c1 $p1, $c2 $p2);
    background: -ms-linear-gradient($start, $c1 $p1, $c2 $p2);
    background: -o-linear-gradient($start, $c1 $p1, $c2 $p2);
    background: linear-gradient($start, $c1 $p1, $c2 $p2);
}

@mixin gradient-streight-3($start, $c1, $p1, $c2, $p2, $c3, $p3) {
    background: -webkit-linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3);
    background: -moz-linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3);
    background: -ms-linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3);
    background: -o-linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3);
    background: linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3);
}

@mixin gradient-streight-4($start, $c1, $p1, $c2, $p2, $c3, $p3, $c4, $p4) {
    background: -webkit-linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3, $c4 $p4);
    background: -moz-linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3, $c4 $p4);
    background: -ms-linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3, $c4 $p4);
    background: -o-linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3, $c4 $p4);
    background: linear-gradient($start, $c1 $p1, $c2 $p2, $c3 $p3, $c4 $p4);
}

@mixin gradient-circle($c1, $p1, $c2, $p2) {
    background: -webkit-radial-gradient(circle, $c1 $p1, $c2 $p2);
    background: -moz-radial-gradient(circle, $c1 $p1, $c2 $p2);
    background: -ms-radial-gradient(circle, $c1 $p1, $c2 $p2);
    background: -o-radial-gradient(circle, $c1 $p1, $c2 $p2);
    background: radial-gradient(circle, $c1 $p1, $c2 $p2);
}

/* == ROTATE ====================================================== */

@mixin rotate($r) {
    -webkit-transform: rotate($r);
    -moz-transform: rotate($r);
    -ms-transform: rotate($r);
    -o-transform: rotate($r);
    transform: rotate($r);
}

@mixin rotate-x($p, $r, $ox: 0, $oy: 0) {
    @include transform-style(preserve-3d);
    @include transform-origin($ox, $oy);

    -webkit-transform: perspective($p) rotateX($r);
    -moz-transform: perspective($p) rotateX($r);
    -ms-transform: perspective($p) rotateX($r);
    -o-transform: perspective($p) rotateX($r);
    transform: perspective($p) rotateX($r);
}

@mixin rotate-y($p, $r, $ox: 0, $oy: 0) {
    @include transform-style(preserve-3d);
    @include transform-origin($ox, $oy);

    -webkit-transform: rotateY($r);
    -moz-transform: rotateY($r);
    -ms-transform: rotateY($r);
    -o-transform: rotateY($r);
    transform: rotateY($r);
}

@mixin rotate-z($p, $r, $ox: 0, $oy: 0) {
    @include transform-style(preserve-3d);
    @include transform-origin($ox, $oy);

    -webkit-transform: perspective($p) rotateZ($r);
    -moz-transform: rotateZ($r);
    -ms-transform: perspective($p) rotateZ($r);
    -o-transform: perspective($p) rotateZ($r);
    transform: perspective($p) rotateZ($r);
}

@mixin perspective($p) {
    -webkit-perspective: $p;
    -moz-perspective: $p;
    -ms-perspective: $p;
    -o-perspective: $p;
    perspective: $p;
}

@mixin backface-visibility($style) {
    -webkit-backface-visibility: $style;
    -moz-backface-visibility: $style;
    -ms-backface-visibility: $style;
    -o-backface-visibility: $style;
    backface-visibility: $style;
}

/* == SCALE ====================================================== */

@mixin scale($f) {
    -webkit-transform: scale($f);
    -moz-transform: scale($f);
    -ms-transform: scale($f);
    -o-transform: scale($f);
    transform: scale($f);
}

@mixin scale-x($f) {
    -webkit-transform: scaleX($f);
    -moz-transform: scaleX($f);
    -ms-transform: scaleX($f);
    -o-transform: scaleX($f);
    transform: scaleX($f);
}

@mixin scale-y($f) {
    -webkit-transform: scaleY($f);
    -moz-transform: scaleY($f);
    -ms-transform: scaleY($f);
    -o-transform: scaleY($f);
    transform: scaleY($f);
}

@mixin transform-origin($x, $y) {
    -webkit-transform-origin: $x $y;
    -moz-transform-origin: $x $y;
    -ms-transform-origin: $x $y;
    -o-transform-origin: $x $y;
    transform-origin: $x $y;
}

/* == CALC ====================================================== */

@mixin calc-width($operation) {
    -webkit-width: calc($operation);
    -moz-width: calc($operation);
    -ms-width: calc($operation);
    -o-width: calc($operation);
    width: calc($operation);
}

@mixin calc-height($operation) {
    -webkit-height: calc($operation);
    -moz-height: calc($operation);
    -ms-height: calc($operation);
    -o-height: calc($operation);
    height: calc($operation);
}

/* == CLIP-PATH ====================================================== */

@mixin clip-path-3($x1, $y1, $x2, $y2, $x3, $y3) {
    -webkit-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3);
    -moz-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3);
    -ms-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3);
    -o-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3);
    clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3);
}

@mixin clip-path-4($x1, $y1, $x2, $y2, $x3, $y3, $x4, $y4) {
    -webkit-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4);
    -moz-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4);
    -ms-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4);
    -o-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4);
    clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4);
}

@mixin clip-path-10($x1, $y1, $x2, $y2, $x3, $y3, $x4, $y4, $x5, $y5, $x6, $y6, $x7, $y7, $x8, $y8, $x9, $y9, $x10, $y10) {
    -webkit-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4, $x5 $y5, $x6 $y6, $x7 $y7, $x8 $y8, $x9 $y9, $x10 $y10);
    -moz-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4, $x5 $y5, $x6 $y6, $x7 $y7, $x8 $y8, $x9 $y9, $x10 $y10);
    -ms-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4, $x5 $y5, $x6 $y6, $x7 $y7, $x8 $y8, $x9 $y9, $x10 $y10);
    -o-clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4, $x5 $y5, $x6 $y6, $x7 $y7, $x8 $y8, $x9 $y9, $x10 $y10);
    clip-path: polygon($x1 $y1, $x2 $y2, $x3 $y3, $x4 $y4, $x5 $y5, $x6 $y6, $x7 $y7, $x8 $y8, $x9 $y9, $x10 $y10);
}

/* == TRANSFORM ====================================================== */

@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
}

@mixin transform-style($style) {
    -webkit-transform-style: $style;
    -moz-transform-style: $style;
    -ms-transform-style: $style;
    -o-transform-style: $style;
    transform-style: $style;
}

/* == TRANSITION ====================================================== */

/* @include transition(opacity, 0.5s, ease-out, 0s); */
@mixin transition($property, $duration, $easing, $delay) {
    -webkit-transition: $property $duration $easing $delay;
    -moz-transition: $property $duration $easing $delay;
    -ms-transition: $property $duration $easing $delay;
    -o-transition: $property $duration $easing $delay;
    transition: $property $duration $easing $delay;
}

@mixin transition-i($property, $duration, $easing, $delay) {
    -webkit-transition: $property $duration $easing $delay !important;
    -moz-transition: $property $duration $easing $delay !important;
    -ms-transition: $property $duration $easing $delay !important;
    -o-transition: $property $duration $easing $delay !important;
    transition: $property $duration $easing $delay !important;
}

@mixin transition-filter($duration, $easing, $delay) {
    -webkit-transition: -webkit-filter $duration $easing $delay;
    -moz-transition: -moz-filter $duration $easing $delay;
    -ms-transition: -ms-filter $duration $easing $delay;
    -o-transition: -o-filter $duration $easing $delay;
    //transition: filter $duration $easing $delay;
}

@mixin transition-multiple-2($transition1, $transition2) {
    -webkit-transition: $transition1, $transition2;
    -moz-transition: $transition1, $transition2;
    -ms-transition: $transition1, $transition2;
    -o-transition: $transition1, $transition2;
}

@mixin transition-multiple-3($transition1, $transition2, $transition3) {
    -webkit-transition: $transition1, $transition2, $transition3;
    -moz-transition: $transition1, $transition2, $transition3;
    -ms-transition: $transition1, $transition2, $transition3;
    -o-transition: $transition1, $transition2, $transition3;
}

@mixin transition-transform($duration, $easing, $delay) {
    -webkit-transition: -webkit-transform $duration $easing $delay;
    -moz-transition: -moz-transform $duration $easing $delay;
    -ms-transition: -ms-transform $duration $easing $delay;
    -o-transition: -o-transform $duration $easing $delay;
    transition: transform $duration $easing $delay;
}

@mixin transition-properties($properties) {
    -webkit-transition-property: $properties;
    -moz-transition-property: $properties;
    -ms-transition-property: $properties;
    -o-transition-property: $properties;
    transition-property: $properties;
}

/* == ANIMATION ====================================================== */

@mixin animation($property, $duration, $easing, $iteration, $delay) {
    -webkit-animation: $property $duration $easing $iteration $delay forwards;
    -moz-animation: $property $duration $easing $iteration $delay forwards;
    -ms-animation: $property $duration $easing $iteration $delay forwards;
    -o-animation: $property $duration $easing $iteration $delay forwards;
    animation: $property $duration $easing $iteration $delay forwards;
}

/* == CURSOR ====================================================== */

@mixin cursor-grab() {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -ms-grab;
    cursor: -o-grab;
    cursor: grab;
}

/* == IMG ====================================================== */

@mixin grayscale() {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

@mixin grayscale-no() {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: grayscale(0%);
}

/* == BG-IMG ====================================================== */

@mixin bg-img($url, $width, $height, $left, $top) {
    background-image: url($url);
    background-size: $width $height;
    background-repeat: no-repeat;
    background-position: $left $top;
}

@mixin bg-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@mixin bg-pattern-chess($color, $size) {
    background-position: 0 0, 0 $size, $size ($size * -1), ($size * -1) 0;
    background-size: $size * 2 $size * 2;

    background-image: -webkit-linear-gradient(45deg, $color 25%, $transparent 25%),
                      -webkit-linear-gradient(-45deg, $color 25%, $transparent 25%),
                      -webkit-linear-gradient(45deg, $transparent 75%, $color 75%),
                      -webkit-linear-gradient(-45deg, $transparent 75%, $color 75%);

    background-image: -moz-linear-gradient(45deg, $color 25%, $transparent 25%),
                      -moz-linear-gradient(-45deg, $color 25%, $transparent 25%),
                      -moz-linear-gradient(45deg, $transparent 75%, $color 75%),
                      -moz-linear-gradient(-45deg, $transparent 75%, $color 75%);

    background-image: -ms-linear-gradient(45deg, $color 25%, $transparent 25%),
                      -ms-linear-gradient(-45deg, $color 25%, $transparent 25%),
                      -ms-linear-gradient(45deg, $transparent 75%, $color 75%),
                      -ms-linear-gradient(-45deg, $transparent 75%, $color 75%);

    background-image: -o-linear-gradient(45deg, $color 25%, $transparent 25%),
                      -o-linear-gradient(-45deg, $color 25%, $transparent 25%),
                      -o-linear-gradient(45deg, $transparent 75%, $color 75%),
                      -o-linear-gradient(-45deg, $transparent 75%, $color 75%);

    background-image: linear-gradient(45deg, $color 25%, $transparent 25%),
                      linear-gradient(-45deg, $color 25%, $transparent 25%),
                      linear-gradient(45deg, $transparent 75%, $color 75%),
                      linear-gradient(-45deg, $transparent 75%, $color 75%);
}

/* == COLUMN ====================================================== */

@mixin column-count($c) {
    -webkit-column-count: $c;
    -moz-column-count: $c;
    -ms-column-count: $c;
    -o-column-count: $c;
    column-count: $c;
}

@mixin column-gap($g) {
    -webkit-column-gap: $g;
    -moz-column-gap: $g;
    -ms-column-gap: $g;
    -o-column-gap: $g;
    column-gap: $g;
}
