#page-maintenance {
    height: 100%;
    text-align: center;

    h1 {
        font-size: $font-size-30;
        line-height: 1.3em;
    }

    p {
        margin-top: 10px;
        font-size: $font-size-20;
    }

    form {
        margin: 0 auto;
        padding: 0 20px;
        max-width: 400px;
        text-align: left;

        label {

            &.mandatory {

                &:after {
                    content: '*';
                    margin-left: 5px;
                }
            }
        }

        input {
            @include border-radius($border-radius-1, $border-radius-1, $border-radius-1, $border-radius-1);

            &[type=submit] {
                padding: $form-padding;
                color: $color-white;
                background: $color-grey-dark;
                text-transform: uppercase;
            }
        }

        p {
            font-size: $font-size-16;
        }
    }
}
