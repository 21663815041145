#overlay {
    position: fixed !important;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    cursor: initial !important;
    overflow: hidden;
    @include transition(all, 0.3s, ease-out, 0s);

    > .outer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        > .wrapper {
            position: relative;
            max-width: 100%;
            max-height: 100%;
            padding: 20px;
            text-align: left;
            background: $color-white;
            overflow-x: hidden;
            overflow-y: auto;
            @include border-radius($border-radius-1, $border-radius-1, $border-radius-1, $border-radius-1);
            @include smooth-scroll();

            > .close {
                float: right;
                position: relative;
                z-index: 3;
                width: 18px;
                height: 18px;
                border: 1px solid $color-grey-dim;
                cursor: pointer;
                @include border-radius($border-radius-1, $border-radius-1, $border-radius-1, $border-radius-1);

                .trigger {
                    position: absolute;
                    top: -10px;
                    bottom: -10px;
                    left: -10px;
                    right: -10px;
                }

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 11px;
                    background: $color-grey-dim;
                }

                &:before {
                    top: 4px;
                    right: 3px;
                    @include rotate-z(0, 45deg, 0, 0);
                }

                &:after {
                    top: 5px;
                    left: 4px;
                    @include rotate-z(0, -45deg, 0, 0);
                }
            }

            > .content {
                clear: right;
                position: relative;
                z-index: 1;
                padding-top: 10px;
                overflow-x: hidden;

                .chunk-overlay {
                    max-width: 100%;
                }
            }

            > .loading {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                pointer-events: none;
                background: $color-white;
                @include opacity(0);
                @include transition(opacity, 0.3s, ease-out, 0s);

                &.show {
                    pointer-events: auto;
                    @include opacity(1);
                }

                &:after {
                    content: 'Loading...';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%, -50%));
                }
            }
        }
    }

    &.loading {

        > .outer {

            > .wrapper {
                overflow-y: hidden;
            }
        }
    }

    &.locked {

        > .outer {

            > .wrapper {

                > .close {
                    display: none;
                }
            }
        }
    }
}

#overlay_wrapper {
    overflow: hidden !important;
}

@media screen and (max-width: 400px) {

    #overlay {
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }
}
