/* == TAGS ====================================================== */

body {
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
    background: $color-white;
    @include transition(opacity, 0.5s, ease-out, 0s);

    &.scrolling {

        iframe {
            pointer-events: none;
        }
    }

    &.resizing {

        * {
            @include transition-i(all, 0s, linear, 0s);
        }
    }
}

img,
[data-lazy] {
    @include opacity(0);
    @include transition(opacity, 1s, ease-out, 0s);

    &.img-loaded {
        @include opacity(1);
    }

    &.img-lazy-waiting {
        @include opacity(0);
    }

    &.img-lazy-loaded {
        @include opacity(1);
    }
}

/* == COMMON ====================================================== */

.box-square {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        // padding-top: 100%;
        aspect-ratio: 1;
        height: 100%;
    }

    .box-square-ctn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.box-shadow {
    @include box-shadow(0, 0, 10px, 0, rgba(0, 0, 0, 0.2));

    &.box-shadow-huge {
        @include box-shadow(0, 0, 10px, 0, rgba(0, 0, 0, 1));
    }

    &.box-shadow-small {
        @include box-shadow(0, 0, 5px, 0, rgba(0, 0, 0, 0.1));
    }
}

.css-table {
    display: table;
    width: 100%;
    height: 100%;

    .css-cell {
        display: table-cell;
        vertical-align: middle;
    }
}

.border-radius {
    @include border-radius($border-radius-1, $border-radius-1, $border-radius-1, $border-radius-1);
}

.border-radius-2 {
    @include border-radius($border-radius-2, $border-radius-2, $border-radius-2, $border-radius-2);
}

.text-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    @include opacity(0.5);
}

.line-break {

    &:after {
        content: '\a';
        white-space: pre;
    }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    border: none;
    margin: -1px;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.crop-at-show-more ,
.crop-at-show-less {
    display: inline-block;
    padding-left: 5px;
    cursor: pointer;
}

.ctn-capitalize {
    position: relative;

    .ctn-cap {
        position: relative;
        color: inherit;
        font: inherit;
    }
}

.clm-wrapper {
    font-size: 0;
    @include flex-box();
    @include flex-wrap(wrap);

    > .clm {
        display: inline-block;

        &.clm-1 {
            width: 100%;
        }

        &.clm-2 {
            width: 50%;
        }

        &.clm-3 {
            width: 33.333333%;
        }

        &.clm-4 {
            width: 25%;
        }

        &.clm-5 {
            width: 20%;
        }

        &.clm-6 {
            width: 16.666666%;
        }

        &.clm-7 {
            width: 14.285%;
        }

        &.clm-8 {
            width: 12.5%;
        }

        &.clm-9 {
            width: 11.111111%;
        }

        &.clm-20p {
            width: 20%;
        }

        &.clm-25p {
            width: 25%;
        }

        &.clm-30p {
            width: 30%;
        }

        &.clm-33p {
            width: 33.333333%;
        }

        &.clm-35p {
            width: 35%;
        }

        &.clm-40p {
            width: 40%;
        }

        &.clm-45p {
            width: 45%;
        }

        &.clm-55p {
            width: 55%;
        }

        &.clm-50p {
            width: 50%;
        }

        &.clm-60p {
            width: 60%;
        }

        &.clm-65p {
            width: 65%;
        }

        &.clm-66p {
            width: 66.666666%;
        }

        &.clm-70p {
            width: 70%;
        }

        &.clm-75p {
            width: 75%;
        }

        &.clm-80p {
            width: 80%;
        }

        &.clm-83p {
            width: 83.334%;
        }
    }

    &.nowrap {
        @include flex-wrap(nowrap);
    }
}

.uniform {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 25px;
    height: 25px;

    input {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        @include opacity(0);
    }

    &.uniform-radio {

        &:before {
            bottom: 3px;
            @include border-radius(50%, 50%, 50%, 50%);
        }

        &:after {
            bottom: 8px;
            width: 8px;
            height: 8px;
            background: $color-grey-black;
            @include opacity(0.5);
            @include border-radius(50%, 50%, 50%, 50%);
        }
    }

    &.uniform-checkbox {

        &:before {
            bottom: 3px;
            @include border-radius($border-radius-1, $border-radius-1, $border-radius-1, $border-radius-1);
        }

        &:after {
            content: '✓';
            height: 1em;
            bottom: 8px;
            @include opacity(0.8);
        }
    }

    &.uniform-checked {

        &:after {
            display: block;
        }
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        @include transform(translate(-50%, 0));
    }

    &:before {
        width: 16px;
        height: 16px;
        border: 1px solid $color-grey-black;
        @include opacity(0.3);
    }

    &:after {
        display: none;
    }
}

html.isFirefox {

    .uniform {

        &.uniform-checkbox {

            &:before {
                bottom: 3px;
            }
        }
    }
}

[data-set-parent-height] {
    @include opacity(0);
    @include transition(opacity, $duration-03s, ease-out, 0s);

    &[style] {
        @include opacity(1);
    }
}

[data-trigger-href] {
    cursor: pointer;
}

[data-clone] {
    cursor: pointer;
}

/* == OLD BROWSER ====================================================== */

#old-browser {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: $color-grey-black;

    h1 {
        padding: 0 20px;
        color: $color-white;
        font-size: 30px;
        line-height: 1.3em;
        background: none;
    }

    p {
        margin-top: 20px;
        padding: 0 20px;
        color: $color-white;
        font-size: $font-size-20;
        font-weight: 100;
        line-height: 1.6em;

        @include opacity(0.5);

        span,
        a {
            margin: 0;
            padding: 0;
            color: inherit;
            font: inherit;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
