@font-face {
    font-family: 'icomoon';
    src: url('public/icons/icomoon.eot');
    src: url('public/icons/icomoon.eot?#iefix') format('embedded-opentype'),
         url('public/icons/icomoon.ttf') format('truetype'),
         url('public/icons/icomoon.woff') format('woff'),
         url('public/icons/icomoon.svg?#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.icon-spike:before {
    content: '\e903';
}

.icon-facebook:before {
    content: '\e902';
}

.icon-location:before {
    content: '\e900';
}

.icon-bug:before {
    content: '\e901';
}
