#navigation {
    position: relative;
    z-index: 20;
    height: 74px;
    background: $color-white;

    nav {

        ul {

            li {

                a {
                    display: block;
                    position: relative;
                    padding: 3px 10px;

                    &:hover {
                        color: $color-white !important;
                        background: $color-grey-black;
                    }
                }

                &.current-menu-item {

                    > a {
                        color: $color-white !important;
                        background: $color-grey-black;
                    }
                }
            }
        }

        &.navigation-main-aside {
            position: relative;
            height: 0;
            background: $color-grey-black;
            overflow: hidden;
            @include transition(height, 0.5s, $ease-in-out-cubic, 0s);

            > div > div > div {
                font-size: 0;
                padding: 60px 0 40px 0;
                overflow: hidden;

                > div {
                    display: inline-block;

                    ul {
                        display: block;

                        li {
                            display: block;
                            text-align: center;

                            a {
                                display: inline-block;
                                margin: 0 10px;
                                color: $color-grey-middle;
                                text-transform: uppercase;

                                &:hover {
                                    background: $color-orange;
                                }
                            }

                            &.current-menu-item {

                                > a {
                                    background: $color-orange;
                                }
                            }
                        }
                    }

                    &:nth-child(1) {
                        width: 80%;
                        height: 100%;

                        > div {
                            display: table;
                            width: 100%;
                            height: 100%;

                            > ul {
                                display: table-cell;
                                width: 100%;
                                height: 100%;
                                vertical-align: middle;

                                > li {
                                    display: inline-block;
                                    position: relative;
                                    width: 25%;

                                    > a {
                                        border-bottom: 1px solid $color-grey-dark;
                                    }

                                    ul {

                                        li {

                                            a {
                                                color: $color-grey-dark;
                                            }
                                        }
                                    }

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        top: -1000px;
                                        right: 0;
                                        width: 1px;
                                        height: 2000px;
                                        background: $color-grey-dark;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(2) {
                        width: 20%;
                    }
                }
            }

            .close {
                position: absolute;
                top: 0;
                right: -200px;
                @include opacity(0);
                @include transition(all, 0.3s, $ease-in-out-cubic, 0.75s);
            }
        }

        &.navigation-quick {
            position: relative;
            z-index: 1;
            display: inline-block;
            margin: 22px 0 0 30px;

            ul {

                li {
                    display: inline-block;

                    a {
                        margin-right: 1px;
                        font-size: $font-size-18;
                        font-weight: 200;
                    }
                }
            }
        }
    }

    .center-header-ctn {
        position: relative;
        height: 100%;
        font-size: 0;
        @include transition(margin-top, 0.5s, ease-out, 0s);

        .logo {
            position: relative;
            z-index: 1;
            height: 74px;
            margin-left: 50px;
            padding: 0 30px;
            text-align: center;
            overflow: hidden;
            background: $color-grey-black;

            .text {
                color: $color-grey-white;
                @include font-family('Clarendon BT');

                &.large {
                    position: relative;
                    top: 14px;
                    font-size: $font-size-28;
                    line-height: 42px;
                }

                &.small {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    font-size: $font-size-10;
                }

                &.small-top {
                    top: 5px;
                }

                &.small-bottom {
                    bottom: 6px;
                }
            }

            .icon {
                position: absolute;
                top: 9px;
                font-size: 56px;
                color: $color-grey-white;

                &.left {
                    left: -15px;
                }

                &.right {
                    right: -15px;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include opacity(0);
                @include transition-i(opacity, 0s, linear, 0s);
            }
        }

        .mixin-reservation {
            float: right;
            position: relative;
            z-index: 1;
            right: 0;
            margin: 20px 0 0 0;
            @include transition(all, 0.3s, $ease-in-out-cubic, 1s);

            .reservation-icon {
                display: none;
                position: relative;
                top: -1px;
                left: 10px;
                margin: 10px;
                width: 20px;
                height: 20px;
                border: 1px solid $color-grey-black;
                cursor: pointer;

                .checkmark {
                    position: absolute;
                    top: 4px;
                    left: 8px;
                    width: 4px;
                    height: 10px;
                    border-bottom: 1px solid $color-grey-black;
                    border-right: 1px solid $color-grey-black;
                    @include rotate-z(0, 45deg, 50%, 50%);
                }

                .trigger {
                    position: absolute;
                    top: -10px;
                    left: -10px;
                    width: 40px;
                    height: 40px;
                }

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: -4px;
                    width: 2px;
                    height: 5px;
                    border: 1px solid $color-white;
                    background: $color-grey-black;
                }

                &:before {
                    left: 3px;
                }

                &:after {
                    right: 3px;
                }
            }

            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -100px;
            right: -100px;
            height: 100%;
            background: $color-white;
        }
    }

    .mixin-burger {
        @include transition(opacity, 0.5s, ease-out, 0.75s);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $color-grey-white;
    }
}

#header.show-navigation-main-aside {

    #navigation {

        nav {

            &.navigation-main-aside {

                .close {
                    right: 0;
                    @include opacity(1);
                }
            }
        }

        .mixin-burger {
            pointer-events: none;
            @include opacity(0);
            @include transition(opacity, 0.5s, ease-out, 0s);
        }

        .center-header-ctn {
            margin-top: 43px;

            .mixin-reservation {
                right: -200px;
                @include opacity(0);
            }
        }
    }
}

#header.show-gmaps {

    #navigation {

        .center-header-ctn {
            margin-top: 43px;

            .mixin-reservation {
                right: -200px;
                @include opacity(0);
            }
        }
    }
}

html.isMobile {

    #navigation .center-header-ctn .mixin-reservation a {
        display: block;
    }
}

body.hb {

    #navigation .center-header-ctn .logo .text.large {
        font-size: $font-size-24;
    }
}

@media screen and (max-width: 1250px) {

    #navigation nav.navigation-quick {
        display: none;
    }
}

@media screen and (max-width: 1200px) {

    #navigation nav.navigation-main-aside {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        > div > div > div > div {

            &:nth-child(1) {
                content: 'ignore-sibling-height';
                width: 100%;
                height: auto !important;

                > div > ul > li {
                    margin-bottom: 20px;
                    width: 100%;

                    &:after {
                        content: none;
                    }
                }
            }

            &:nth-child(2) {
                content: 'ignore-sibling-height';
                width: 100%;
                height: auto !important;
            }
        }
    }
}

@media screen and (max-width: 600px) {

    #navigation {
        height: 70px;

        .center-header-ctn {
            text-align: center;

            .logo {
                margin: 0;
                height: 70px;

                .text {

                    &.small {

                        &.top {
                            top: 4px;
                        }

                        &.bottom {
                            bottom: 5px;
                        }
                    }

                    &.large {
                        top: 13px;
                        height: 70px;
                        font-size: $font-size-22;
                    }
                }

                .icon {
                    font-size: 50px;
                }
            }

            .mixin-reservation {
                position: absolute;
                top: -2px;
                right: 0 !important;
                @include opacity-i(1);

                .btn {
                    display: none;
                }

                .reservation-icon {
                    display: inline-block;
                }
            }
        }

        .mixin-burger {
            top: 18px;
            left: 20px;
        }
    }
}

@media screen and (max-width: 500px) {

    #navigation {

        .mixin-burger {
            left: 10px;
        }
    }
}

@media screen and (max-width: 350px) {

    body.hb {

        #navigation {

            .center-header-ctn .logo {

                .text.large {
                    font-size: $font-size-18;
                }
            }
        }
    }
}
