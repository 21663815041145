/* == TAGS ====================================================== */

* {
    @include font-family('Galano Grotesque');
    color: $color-grey-black;
}

html {
    width: 100%;

    &.isTouch {

        .h1-style {

            span {
                background: none;
                @include background-clip(unset);
                @include text-fill-color(unset);

                &:after {
                    content: none;
                }
            }

            &:after {
                content: none;
            }
        }
    }
}

body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: $color-white;

    &#page-6,
    &#page-3057 {
        width: 100%;
        height: 100%;
        padding-top: 0;
        overflow: hidden;

        #main {
            height: 100%;
        }
    }
}

p {
    color: $color-grey-middle;

    a {
        color: inherit;
        font-weight: 500;
    }
}

/* == PSEUDO CLASS ====================================================== */

::selection {
    color: $color-white;
    background: $color-theme;
}

::-moz-selection {
    color: $color-white;
    background: $color-theme;
}

/* == COMMON ====================================================== */

.h1-style {
    position: relative;
    margin: 0 auto;
    max-width: 1000px;
    color: $color-white;
    font-size: $font-size-70;
    line-height: 1.25em;
    text-align: center;
    @include font-family('Clarendon BT');

    span {
        position: relative;
        z-index: 1;
        color: inherit;
        font: inherit;
        line-height: inherit;
        background-image: url('public/images/assets/layout/global/pattern-white.png');
        background-position: center;
        @include hyphens(manual);
        @include background-clip(text);
        @include text-fill-color($transparent);
    }

    &:after {
        content: attr(data-content);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: $transparent;
        @include text-shadow-3(-1px, -1px, 0, $color-black, 0, 0, 1px, $color-black, 0, 0, 50px, rgba(0, 0, 0, 1));
    }

    &.shadow {
        @include text-shadow-3(-1px, -1px, 0, $color-black, 0, 0, 1px, $color-black, 0, 0, 50px, rgba(0, 0, 0, 1));
    }
}

.text-hover {
    position: relative;
    z-index: 0;
    @include transition(color, 0.3s, $ease-in-out-cubic, 0s);

    &:after {
        content: '';
        position: absolute;
        height: 3px;
        bottom: 0;
        left: 50%;
        right: 50%;
        z-index: -1;
        background: $color-orange;
        @include opacity(0);
        @include transition(all, 0.3s, $ease-in-out-cubic, 0s);
    }

    &:hover {

        &:after {
            left: 0;
            right: 0;
            @include opacity(1);
        }
    }
}

.clm-wrapper {
    display: block;
    flex-wrap: unset;
}

@media screen and (max-width: 1200px) {

    .h1-style {
        font-size: $font-size-50;
    }
}

@media screen and (max-width: 1180px) {

    .h1-style {
        margin: 0 90px;
    }
}

@media screen and (max-width: 600px) {

    .h1-style {
        margin: 0 30px;
        font-size: $font-size-40;
    }
}

@media screen and (max-width: 400px) {

    .h1-style {
        margin: 0 10px;
        font-size: $font-size-36;
    }
}

@media screen and (max-width: 350px) {

    .h1-style {
        font-size: $font-size-32;
    }
}

/* == CONTENT-WRAPPER ====================================================== */

.center-header-ctn {
    margin: 0 40px;
}

.center-huge-ctn {
    margin: 0 90px;
}

.center-wide-ctn {
    margin: 0 60px;
}

.center-middle-ctn {
    margin: 0 auto;
    max-width: $middle-ctn;
}

.center-small-ctn {
    margin: 0 auto;
    max-width: $small-ctn;
}

.center-menu-ctn {
    margin: 0 auto;
    max-width: $middle-ctn + 200;
}

@media screen and (max-width: 1550px) {

    .center-menu-ctn {
        margin-left: 60px;
        margin-right: 60px;
    }
}

@media screen and (max-width: 1360px) {

    .center-middle-ctn {
        margin-left: 90px;
        margin-right: 90px;
    }
}

@media screen and (max-width: 1300px) {

    .center-huge-ctn {
        margin-left: 60px;
        margin-right: 60px;
    }
}

@media screen and (max-width: 1200px) {

    .center-huge-ctn,
    .center-wide-ctn {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media screen and (max-width: 1000px) {

    .center-menu-ctn {
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 800px) {

    .center-middle-ctn {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media screen and (max-width: 765px) {

    .center-menu-ctn {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media screen and (max-width: 600px) {

    .center-header-ctn,
    .center-huge-ctn,
    .center-wide-ctn,
    .center-middle-ctn,
    .center-small-ctn,
    .center-menu-ctn {
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media screen and (max-width: 500px) {

    .center-header-ctn,
    .center-huge-ctn,
    .center-wide-ctn,
    .center-menu-ctn,
    .center-middle-ctn,
    .center-small-ctn {
        margin-left: 20px;
        margin-right: 20px;
    }
}

/* == BACKGROUND ====================================================== */

.bg-color-white {
    background: $color-white;
}

.bg-color-grey-black {
    background: $color-grey-black;
}

.bg-color-grey-white {
    background: $color-grey-white;
}
