.mixin-article {
    position: relative;
    margin-top: 40px;

    .clm-wrapper {

        .clm {
            position: relative;

            h3 {
                margin-bottom: 10px;
                color: $color-grey-dark;
                font-size: $font-size-30;
                font-weight: 500;
                line-height: 1.3em;
            }

            p {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            > ul {

                > li {
                    margin-bottom: 40px;

                    p {
                        padding-left: 40px;
                    }

                    ul {
                        text-align: left;

                        li {
                            position: relative;
                            margin-bottom: 10px;
                            padding-left: 60px;
                            color: $color-grey-middle;

                            &:before {
                                content: '';
                                position: absolute;
                                top: 8px;
                                left: 40px;
                                width: 8px;
                                height: 8px;
                                background: $color-orange;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &:nth-child(1) {
                padding-right: 60px;
            }

            &:nth-child(2) {
                padding-left: 60px;
            }
        }
    }

    .img-wrapper,
    .mixin-gmaps {
        position: relative;
        margin-top: 100px;
        height: 500px;
    }

    .img-wrapper {

        .img-mask {
            position: absolute;
            top: 0;
            left: 50%;
            width: 200%;
            height: 100%;
            overflow: hidden;
            @include transform(translate(-50%, 0));

            img {
                position: absolute;
                top: 0;
                left: 50%;
                width: 100%;
                @include transform(translate(-50%, 0));
            }
        }
    }

    .mixin-gmaps {
        overflow: visible;

        .wrapper {
            position: absolute;
            top: 0;
            left: 50%;
            width: 200%;
            height: 100% !important;
            @include transform(translate(-50%, 0));
        }

        > a {
            left: auto;
            right: 0;
            @include opacity(1);
        }
    }

    &.left {

        .clm-wrapper {

            .clm {

                img {
                    position: absolute;
                    bottom: -200px;
                    left: 200px;
                    width: 900px;
                    @include opacity(0);
                    @include transition(all, 1s, $ease-in-out-cubic, 0s);
                }
            }

            &.is-visible-in-viewport {

                .clm {

                    img {
                        left: 150px;
                        @include opacity(1);
                    }
                }
            }
        }

        &.parallax {

            .clm-wrapper {

                .clm {

                    img {
                        left: 25%;
                    }
                }
            }
        }
    }

    &.right {

        .clm-wrapper {

            .clm {

                h3 {
                    max-width: 500px;
                }

                p {
                    max-width: 500px;
                }

                img {
                    position: absolute;
                    bottom: -200px;
                    right: 110px;
                    width: 1000px;
                    @include opacity(0);
                    @include transition(all, 1s, $ease-in-out-cubic, 0s);
                }
            }

            &.is-visible-in-viewport {

                .clm {

                    img {
                        right: 60px;
                        @include opacity(1);
                    }
                }
            }
        }

        &.parallax {

            .clm-wrapper {

                .clm {

                    img {
                        right: 25%;
                    }
                }
            }
        }
    }

    &.parallax {

        .clm-wrapper {

            .clm {

                img {
                    top: -500px;
                    bottom: auto;
                    width: auto;
                    @include opacity(1);
                    @include transition(opacity, 1s, ease-out, 0s);

                    &.img-lazy-waiting {
                        height: 100%;
                        @include opacity(0);
                    }

                    &.img-lazy-loaded {
                        @include transition(all, 0.3s, ease-out, 0s);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {

    .mixin-article {

        .clm-wrapper .clm {

            &.includes-image-landscape img {
                width: auto !important;
                height: 200% !important;
            }
        }

        .img-wrapper,
        .mixin-gmaps {
            height: 400px;

            .img-mask {
                width: 150%;

                img {
                    width: auto;
                    max-height: 400px;
                }
            }
        }
    }
}

@media screen and (max-width: 1350px) {

    .mixin-article.left .clm-wrapper {

        &.is-visible-in-viewport .clm.includes-image-landscape img {
            left: 50px;
        }
    }
}

@media screen and (max-width: 1300px) {

    .mixin-article {

        .clm-wrapper .clm {

            &.clm-45p {
                width: 35%;
            }

            &.includes-image-landscape {
                width: 40%;

                img {
                    right: 0;
                    width: auto !important;
                    height: 160% !important;
                    max-height: 400px !important;
                }
            }

            &.clm-55p {
                width: 65%;

                &.includes-sibling-with-image-landscape {
                    width: 60%;
                }
            }

            &.clm-2 {

                &.includes-sibling-with-image-parallax {
                    width: 60%;
                }

                &.includes-image-parallax {
                    width: 40%;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {

    .mixin-article {

        .img-wrapper {
            margin-top: 50px;
            height: auto;

            .img-mask {
                position: relative;
                left: -100px;
                right: -100px;
                @include transform(translate(0, 0));

                img {
                    position: relative;
                    top: 0;
                    left: 50%;
                    width: 100%;
                    min-width: 1000px;
                    min-height: 250px;
                    @include transform(translate(-50%, 0));
                }
            }
        }
    }
}

@media screen and (max-width: 1150px) {

    .mixin-article {

        .clm-wrapper .clm {

            &.clm-55p {
                width: 75%;
            }

            &.clm-2 {

                &.includes-sibling-with-image {
                    width: 60%;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-article {

        .clm-wrapper

        .clm {
            content: 'ignore-parent-min-height';
            padding: 0 !important;

            &.includes-image-parallax {
                display: none !important;
            }

            &.clm-45p {

                &.includes-image-landscape {
                    padding-right: 0;
                    width: 100%;
                }
            }

            &.clm-55p {
                width: 100% !important;

                h3,
                p {
                    max-width: none;
                }
            }

            &.clm-2 {
                width: 100% !important;

                > ul {
                    margin: 0 auto 40px auto;
                    max-width: 500px;
                    text-align: center;

                    > li {

                        p {
                            padding: 0;
                        }

                        ul li {
                            padding-left: 40px;

                            &:before {
                                left: 0;
                            }
                        }
                    }
                }
            }

            &.includes-image {
                height: 300px;
                overflow: hidden;

                img {
                    position: relative;
                    top: 50% !important;
                    left: 0 !important;
                    bottom: auto !important;
                    width: 100% !important;
                    height: auto !important;
                    max-height: none !important;
                    @include transform(translate(0, -50%));

                }
            }

            &:last-child {
                margin-top: 20px;
            }
        }
    }
}

@media screen and (max-width: 850px) {

    .mixin-article {

        .clm-wrapper .clm {
            padding: 0 !important;
        }
    }
}

@media screen and (max-width: 500px) {

    .mixin-article {

        .img-wrapper,
        .mixin-gmaps {
            margin-top: 30px;
        }

        &.right .clm-wrapper .clm h3 {
            font-size: $font-size-26;
        }
    }
}

@media screen and (max-width: 400px) {

    .mixin-article {

        .clm-wrapper .clm {

            h3 {
                font-size: $font-size-22;
            }

            &.includes-image {
                height: 200px;
            }

            &.clm-2 > ul > li ul li {
                padding-left: 25px;
            }
        }

        .mixin-gmaps > a {
            right: -20px;
        }
    }
}
