.mixin-timeline {
    margin-top: 100px;

    ul {
        position: relative;
        font-size: 0;

        li {
            position: relative;
            left: -50px;
            margin: 70px 0;
            padding-right: 60px;
            width: 50%;
            text-align: right;
            @include transition(all, 1s, $ease-in-out-cubic, 0s);

            > div {
                position: relative;
                display: inline-block;
                max-width: 300px;

                h3 {
                    color: $color-grey-dark;
                    font-weight: 500;
                    text-transform: uppercase;
                    @include opacity(0);
                    @include transition(opacity, 1s, $ease-in-out-cubic, 0s);
                }

                p {
                    color: $color-grey-middle;
                    font-size: $font-size-14;
                    @include opacity(0);
                    @include transition(opacity, 1s, $ease-in-out-cubic, 0s);

                    &.link-wrapper {
                        margin-top: 20px;
                    }
                }

                .link-wrapper {
                    @include opacity(0);
                    @include transition(opacity, 1s, $ease-in-out-cubic, 0s);

                    a,
                    span {
                        color: $color-grey-middle;
                        font-size: $font-size-14;
                    }
                }

                .date {
                    position: absolute;
                    top: -11px;
                    right: -150px;
                    width: 80px;
                    text-align: center;
                    background: $color-grey-white;
                    @include transition(all, 1s, $ease-in-out-cubic, 0s);

                    span {
                        display: block;
                        position: relative;
                        z-index: 1;
                        color: $color-grey-middle;
                        font-size: $font-size-12;
                        font-weight: 500;
                        line-height: 40px;
                    }
                }
            }

            &:nth-child(even) {
                left: 50px;
                margin-left: 50%;
                padding-left: 60px;
                padding-right: 0;
                text-align: left;

                > div {

                    .date {
                        left: -150px;
                        right: auto;
                    }
                }
            }

            &.is-visible-in-viewport {
                left: 0;

                > div {

                    h3,
                    p,
                    .link-wrapper {
                        @include opacity(1);
                    }

                    .date {
                        right: -100px;
                    }
                }

                &:nth-child(even) {

                    > div {

                        .date {
                            left: -100px;
                            right: auto;
                        }
                    }
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background: $color-grey-middle;
            @include opacity(0.3);
        }
    }
}

html.isMobile {

    .mixin-timeline ul li {
        left: 0;

        > div {

            h3,
            p {
                @include opacity(1);
            }

            .date {
                right: -100px;
            }
        }

        &:nth-child(even) {

            > div {

                .date {
                    left: -100px;
                    right: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    .mixin-timeline {

        ul li {
            position: static;
            width: auto;
            padding: 0 !important;
            margin: 0 0 80px 0 !important;
            text-align: center !important;

            > div {
                padding-bottom: 20px;
                text-align: center;
                background: $color-grey-white;

                h3 {
                    position: relative;
                    z-index: 1;
                    padding: 40px 0 10px 0;
                }

                .date {
                    top: 0;
                    left: 50% !important;
                    @include transform(translate(-50%, 0));
                }
            }

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}

@media screen and (max-width: 500px) {

    .mixin-timeline {
        margin-top: 50px;
    }
}
