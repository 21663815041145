#menu {
    padding: 100px 0;

    iframe {
        margin-top: 80px;
    }
}

@media screen and (max-width: 1000px) {

    #menu {
        padding: 100px 0 50px 0;
    }
}

@media screen and (max-width: 750px) {

    #menu {
        padding: 100px 0 20px 0;

        iframe {
            margin-top: 40px;
        }
    }
}

@media screen and (max-width: 700px) {

    #menu {
        padding: 100px 0 20px 0;
    }
}

@media screen and (max-width: 500px) {

    #menu {
        padding: 50px 0 20px 0;
    }
}
