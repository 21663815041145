.notifyjs-corner {
    left: 50% !important;
    right: auto !important;
    z-index: 999999 !important;
    margin: 0 !important;
    padding: 10px;
    width: 100%;
    max-width: 960px;
    @include transform(translate(-50%, 0));

    .notifyjs-wrapper {
        margin: 0 0 10px 0!important;

        .notifyjs-container {
            margin: 0 !important;
            text-align: center;

            > div {
                display: inline-block;
                padding: 10px 20px;
                text-align: left;
                background: $color-white;
                @include box-shadow(0, 0, 10px, 0, $color-grey-black);
                @include border-radius($box-rounded-1, $box-rounded-1, $box-rounded-1, $box-rounded-1);

                .icon {
                    display: inline-block;
                    width: 34px;
                    margin-right: 15px;
                    color: $color-grey-dark;
                    font-size: $font-size-26;
                    line-height: 32px;
                    font-weight: 100;
                    text-align: center;
                    border: 1px solid $color-grey-dark;
                    @include border-radius($box-rounded-1, $box-rounded-1, $box-rounded-1, $box-rounded-1);
                }

                .text {
                    color: $color-grey-dark;
                    line-height: 34px;
                }

                &.notifyjs-error-base {
                    background: $color-white;
                }
            }
        }
    }
}

