.mixin-section {
    padding: 100px 0;

    &.image-before {
        padding-top: 200px;
    }

    &.image-bottom,
    &.gmaps-bottom {
        padding-bottom: 0;
    }

    &:last-child {
        overflow: hidden;

        &.image-parallax {
            overflow: visible;
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-section {

        &.image-before {
            padding-top: 50px;
        }
    }
}

@media screen and (max-width: 500px) {

    .mixin-section {
        padding: 50px 0;
    }
}
