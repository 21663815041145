$distance-default-20: 20px;
$distance-default-30: 30px;
$distance-default-40: 40px;
$distance-default-50: 50px;
$screen-1365: 1365px;
$screen-1279: 1279px;
$screen-1023: 1023px;
$screen-767: 767px;
$screen-319: 319px;
$border-radius-1: 3px;
$border-radius-2: 5px;
$line-height-1: 1.6em;
$line-height-2: 1.3em;
$line-height-3: 1em;
$form-padding: 4px 5px;
$form-font-size: 16px;
$form-font-weight: 300;
$form-line-height: 24px;
$duration-01s: 0.1s;
$duration-02s: 0.2s;
$duration-03s: 0.3s;
$duration-04s: 0.5s;
$duration-05s: 0.5s;
$font-size-8: 8px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-34: 34px;
$font-size-36: 36px;
$font-size-38: 38px;
$font-size-40: 40px;
$font-size-46: 46px;
$font-size-50: 50px;
$font-size-60: 60px;
$font-size-70: 70px;
$font-size-80: 80px;
$font-size-90: 90px;
$font-size-100: 100px;
$font-size-110: 110px;
$font-size-120: 120px;
$font-size-130: 130px;
$font-size-140: 140px;
$font-size-150: 150px;
$color-white: #fff;
$color-black: #000;
$color-grey-light: #f3f3f4;
$color-grey-shiny: #c3c3c3;
$color-grey-bright: #e0e0e0;
$color-grey-middle: #999c9e;
$color-grey-dim: #676a6c;
$color-grey-dark: #333;
$color-grey-black: #181818;
$color-grey-blue-pale: #a7b1c2;
$color-grey-blue-bright: #2f4050;
$color-grey-blue-middle: #293846;
$color-blue-light: #d9e9f3;
$color-blue-middle: #1c84c6;
$color-green-mint: #23c6c8;
$color-red-error: #a50000;
$color-red-error-bright: #e2430f;
$color-orange-warning: #ff9800;
$transparent: rgba(0, 0, 0, 0);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$timestamp: 1682239323435;
