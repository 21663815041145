.mixin-pagination {
    margin-top: 100px;
    font-size: 0;
    text-align: center;

    .controls {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-top: 5px;

        span {
            display: none;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            background: $color-grey-middle;
            @include transition(background, 0.3s, ease-out, 0s);
        }

        &:before {
            top: 50%;
            left: 5px;
            right: 4px;
            height: 1px;
        }

        &.active {
            cursor: pointer;

            &:hover {

                &:before,
                &:after {
                    background: $color-grey-dark;
                }
            }
        }

        &.next {

            &:after {
                top: 5px;
                bottom: 4px;
                left: 50%;
                width: 1px;
            }
        }
    }

    ul {
        display: inline-block;
        font-size: 0;

        li {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 30px;
            margin: 0 5px;
            padding-top: 5px;
            font-size: 0;
            text-align: center;

            a {
                position: absolute;
                top: 1px;
                left: 0;
                right: -2px;
                z-index: 1;
                height: 100%;
                font-size: 0;

                span {
                    display: none;
                }

                &:before {
                    content: attr(data-idx);
                    color: $color-grey-middle;
                    font-size: $font-size-20;
                    line-height: 30px;
                    @include transition(color, 0.3s, ease-out, 0s);
                }

                &:hover {

                    &:before {
                        color: $color-grey-dark;
                    }
                }
            }

            &.active {

                a {

                    &:before {
                        color: $color-grey-dark;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 1px solid $color-grey-middle;
                }
            }

            &.dots {
                width: auto;

                span {
                    position: relative;
                    top: -10px;
                    color: $color-grey-middle;
                    font-size: $font-size-20;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {

    .mixin-pagination {
        margin-top: 60px;
    }
}

@media screen and (max-width: 600px) {

    .mixin-pagination {
        margin-top: 40px;

        ul li {
            width: 20px;
            margin: 0 3px;

            a:before {
                font-size: $font-size-14;
            }

            &.dots {

                span {
                    top: -4px;
                    font-size: $font-size-14;
                }
            }
        }
    }
}
