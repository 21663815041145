#posts {
    padding: 100px 0 30px 0;

    &.image-before {
        padding-top: 200px;
    }
}

@media screen and (max-width: 1000px) {

    #posts {

        &.image-before {
            padding-top: 50px;
        }
    }
}

@media screen and (max-width: 500px) {

    #posts {
        padding: 50px 0;
    }
}
