#landing {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        min-width: 100%;
        min-height: 100%;
    }

    .badge {
        position: fixed;
        top: 50px;
        left: 50px;
        width: 250px;
        height: 250px;
        text-align: center;
        background-image: url('public/images/assets/layout/global/crown-cap.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: -3px 3px;
        @include border-radius(50%, 50%, 50%, 50%);
        @include rotate-z(0, -15deg, 50%, 50%);
        @include transition(all, 0.3s, $ease-in-out-cubic, 0s);

        h1 {
            margin-top: 10px;
            color: $color-grey-white;
            font-size: $font-size-24;
            line-height: 1.3em;
            background-image: url('public/images/assets/layout/global/pattern-grey-white.png');
            background-position: center;
            @include background-clip(text);
            @include text-fill-color(transparent);
            @include font-family('Clarendon BT');
        }

        p,
        p span {
            color: $color-grey-white;
            font-size: $font-size-14;
            font-weight: 500;

            &.circle-type {
                position: absolute;
                letter-spacing: -5px;
                text-transform: uppercase;

                &.top {
                    top: 48px;
                    left: 128px;
                }

                &.bottom {
                    bottom: 45px;
                    left: 126px;
                }
            }
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            @include opacity(0);
        }
    }

    .bar {
        position: fixed;
        bottom: 100px;
        left: 0;
        width: 100%;
        text-align: center;
        background: $color-grey-black;
        border-top: 2px solid $color-white-brown;
        border-bottom: 2px solid $color-white-brown;
        @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0.5));

        .clm-wrapper {
            margin: 0 100px;

            .clm {
                position: relative;
                padding: 40px 0;

                .restaurant {
                    position: relative;
                    display: inline-block;

                    .text {
                        display: block;
                        color: $color-white-brown;
                        background-image: url('public/images/assets/layout/global/pattern-white-brown.png');
                        background-position: center;
                        @include background-clip(text);
                        @include text-fill-color(transparent);
                        @include font-family('Clarendon BT');

                        &.large {
                            color: $color-white-brown;
                            font-size: $font-size-50;
                        }
                    }

                    .icon {

                        &.left {
                            left: -140px;
                        }

                        &.right {
                            right: -140px;
                        }

                        &:before {
                            background-image: url('public/images/assets/layout/global/pattern-white.png');
                            @include background-clip(text);
                            @include text-fill-color(transparent);
                        }
                    }
                }
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            color: $color-white-brown;
            font-size: 140px;
            @include transform(translate(0, -50%));
            @include opacity(0.2);

            &.outer {
                display: none;

                &.left {
                    left: 0;

                    &:before {
                        background-position: 20px 0;
                    }
                }

                &.right {
                    right: 0;

                    &:before {
                        background-position: 30px 0;
                    }
                }
            }

            &:before {
                background-image: url('public/images/assets/layout/global/pattern-white.png');

                @include background-clip(text);
                @include text-fill-color(transparent);
            }
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-white-brown;
        }

        &:before {
            top: 3px;
        }

        &:after {
            bottom: 3px;
        }
    }
}

@media screen and (max-width: 1600px) {

    #landing {

        .bar {

            .clm-wrapper {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 1300px) {

    #landing {

        .bar {

            .clm-wrapper {
                margin: 0 100px;

                .clm {

                    .restaurant {

                        .text {
                            font-size: $font-size-14;

                            &.large {
                                font-size: $font-size-40;
                            }
                        }
                    }

                    .icon {
                        display: none;
                    }
                }
            }

            .icon.outer {
                display: block;
                font-size: 120px;

                &.left {
                    left: -15px;
                }

                &.right {
                    right: -15px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    #landing {

        img {
            left: -1000px;
        }

        .bar {
            height: auto;

            .icon.outer {

                &.left {
                    left: -15px;
                }

                &.right {
                    right: -15px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {

    #landing {

        .bar {

            .clm-wrapper {

                .clm {
                    display: block;
                    width: 100%;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        right: 50px;
                        width: 200px;
                        height: 1px;
                        background: $color-white;
                        @include opacity(0.2);
                        @include transform(translate(-50%, 0));
                    }
                }
            }

            .icon.outer {
                font-size: 200px;

                &.left {
                    left: -50px;
                }

                &.right {
                    right: -50px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {

    #landing {

        img {
            left: -1100px;
        }

        .badge {
            top: 20px;
            left: 20px;
        }
    }
}

@media screen and (max-width: 550px) {

    #landing {

        .bar {

            .clm-wrapper {

                .clm {

                    .restaurant {

                        .text {
                            font-size: $font-size-12;

                            &.large {
                                font-size: $font-size-30;
                            }
                        }
                    }
                }
            }

            .icon.outer {
                @include opacity(0.2);

                &.left {
                    left: -60px;
                }

                &.right {
                    right: -60px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {

    #landing {

        img {
            top: -100px;
        }

        .badge {
            left: -10px;
        }

        .bar {
            bottom: 40px;

            .clm-wrapper {
                margin: 0 20px;

                .clm {
                    padding: 30px 0;
                }
            }

            .icon.outer {
                margin-top: -100px;
                @include scale-x(0.7);

                &.left {
                    left: -70px;
                }

                &.right {
                    right: -70px;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {

    #landing {

        img {
            top: -120px;
            left: -1140px;
        }

        .badge {
            top: 0;
            left: -50px;
        }

        .bar {

            .clm-wrapper .clm {
                padding: 20px 0;
            }
        }
    }
}

@media screen and (max-width: 350px) {

    #landing {

        img {
            top: -150px;
        }

        .badge {
            top: -20px;
        }

        .bar {
            bottom: 0;

            .icon.outer {
                display: none;
            }
        }
    }
}
