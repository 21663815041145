#splasher {
    height: 500px;
    background: $color-grey-black;

    .css-table {
        position: relative;
        z-index: 1;

        h1 {
            text-align: center;
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: -80px;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right center;
    }
}

@media screen and (max-width: 1500px) {

    #splasher {
        height: 400px;
    }
}

@media screen and (max-width: 1200px) {

    #splasher {
        height: 300px;
    }
}

@media screen and (max-width: 500px) {

    #splasher {

        .background {
            right: -100px;
        }
    }
}

@media screen and (max-width: 400px) {

    #splasher {
        height: 250px;

        .background {
            right: -120px;
        }
    }
}

@media screen and (max-width: 350px) {

    #splasher {
        height: 200px;
    }
}
