#address {
    position: relative;
    z-index: 30;
    padding: 16px 0 14px 0;
    text-align: center;
    background: $color-white;

    .center-header-ctn {
        position: relative;

        nav {
            position: absolute;
            top: 3px;
            left: 0;

            ul {

                li {
                    display: inline-block;
                    margin-right: 7px;

                    a {
                        color: $color-grey-middle;
                        font-size: $font-size-12;
                        font-weight: 400;
                        text-transform: uppercase;
                        @include opacity(0.4);
                        @include transition(opacity, 0.3s, ease-out, 0s);

                        &:hover,
                        &.active {
                            @include opacity(1);
                        }
                    }
                }
            }
        }

        address {
            display: inline-block;
            font-size: $font-size-14;
            color: $color-grey-dark;
            @include font-family('Clarendon BT');

            a {
                color: inherit;
                font: inherit;
            }
        }

        .wrapper-phone-icon {
            display: inline-block;

            .phone {
                display: inline-block;
                margin: 0 10px;
                color: $color-grey-middle;
                font-size: $font-size-14;
            }

            .icon {
                top: 2px;
                padding: 10px;
                margin: -10px;
                color: $color-grey-dark;
                font-size: 16px;
                cursor: pointer;
            }
        }

        .opening-hours {
            position: absolute;
            bottom: 4px;
            right: 0;
            color: $color-grey-middle;
            font-size: $font-size-12;

            sup {
                color: inherit;
                font-family: inherit;
                font-variant: unset;
                font-size: $font-size-8;
            }
        }
    }
}

@media screen and (max-width: 950px) {

    #address .center-header-ctn {
        text-align: right;

        .opening-hours {
            display: none;
        }
    }
}

@media screen and (max-width: 600px) {

    #address {
        padding: 14px 0 0;

        .center-header-ctn {
            text-align: right;

            nav {
                top: -2px;
            }

            address {
                display: none;
            }

            .wrapper-phone-icon {
                display: block;
                text-align: center;

                .phone {
                    font-size: $font-size-12;
                    line-height: 1em;
                }

                .icon {
                    position: absolute;
                    top: 0;
                    right: 3px;
                    font-size: 14px;
                }
            }
        }
    }
}
