.addthis-smartlayers {
    @include opacity(0);

    .at4-share {
        left: -48px;
        animation-name: none;
        @include opacity(0);
        @include transition-i(all, 0.3s, $ease-in-out-cubic, 0s);

        .at-share-btn .at-icon-wrapper {
            background-color: $color-grey-black !important;
        }
    }
}

body.body-images-loaded {

    .addthis-smartlayers {
        @include opacity(1);
    }
}

body.window-scroll-top-gt-0 {

    .addthis-smartlayers {

        .at4-share {
            left: 0;
            @include opacity(1);
        }
    }

    &#page-1342,
    &#page-1346,
    &#page-42,
    &#page-47 {

        .addthis-smartlayers {

            .at4-share {
                left: -48px;
                @include opacity(0);
            }
        }
    }
}

body.window-scroll-top-gt-viewport {
    .addthis-smartlayers {

        .at4-share {
            left: 0 !important;
            @include opacity-i(1);
        }
    }
}

@media screen and (max-width: 1000px) {

    .addthis-smartlayers {
        display: none;
    }
}
