#overlay {

    .wrapper {

        .close {
            border-color: $color-grey-dark;

            &:before,
            &:after {
                background: $color-grey-dark;
            }
        }

        .content {

            .chunk-overlay {

                h5 {
                    margin-bottom: 20px;
                    padding-bottom: 10px;
                    color: $color-grey-dark;
                    font-size: $font-size-20;
                    text-transform: uppercase;
                    border-bottom: 1px solid $color-grey-middle;

                    em {
                        margin-bottom: 10px;
                        font-weight: 500;
                    }
                }

                p {
                    margin-bottom: 10px;

                    strong {
                        margin-bottom: 10px;
                        color: $color-grey-dark;
                        font-weight: 400;
                    }

                    &:last-of-type {
                        margin-bottom: 20px;
                    }
                }

                form {

                    .clm-wrapper {
                        margin: 10px 0;

                        .clm {
                            padding: 0 5px;

                            label {
                                color: $color-grey-middle;
                                font-size: $font-size-14;
                            }

                            input {
                                width: 100%;
                                padding: 5px 10px;
                                border-color: $color-grey-middle;
                                @include transition(border-color, 0.5s, ease-out, 0s);

                                &.error-flag {
                                    border-color: $color-orange;
                                }
                            }

                            .error-message {
                                color: $color-orange;
                                font-size: $font-size-14;
                                font-weight: 400;
                                @include opacity(0);
                                @include transition(opacity, 0.5s, ease-out, 0s);
                            }

                            &:first-child {
                                padding-left: 0;
                            }

                            &:last-child {
                                padding-right: 0;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &.error-flag {

                        .clm-wrapper .clm .error-message {
                            @include opacity(1);
                        }
                    }
                }

                .btn {
                    float: right;
                    color: $color-grey-dark;
                }

                &.reservation {

                    iframe {
                        width: 380px;
                        height: 640px;
                    }
                }

                &.newsletter {
                    max-width: 400px;
                }
            }
        }
    }
}
