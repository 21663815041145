.addthis-smartlayers {

    .at4-share {

        .at-share-btn {

            .at-icon-wrapper {
                background-color: $color-grey-dark !important;
                @include transition(background-color, 0.3s, ease-out, 0s);
            }

            &:hover {

                &:nth-child(1) {

                    .at-icon-wrapper {
                        background-color: #3b5998 !important;
                    }
                }

                &:nth-child(2) {

                    .at-icon-wrapper {
                        background-color: #1da1f2 !important;
                    }
                }

                &:nth-child(3) {

                    .at-icon-wrapper {
                        background-color: #dc4e41 !important;
                    }
                }

                &:nth-child(4) {

                    .at-icon-wrapper {
                        background-color: #848484 !important;
                    }
                }

                &:nth-child(5) {

                    .at-icon-wrapper {
                        background-color: #ff6550 !important;
                    }
                }
            }
        }
    }
}
