.mixin-gmaps {
    position: relative;
    height: 0;
    overflow: hidden;
    @include transition(height, 0.5s, $ease-in-out-cubic, 0s);

    .wrapper {
        height: 100%;
        background: $color-grey-white;

        .gmnoprint,
        .gm-style-cc,
        .gm-style > div > a {
            display: none !important;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: -200px;
        @include opacity(0);
        @include transition(all, 0.3s, $ease-in-out-cubic, 0.75s);
    }

    > a {
        position: absolute;
        top: 0;
        left: -200px;
        @include opacity(0);
        @include transition(all, 0.3s, $ease-in-out-cubic, 0.75s);
    }
}

#header.show-gmaps {

    .mixin-gmaps {

        .wrapper {
            height: 100% !important;
        }

        .close {
            right: 0;
            @include opacity(1);
        }

        > a {
            left: 0;
            @include opacity(1);
            @include transition(all, 0.3s, $ease-in-out-cubic, 0.75s);
        }


    }
}

@media screen and (max-width: 700px) {

    .mixin-gmaps > a {
        top: auto;
        bottom: 0;
        @include transition(all, 0.3s, $ease-in-out-cubic, 0s);
    }
}
