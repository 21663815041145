[class^='icon-'],
[class*=' icon-'] {
    display: inline-block;
    position: relative;
    font-family: 'icomoon' !important;
    speak: none !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    text-align: center !important;
    line-height: 1;

    span {
        font-family: 'icomoon' !important;
        speak: inherit !important;
        font-style: inherit !important;
        font-weight: inherit !important;
        font-variant: inherit !important;
        text-transform: inherit !important;
        line-height: inherit;
    }

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        border: none;
        margin: -1px;
        padding: 0;
        clip: rect(0 0 0 0);
        overflow: hidden;
    }

    &:before {
        position: relative;
        z-index: 1;
    }
}
