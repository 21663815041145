/* == Galano Grotesque ====================================================== */

@font-face {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('public/fonts/Galano-Grotesque/GalanoGrotesque-Thin.woff2') format('woff2');
}

@font-face {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('public/fonts/Galano-Grotesque/GalanoGrotesque-ExtraLight.woff2') format('woff2');
}

@font-face {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('public/fonts/Galano-Grotesque/GalanoGrotesque-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('public/fonts/Galano-Grotesque/GalanoGrotesque-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('public/fonts/Galano-Grotesque/GalanoGrotesque-Medium.woff2') format('woff2');
}

/* == Clarendon BT ====================================================== */

@font-face {
    font-family: 'Clarendon BT';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('public/fonts/Clarendon-BT/Clarendon-BT-Roman.woff2') format('woff2');
}

@font-face {
    font-family: 'Clarendon BT';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('public/fonts/Clarendon-BT/Clarendon-BT-Bold.woff2') format('woff2');
}

/* == Google Fonts - Roboto ====================================================== */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
