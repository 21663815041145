#main {
    z-index: 20;
    width: 100%;
    height: auto;
    overflow-y: visible;

    > section {
        position: relative;

        > img[data-parallax] {
            position: absolute;
            top: 0;
            left: 0;

            &.right {
                left: auto;
                right: 0;
            }
        }

        &.bg-color-grey-white {
            background: $transparent;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: $color-grey-white;
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    #main {
        overflow: hidden;
    }
}
