#slider {
    overflow: hidden;
    background: $color-grey-black;

    &.royalSlider {
        width: 100%;

        .rsOverflow {
            height: 100% !important;

            .rsContent {

                .ctn-wrapper {
                    position: absolute;
                    bottom: 15%;
                    left: 0;
                    z-index: 1;
                    width: 100%;

                    .h1-style {
                        padding: 0 40px;
                    }

                    .btn-wrapper {
                        margin-top: 40px;
                        text-align: center;
                    }
                }

                .image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: top center;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        @include gradient-streight(bottom, rgba(0, 0, 0, 0.3), 0, $transparent, 50%);
                    }
                }

                video {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    min-width: 100%;
                    min-height: 100%;
                    @include transform(translate(-50%, -50%));
                }
            }

            .rsArrow {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100px;

                .rsArrowIcn {
                    position: absolute;
                    top: 50%;
                    width: 100px;
                    height: 74px;
                    background: $color-grey-black;
                    @include opacity(0);
                    @include transform(translate(0, -50%));
                    @include transition(all, 0.3s, $ease-in-out-cubic, 0s);

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        width: 20px;
                        height: 20px;
                    }
                }

                &.rsArrowLeft {
                    left: 0;

                    .rsArrowIcn {
                        left: -100px;

                        &:after {
                            left: 50%;
                            border-left: 1px solid $color-white;
                            border-bottom: 1px solid $color-white;
                            @include rotate-z(0, 45deg, 20px, 0);
                        }
                    }
                }

                &.rsArrowRight {
                    right: 0;

                    .rsArrowIcn {
                        right: -100px;

                        &:after {
                            right: 50%;
                            border-right: 1px solid $color-white;
                            border-bottom: 1px solid $color-white;
                            @include rotate-z(0, -45deg, 0, 0);
                        }
                    }
                }

                &:hover {

                    &.rsArrowLeft .rsArrowIcn {
                        left: 0;
                        @include opacity(1);
                    }

                    &.rsArrowRight .rsArrowIcn {
                        right: 0;
                        @include opacity(1);
                    }
                }
            }
        }

        .rsNav {
            position: absolute;
            bottom: 30px;
            left: 50%;
            z-index: 2;
            @include transform(translate(-50%, 0));

            .rsNavItem {
                display: inline-block;
                width: 15px;
                height: 15px;
                cursor: pointer;
                margin: 0 10px;
                background: $color-white;
                @include border-radius(50%, 50%, 50%, 50%);
                @include opacity(0.5);

                &.rsNavSelected {
                    @include opacity(1);
                }
            }
        }
    }
}

#main.show-gmaps,
#main.show-navigation-main-aside {

    #slider {
        content: 'ignore-viewport-height';
    }
}

html.isTouch {

    #slider.royalSlider .rsOverflow .rsArrow {
        display: none !important;
    }
}

@media screen and (max-width: 1000px) {

    #slider.royalSlider .rsOverflow .rsArrow {
        display: none !important;
    }
}

@media screen and (max-width: 500px) {

    #slider.royalSlider .rsOverflow .rsContent .ctn-wrapper .h1-style {
        padding: 0 20px;
    }
}

@media screen and (max-width: 400px) {

    #slider.royalSlider .rsNav {
        bottom: 15px;
    }
}
